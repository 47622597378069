import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Post } from 'apps/shared/scripts/__generated__/graphql.generated';
import { PostsByUsernameQueryDocument } from 'apps/shared/scripts/graphql/hooks/usePostsByUsername/usePostsByUsername.generated';
import { modalDataSelector } from 'apps/shared/scripts/redux/selectors';
import { connectionEdgesToList } from 'apps/shared/scripts/utils';

interface Options {
  id: string;
}

const useControls = ({ id }: Options) => {
  const { username } = useParams();
  const client = useApolloClient();

  const data = client.readQuery({
    query: PostsByUsernameQueryDocument,
    variables: {
      username,
    },
  });

  // hack: "lastUpdated" property busts cache
  useSelector(modalDataSelector);

  if (!data) {
    return [null, null];
  }

  const postIds =
    connectionEdgesToList<Post>(data.postsByUsername).map(
      (node: Post) => node.id
    ) || [];

  const activeIndex = postIds.indexOf(id);
  const prevId = activeIndex - 1 >= 0 ? postIds[activeIndex - 1] : null;
  const nextId =
    activeIndex + 1 < postIds.length ? postIds[activeIndex + 1] : null;

  return [prevId, nextId];
};

export default useControls;
