import { css, keyframes } from '@emotion/css';

import { Colors } from '../../styles/constants';

const spin = keyframes({
  '100%': { transform: 'rotate(360deg)' },
  '75%': { transform: 'rotate(300deg)' },
  '50%': { transform: 'rotate(240deg)' },
  '25%': { transform: 'rotate(90deg)' },
  '0%': { transform: 'rotate(0deg)' },
});

export const spinnerClassName = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

export const iconClassName = css({
  animationName: spin,
  animationDuration: '1s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
  color: Colors.Secondary,
  height: 16,
  width: 16,
});
