import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const layoutClassName = css({
  margin: '3rem auto',
  padding: `0 ${Spacing.MD}px`,
  textAlign: 'left',

  [`@media (min-width: 576px)`]: {
    maxWidth: 400,
  },
});

export const centeredClassName = css({
  textAlign: 'center',
});

export const headerClassName = css({
  marginBottom: Spacing.LG,
});

export const mediaClassName = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: Spacing.LG,
});

export const iconClassName = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: 48,
  width: 48,
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: 22,
  fontWeight: FontWeight.BOLD,
});

export const subheaderClassName = css({
  alignItems: 'center',
  display: 'flex',
  marginTop: Spacing.SM,
});

export const subheadingClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
});

export const actionClassName = css({
  marginTop: Spacing.XL,
});

export const buttonPrimaryClassName = css({
  background: Colors.Primary,
  borderRadius: Spacing.XS,
  minHeight: 52,
  '&:hover': {
    opacity: 0.9,
  },

  '& span': {
    color: Colors.White,
    fontSize: FontSize.REGULAR,
    fontWeight: FontWeight.BOLD,
  },

  '& i > svg': {
    color: Colors.White,
  },
});

export const footerClassName = css({
  alignItems: 'center',
  display: 'flex',
});
