import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Centered, Label, Layout } from 'apps/auth/scripts/components';
import { EmailSvg } from 'apps/shared/scripts/assets';

import { descriptionClassName } from './styles';

const NUM_SECONDS_TO_REDIRECT = 5;

const VerificationSuccess = () => {
  const [timer, setTimer] = useState<number>(NUM_SECONDS_TO_REDIRECT);
  const navigate = useNavigate();

  const client = useApolloClient();

  const onSubmit = useCallback(async () => {
    navigate('/profile', { replace: true });
    await client.refetchQueries({
      include: ['UserQuery'],
    });
  }, [client, navigate]);

  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  useEffect(() => {
    const onAutomaticRedirect = () => {
      setTimeout(onSubmit, NUM_SECONDS_TO_REDIRECT * 1000);
    };

    onAutomaticRedirect();
  }, []); // eslint-disable-line

  return (
    <Layout
      centered
      buttonLabel={'Redirect now'}
      heading={'Email Verified'}
      icon={<EmailSvg />}
      subheader={
        <Centered>
          <Label
            value={
              <div className={descriptionClassName}>
                <span>Thanks for verifying your email.</span>
                <span>
                  You now have full access to Flytepost and will be redirected
                  in {timer} seconds.
                </span>
              </div>
            }
          />
        </Centered>
      }
      onSubmit={onSubmit}
    />
  );
};

VerificationSuccess.displayName = 'VerificationSuccess';

export default VerificationSuccess;
