import { gql } from '@apollo/client';

import { generateMutationHook } from '../../utils';

import {
  CreateOAuthUserMutationResult,
  CreateOAuthUserMutationVariables,
  useCreateOAuthUserMutation,
} from './useCreateOAuthUser.generated';

gql`
  mutation CreateOAuthUser($input: CreateOAuthUserInput!) {
    createOAuthUser(input: $input) {
      username
    }
  }
`;

interface HookDataToOutputReturn {
  result?: boolean;
}

const hookDataToOutput = (
  data?: CreateOAuthUserMutationResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    result: undefined,
  };

  if (data?.createOAuthUser) {
    toReturn.result = true;
  }

  return toReturn;
};

const useCreateOAuthUser = generateMutationHook<
  CreateOAuthUserMutationResult,
  CreateOAuthUserMutationVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useCreateOAuthUserMutation);

export default useCreateOAuthUser;
