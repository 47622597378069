import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { TopNav } from 'apps/shared/scripts/components';
import {
  GalleryContainer,
  PhotoModalContainer,
} from 'apps/shared/scripts/containers';
import { UploadModalContainer } from 'apps/upload/scripts/containers';

import { ProfileFooter, ProfileHero } from '../../components';

const ProfileContainer = () => {
  const { username } = useParams();

  return (
    <>
      <TopNav />
      <Container>
        <ProfileHero username={username!} />
        <GalleryContainer username={username!} />
        <PhotoModalContainer />
        <UploadModalContainer />
        <ProfileFooter />
      </Container>
    </>
  );
};

ProfileContainer.displayName = 'ProfileContainer';

export default ProfileContainer;
