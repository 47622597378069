import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { closeModal } from 'apps/shared/scripts/redux';

import DismissButton from './DismissButton';
import MobileDismissButton from './MobileDismissButton';

interface Props {
  fixed?: boolean; // pops dismiss button outside modal
  mobile?: boolean;
}

const Dismiss = ({ fixed, mobile }: Props) => {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const delayedHandleClose = useCallback(() => {
    setTimeout(handleClose, 100);
  }, [handleClose]);

  return mobile ? (
    <MobileDismissButton onClick={delayedHandleClose} />
  ) : (
    <DismissButton fixed={fixed} onClick={delayedHandleClose} />
  );
};

Dismiss.displayName = 'Dismiss';

export default Dismiss;
