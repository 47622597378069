export const Breakpoints = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
};

export const Colors = {
  Black: '#000000',
  Brand: '#26A7DE',
  Green: '#0ABF53',
  Primary: '#0A2540',
  Red: '#C13515',
  Secondary: '#425466',
  White: '#FFFFFF',
};

export enum FontSize {
  COMPACT = 14,
  REGULAR = 16,
  LARGE = 18,
}

export enum FontWeight {
  THIN = 100,
  EXTRA_LIGHT = 200,
  LIGHT = 300,
  NORMAL = 400,
  MEDIUM = 500,
  SEMI_BOLD = 600,
  BOLD = 700,
  EXTRA_BOLD = 800,
  BLACK = 900,
}

export enum LineHeight {
  COMPACT = '1.2',
  REGULAR = '1.4',
  LARGE = '1.6',
  EXTRA_LARGE = '1.8',
}

export enum Spacing {
  '3XS' = 2,
  '2XS' = 4,
  '1XS' = 6,
  XS = 8,
  SM = 12,
  MD = 16,
  LG = 24,
  XL = 32,
  '2XL' = 40,
  '3XL' = 48,
}
