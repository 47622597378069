import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal, { Card } from 'apps/shared/scripts/components/Modal';
import { useUser } from 'apps/shared/scripts/graphql';
import { useIsAuthenticated, useRedirect } from 'apps/shared/scripts/hooks';
import { closeModal, ModalTypes } from 'apps/shared/scripts/redux';
import {
  modalDataIdSelector,
  selectIsUploadModalVisible,
  selectModalType,
} from 'apps/shared/scripts/redux/selectors';

import {
  UploadFormCreateContainer,
  UploadFormUpdateContainer,
} from '../UploadFormContainer';

const UploadModalContainer = () => {
  const isModalVisible = useSelector(selectIsUploadModalVisible);
  const modalType = useSelector(selectModalType);
  const postId = useSelector(modalDataIdSelector);
  const dispatch = useDispatch();

  const redirect = useRedirect();

 const isAuthenticated = useIsAuthenticated();

  const { user } = useUser({
    skip: !isAuthenticated,
  });

  const isEditMode = modalType === ModalTypes.Edit;

  const handleClose = useCallback(() => {
    redirect(`/${user!.username}`);
    dispatch(closeModal());
  }, [dispatch, redirect, user]);

  return (
    <Modal isVisible={isModalVisible}>
      <Card>
        {isEditMode ? (
          <UploadFormUpdateContainer onSuccess={handleClose} postId={postId} />
        ) : (
          <UploadFormCreateContainer onSuccess={handleClose} />
        )}
      </Card>
    </Modal>
  );
};

UploadModalContainer.displayName = 'UploadModalContainer';

export default UploadModalContainer;
