import { cx } from '@emotion/css';
import { forwardRef, ReactNode } from 'react';

import { cardClassName } from './styles';

interface Props {
  children: ReactNode;
  className?: string;
}

type Ref = HTMLDivElement;

const Card = forwardRef<Ref, Props>(
  ({ children, className = '' }: Props, ref) => {
    return (
      <div
        className={cx(cardClassName, { [className]: !!className })}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

Card.displayName = 'Card';

export default Card;
