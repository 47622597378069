export const FORM_FIELDS = {
  EMAIL: {
    name: 'email',
    label: 'Email',
  } as const,
  FIRST_NAME: {
    name: 'firstName',
    label: 'First Name',
  } as const,
  LAST_NAME: {
    name: 'lastName',
    label: 'Last Name',
  } as const,
  PASSWORD: {
    name: 'password',
    label: 'Password',
  } as const,
  USERNAME: {
    name: 'username',
    label: 'Username',
  } as const,
}