import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Centered, Label, Layout } from 'apps/auth/scripts/components';
import { EmailSvg } from 'apps/shared/scripts/assets';
import { useSendEmailVerification, useUser } from 'apps/shared/scripts/graphql';
import { useToast } from 'apps/shared/scripts/hooks';

import { descriptionClassName } from './styles';

const VerificationExpired = () => {
  const { user } = useUser();
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('r');

  const { mutate: resendEmail, isMutating: isLoading } =
    useSendEmailVerification();
  const toast = useToast();

  const navigate = useNavigate();

  const onSubmit = useCallback(async () => {
    const { errors } = await resendEmail();
    if (errors.length) {
      return toast.error(errors[0].message);
    }
    toast.success(`An email has been sent to ${user!.email}`);
    referrer ? navigate(-1) : navigate('/settings');
  }, [navigate, referrer, resendEmail, toast, user]);

  return (
    <Layout
      centered
      buttonLabel={'Resend Email'}
      heading={'Verification Expired'}
      icon={<EmailSvg />}
      isLoading={isLoading}
      subheader={
        <Centered>
          <Label
            value={
              <div className={descriptionClassName}>
                <span>
                  We were unable to verify your email as the verification link
                  has expired.
                </span>
                <span>You may request a new one be sent to:</span>
                <span>
                  <b>{user!.email}</b>
                </span>
              </div>
            }
          />
        </Centered>
      }
      onSubmit={onSubmit}
    />
  );
};

VerificationExpired.displayName = 'VerificationExpired';

export default VerificationExpired;
