import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const primaryButtonClassName = css({
  background: '#061524',
  borderRadius: 8,
  color: Colors.White,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  lineHeight: 1.3,
  padding: `${Spacing.SM}px ${Spacing.XL}px`,
  '&:hover': {
    opacity: 0.9,
  },
  '&:active': {
    transform: 'scale(0.98)',
  },
  '&:disabled': {
    opacity: 0.4,
    transform: 'none',
  },
});

export const secondaryButtonClassName = css({
  background: 'none',
  color: Colors.Secondary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  lineHeight: 1.3,
  marginRight: Spacing.MD,
  padding: 0,
  '&:hover': {
    color: Colors.Primary,
  },
});

export const uploadFooterClassName = css({
  borderTop: '1px solid #DDD',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: `${Spacing.MD}px ${Spacing.MD}px`,
  zIndex: -1,
});
