import { ReactNode } from 'react';

import { uploadContentClassName } from './styles';

interface Props {
  children: ReactNode;
}

const UploadContent = ({ children }: Props) => {
  return (
    <div className={uploadContentClassName}>
      {children}
    </div>
  );
};

UploadContent.displayName = 'UploadContent';

export default UploadContent;
