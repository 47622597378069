import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { Initializer } from 'apps/shared/scripts/containers';
import store from 'apps/shared/scripts/redux/store';

import router from './router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'apps/shared/scripts/styles/global.css';

const App = () => {
  return (
    <Initializer>
      <Provider store={store}>
        <RouterProvider router={router} />
        <Toaster reverseOrder={false} />
      </Provider>
    </Initializer>
  );
};

App.displayName = 'App';

export default App;
