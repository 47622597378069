import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Dropdown, DropdownItem, Icon } from 'apps/shared/scripts/components';
import { useDeletePost } from 'apps/shared/scripts/graphql';
import { useToast } from 'apps/shared/scripts/hooks';
import { closeModal, ModalTypes, openModal } from 'apps/shared/scripts/redux';

import {
  activeClassName,
  anchorClassName,
  headActionsClassName,
  iconClassName,
} from './styles';

interface Props {
  postId: string;
}

const HeadActions = ({ postId }: Props) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { isMutating: isDeleting, mutate: deletePost } = useDeletePost({
    awaitRefetchQueries: true,
    refetchQueries: ['PostsByUsernameQuery'],
  });

  const onEdit = useCallback(() => {
    dispatch(openModal({ type: ModalTypes.Edit, id: postId }));
  }, [dispatch, postId]);

  const onDelete = useCallback(async () => {
    const confirm = window.confirm(
      'Are you sure you want to delete this post?'
    );
    if (confirm) {
      const { result, errors } =  await deletePost({ id: postId });
      if (!result || errors.length > 0) {
        toast.error('Unable to delete post');
      } else {
        toast.success('Success! Post deleted');
        dispatch(closeModal());
      }
    }
  }, [deletePost, dispatch, postId, toast]);

  return (
    <div className={headActionsClassName}>
      <Dropdown
        activeClassName={activeClassName}
        anchor={<Icon className={iconClassName} fa={faEllipsis} />}
        anchorClassName={anchorClassName}
      >
        <DropdownItem disabled={isDeleting} onClick={onEdit}>
          Edit
        </DropdownItem>
        <DropdownItem
          disabled={isDeleting}
          loading={isDeleting}
          onClick={onDelete}
        >
          Delete
        </DropdownItem>
      </Dropdown>
    </div>
  );
};

HeadActions.displayName = 'HeadActions';

export default HeadActions;
