import { ReactNode } from 'react';

import { centeredClassName } from './styles';

interface Props {
  children: ReactNode;
}

const Centered = ({ children }: Props) => {
  return <div className={centeredClassName}>{children}</div>;
};

Centered.displayName = 'Centered';

export default Centered;