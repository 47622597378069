import { css } from '@emotion/css';

import { Colors, FontSize, Spacing } from 'apps/shared/scripts/styles/constants';

export const verifiedBadgeClassName = css({
  alignItems: 'center',
  display: 'flex',
});

export const labelClassName = css({
  display: 'block',
  marginRight: Spacing.XS,
});

export const mutedClassName = css({
  color: '#999',
  display: 'block',
  fontSize: FontSize.REGULAR,
});

export const iconClassName = css({
  color: Colors.Green,
  height: 16,
  width: 16,
});

export const linkClassName = css({
  color: '#999',
  padding: `0 ${Spacing['3XS']}px`,
  transition: '0.2s',
  '&:hover': {
    color: '#777',
  }
});
