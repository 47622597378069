import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

import {
  ForgotContainer,
  LoginContainer,
  LogoutContainer,
  ResetContainer,
  SignupContainer,
  VerifyContainer,
} from 'apps/auth/scripts/containers';
import { LandingContainer } from 'apps/landing/scripts/containers';
import { FaqContainer } from 'apps/marketing/faq/containers';
import { PrivacyContainer } from 'apps/marketing/privacy/containers';
import { TermsContainer } from 'apps/marketing/terms/containers';
import {
  ProfileContainer,
  ProfileIndexContainer,
} from 'apps/profile/scripts/containers';
import { SettingsContainer } from 'apps/settings/scripts/containers';
import { RequireAuth, RequireUnauth } from 'apps/shared/scripts/components';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<LandingContainer />} />
      <Route path=":username" element={<ProfileContainer />} />
      <Route
        path="profile"
        element={
          <RequireAuth>
            <ProfileIndexContainer />
          </RequireAuth>
        }
      />
      <Route
        path="settings"
        element={
          <RequireAuth>
            <SettingsContainer />
          </RequireAuth>
        }
      />
      <Route
        path="login"
        element={
          <RequireUnauth>
            <LoginContainer />
          </RequireUnauth>
        }
      />
      <Route
        path="signup"
        element={
          <RequireUnauth>
            <SignupContainer />
          </RequireUnauth>
        }
      />
      <Route
        path="forgot"
        element={
          <RequireUnauth>
            <ForgotContainer />
          </RequireUnauth>
        }
      />
      <Route
        path="reset"
        element={
          <RequireUnauth>
            <ResetContainer />
          </RequireUnauth>
        }
      />
      <Route
        path="verify"
        element={
          <RequireAuth>
            <VerifyContainer />
          </RequireAuth>
        }
      />
      <Route path="logout" element={<LogoutContainer />}></Route>
      <Route
        path="faq"
        element={<FaqContainer />}
      />
      <Route
        path="terms"
        element={<TermsContainer />}
      />
      <Route
        path="privacy"
        element={<PrivacyContainer />}
      />
    </Route>
  )
);

export default router;
