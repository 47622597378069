import { Navigate } from 'react-router-dom';

import { useUser } from 'apps/shared/scripts/graphql';

const ProfileIndexContainer = () => {
  const { isLoading, user } = useUser();

  return isLoading ? null : (
    <Navigate to={`/${user ? user.username : ''}`} replace />
  );
};

ProfileIndexContainer.displayName = 'ProfileIndexContainer';

export default ProfileIndexContainer;
