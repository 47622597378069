import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { usePostsByUsername } from 'apps/shared/scripts/graphql';

import { InfiniteLoader, NotFound } from '../../components';
import { ModalTypes, openModal } from '../../redux';

import EmptyPlaceholder from './EmptyPlaceholder';
import LoadingSkeleton from './LoadingSkeleton';
import Photo from './Photo';
import { colClassName, galleryClassName } from './styles';
import useInfiniteLoad from './useInfiniteLoad';

interface Props {
  username: string;
}

const GalleryContainer = ({ username }: Props) => {
  const dispatch = useDispatch();

  const { error, posts, isLoading, fetchMore, cursor, hasNextPage } =
    usePostsByUsername({
      variables: { username, first: 12 },
    });
  useInfiniteLoad({ posts, fetchMore, cursor, hasNextPage });

  const onClickPhoto = useCallback(
    (id: string) => {
      dispatch(openModal({ type: ModalTypes.Photo, id }));
    },
    [dispatch]
  );

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return <NotFound />;
  }

  const list = posts.map((post, i) => (
    <Col key={i} md={6} xl={4}>
      <div className={colClassName}>
        <Photo id={post.id} src={post.imageUrl} onClick={onClickPhoto} />
      </div>
    </Col>
  ));

  if (!posts.length) {
    return <EmptyPlaceholder />;
  }

  return (
    <div className={galleryClassName}>
      <Row>{list}</Row>
      <InfiniteLoader
        cursor={cursor}
        fetchMore={fetchMore}
        hasNextPage={hasNextPage}
      />
    </div>
  );
};

GalleryContainer.displayName = 'GalleryContainer';

export default GalleryContainer;
