import { cx } from '@emotion/css';
import { FC } from 'react';

import { errorClassName, errorMessageClassName } from './styles';
import { ErrorProps } from './types';

const Error: FC<ErrorProps> = ({
  className = '',
  message,
  children,
}) => {
  return (
    <div
      className={cx(errorClassName, {
        [className]: !!className,
      })}
    >
      <span className={errorMessageClassName}>{message || children}</span>
    </div>
  );
};

Error.displayName = 'Error';

export default Error;
