import * as yup from 'yup';

import { FORM_FIELDS } from './constants';

export const schema = yup.object().shape({
  [FORM_FIELDS.EMAIL.name]: yup
    .string()
    .email('Email is invalid')
    .required('Email is required'),
  [FORM_FIELDS.NAME.name]: yup.string().required('Name is required'),
  [FORM_FIELDS.PHOTO.name]: yup.string(),
  [FORM_FIELDS.USERNAME.name]: yup.string().required('Username is required'),
});
