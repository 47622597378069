import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'apps/shared/scripts/components';
import { ButtonTypes } from 'apps/shared/scripts/components/Button';
import { closeModal } from 'apps/shared/scripts/redux';

import {
  primaryButtonClassName,
  secondaryButtonClassName,
  uploadFooterClassName,
} from './styles';

interface Props {
  isDisabled?: boolean;
  isEditMode?: boolean;
  isSubmitting?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

const UploadFooter = ({
  isDisabled,
  isEditMode,
  isSubmitting,
  onCancel,
  onSubmit,
}: Props) => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    if (isEditMode) {
      dispatch(closeModal());
    } else {
      dispatch(closeModal());
      setTimeout(onCancel, 500);
    }
  }, [dispatch, isEditMode, onCancel]);

  return (
    <div className={uploadFooterClassName}>
      <Button
        className={secondaryButtonClassName}
        onClick={onClick}
        type={ButtonTypes.Button}
      >
        Cancel
      </Button>
      <Button
        className={primaryButtonClassName}
        disabled={isDisabled || isSubmitting}
        loading={isSubmitting}
        onClick={onSubmit}
        type={ButtonTypes.Submit}
        bounce
      >
        {isEditMode ? 'Save' : 'Post'}
      </Button>
    </div>
  );
};

UploadFooter.displayName = 'UploadFooter';

export default UploadFooter;
