import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const inputClassName = css({
  border: 'none',
  boxShadow: '0px 0px 0px 1px inset #DDDDDD',
  borderRadius: 8,
  color: Colors.Primary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  minHeight: 50,
  overflow: 'hidden',
  padding: `${Spacing.XS}px ${Spacing.MD}px`,
  textOverflow: 'ellipsis',
  transition: '0.2s',
  position: 'relative',
  flex: '1 1 auto',
  whiteSpace: 'nowrap',
  width: '100%',
  WebkitAppearance: 'none',

  '&:focus': {
    boxShadow: `${Colors.Primary} 0px 0px 0px 2px inset`,
  },

  '&::-webkit-input-placeholder': {
    /* Chrome/Opera/Safari */
    color: '#8f959d',
  },
  '&::-moz-placeholder': {
    /* Firefox 19+ */
    color: '#8f959d',
  },
  '&:-ms-input-placeholder': {
    /* IE 10+ */
    color: '#8f959d',
  },
  '&:-moz-placeholder': {
    /* Firefox 18- */
    color: '#8f959d',
  },
});

export const errorClassName = css({
  boxShadow: `${Colors.Red} 0px 0px 0px 2px inset !important`,
});
