import { gql } from '@apollo/client';

import { Post } from 'apps/shared/scripts/__generated__/graphql.generated';
import { connectionEdgesToList } from 'apps/shared/scripts/utils';

import { PostFragmentDoc } from '../../fragments';
import { generateQueryHook } from '../../utils';

import {
  PostsByUsernameQueryResult,
  PostsByUsernameQueryVariables,
  usePostsByUsernameQuery,
} from './usePostsByUsername.generated';

gql`
  query PostsByUsernameQuery($username: String!, $first: Int, $after: String) {
    postsByUsername(username: $username, first: $first, after: $after) {
      ... on PostConnection {
        totalCount
        edges {
          ... on PostEdge {
            cursor
            node {
              ... on Post {
                ...PostFragment
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${PostFragmentDoc}
`;

interface HookDataToOutputReturn {
  cursor: string;
  hasNextPage: boolean;
  posts: Post[];
}

const DEFAULT_RETURN: HookDataToOutputReturn = {
  cursor: '',
  hasNextPage: false,
  posts: [],
};

const hookDataToOutput = (
  data?: PostsByUsernameQueryResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    cursor: '',
    hasNextPage: false,
    posts: [],
  };

  if (!data) {
    return toReturn;
  }

  const posts =
    connectionEdgesToList(data.postsByUsername).map((node) => node) || [];
  const { endCursor: cursor, hasNextPage } = data.postsByUsername.pageInfo;

  toReturn.posts = posts;
  toReturn.cursor = cursor;
  toReturn.hasNextPage = hasNextPage;

  return toReturn;
};

const usePostsByUsername = generateQueryHook<
  PostsByUsernameQueryResult,
  PostsByUsernameQueryVariables,
  HookDataToOutputReturn
>(hookDataToOutput, usePostsByUsernameQuery, DEFAULT_RETURN);

export default usePostsByUsername;
