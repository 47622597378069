import { css } from '@emotion/css';

import { Spacing } from 'apps/shared/scripts/styles/constants';

export const descriptionClassName = css({
  'span': {
    display: 'block',
    marginBottom: Spacing.MD,
    '&:last-child': {
      marginBottom: 0,
    }
  }
});
