import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { Icon } from 'apps/shared/scripts/components';
import { useUser } from 'apps/shared/scripts/graphql';

import {
  iconClassName,
  labelClassName,
  linkClassName,
  mutedClassName,
  verifiedBadgeClassName,
} from './styles';

interface Props {
  email: string;
}

const VerifiedBadge = ({ email }: Props) => {
  const { user } = useUser();
  const { isVerified } = user!;

  return (
    <div className={verifiedBadgeClassName}>
      <span className={labelClassName}>{email}</span>
      {isVerified ? (
        <Icon className={iconClassName} fa={faCircleCheck} />
      ) : (
        <span className={mutedClassName}>
          (
          <Link className={linkClassName} to={`/verify?r=${encodeURIComponent(window.location.href)}`}>
            Unverified
          </Link>
          )
        </span>
      )}
    </div>
  );
};

VerifiedBadge.displayName = 'VerifiedBadge';

export default VerifiedBadge;
