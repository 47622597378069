import { cx } from '@emotion/css';
import { forwardRef, ForwardRefRenderFunction } from 'react';

import control from '../control';
import Input from '../Input/Input';

import {
  errorClassName,
  inputClassName,
  prefixClassName,
  prefixInputClassName,
} from './styles';
import { PrefixInputProps } from './types';

const PrefixInput: ForwardRefRenderFunction<any, PrefixInputProps> = (
  { prefix, hasError, ...props },
  ref
) => {
  return (
    <div className={cx(prefixInputClassName, { [errorClassName]: hasError })}>
      <span className={prefixClassName}>{prefix}</span>
      <Input className={inputClassName} {...props} ref={ref} />
    </div>
  );
};

PrefixInput.displayName = 'PrefixInput';

export default control(forwardRef<any, PrefixInputProps>(PrefixInput));
