import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Button } from 'apps/shared/scripts/components';
import { useUser } from 'apps/shared/scripts/graphql';
import { useIsAuthenticated } from 'apps/shared/scripts/hooks';

import {
  bodyClassName,
  buttonClassName,
  ctaBannerClassName,
  headerClassName,
  headingClassName,
  iconClassName,
  secondaryButtonClassName,
  secondaryIconClassName,
  subheadingClassName,
} from './styles';

const CtaBanner = () => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const { user } = useUser({
    skip: !isAuthenticated,
  });

  const onClickDemo = useCallback(() => {
    window.open('/avgeek');
  }, []);

  const onClickSignUp = useCallback(() => {
    navigate('/signup');
  }, [navigate]);

  const onClickProfile = useCallback(() => {
    navigate(`/${user!.username}`);
  }, [navigate, user]);

  return (
    <div className={ctaBannerClassName}>
      <Container>
        <Row>
          <Col xs={12}>
            <div className={headerClassName}>
              <h2 className={headingClassName}>
                Post on the go, wherever you go
              </h2>
              <h4 className={subheadingClassName}>
                Create a free account and discover a new way to showcase your
                aviation photos.
              </h4>
            </div>
            <div className={bodyClassName}>
              <Button
                className={buttonClassName}
                faIcon={faArrowRight}
                iconClassName={iconClassName}
                iconAft
                onClick={onClickDemo}
              >
                View Demo
              </Button>
              <Button
                className={secondaryButtonClassName}
                faIcon={faArrowRight}
                iconClassName={secondaryIconClassName}
                iconAft
                onClick={isAuthenticated ? onClickProfile : onClickSignUp}
              >
                {isAuthenticated ? 'Go to Profile' : 'Create Account'}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

CtaBanner.displayName = 'CtaBanner';

export default CtaBanner;
