
import { cx } from '@emotion/css';

import { textClassName } from './styles';
import { ButtonProps } from './types';

interface Props {
  className: ButtonProps['textClassName'];
  children: ButtonProps['children'];
}

const Text = ({ className = '', children }: Props) => {
  return (
    <span className={cx(textClassName, { [className]: !!className })}>
      {children}
    </span>
  );
};

Text.displayName = 'Text';

export default Text;
