import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import {
  PostFragmentDoc,
  usePostById,
  useUpdatePost,
} from 'apps/shared/scripts/graphql';
import { useFormAsync } from 'apps/shared/scripts/hooks';

import { FormValues } from './types';
import UploadForm from './UploadForm';
import { toFormValues, toInput } from './utils';

interface Props {
  onSuccess: () => void;
  postId: string;
}

const UploadFormUpdateContainer = ({
  onSuccess: onSuccessCallback,
  postId,
}: Props) => {
  const client = useApolloClient();
  const { mutate: onUpdate } = useUpdatePost({
    awaitRefetchQueries: true,
    refetchQueries: ['PostsByUsernameQuery'],
  });
  const { isLoading, onStart, onFail, onSuccess } = useFormAsync();

  const cachedPost = client.readFragment({
    id: `Post:${postId}`,
    fragment: PostFragmentDoc,
  });

  const { isLoading: isLoadingPost, post: fetchedPost } = usePostById({
    variables: { id: postId },
    skip: !!cachedPost || !postId,
  });

  const post = cachedPost || fetchedPost;

  const handleSave = useCallback(
    async (data: FormValues) => {
      onStart();
      const input = toInput(data);
      const { result, errors } = await onUpdate({ id: postId, input });
      if (!result || errors.length > 0) {
        onFail();
        throw new Error('Unable to update post');
      }
      onSuccess(onSuccessCallback);
    },
    [onFail, onStart, onSuccess, onSuccessCallback, onUpdate, postId]
  );

  if (isLoadingPost || !post) {
    return null; // todo(jgan): loading skeleton
  }

  const defaultValues = toFormValues(post);

  return (
    <UploadForm
      defaultValues={defaultValues}
      handleSave={handleSave}
      isEditMode
      isSaving={isLoading}
    />
  );
};

UploadFormUpdateContainer.displayName = 'UploadFormUpdateContainer';

export default UploadFormUpdateContainer;
