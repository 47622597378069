import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  MutationTuple,
  PureQueryOptions,
  RefetchQueriesFunction,
} from '@apollo/client';
import { useCallback } from 'react';

type MutationDataToOutput<T, V> = (data?: T | null) => V;

type UseMutationOptions = {
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
  awaitRefetchQueries?: boolean;
};

function generateMutationHook<T, U, V extends { result?: any }>(
  hookDataToOutput: MutationDataToOutput<T, V>,
  useMutationFn: (
    baseOptions?: MutationHookOptions<T, U>
  ) => MutationTuple<T, U, DefaultContext, ApolloCache<any>>
) {
  return function useMutation({
    refetchQueries,
    awaitRefetchQueries,
  }: UseMutationOptions | undefined = {}) {
    const [mutate, { loading: isMutating }] = useMutationFn();

    const mutateWrapped = useCallback(
      async (variables?: U) => {
        const errors: Error[] = [];

        const { data, errors: apolloErrors } = await mutate({
          variables,
          refetchQueries,
          awaitRefetchQueries,
        });

        if (apolloErrors && apolloErrors.length > 0) {
          errors.push(...apolloErrors);
        }

        const { result } = hookDataToOutput(data);

        return { result: result as V['result'], errors };
      },
      [awaitRefetchQueries, mutate, refetchQueries]
    );

    return { mutate: mutateWrapped, isMutating };
  };
}

export default generateMutationHook;
