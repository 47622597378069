import {
  ObservableQueryFields,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  WatchQueryFetchPolicy,
} from '@apollo/client';

type QueryDataToOutput<T, V> = (data?: T | null) => V;

type UseQueryReturn<T, U extends OperationVariables> = {
  error?: string;
  fetchMore: ObservableQueryFields<T, U>['fetchMore'];
  isLoading: boolean;
  skip?: boolean;
};

type UseQueryOptions<U extends OperationVariables> = {
  fetchPolicy?: WatchQueryFetchPolicy;
  nextFetchPolicy?: WatchQueryFetchPolicy;
  variables?: U;
  skip?: boolean;
};

function generateQueryHook<T, U extends OperationVariables, V>(
  hookDataToOutput: QueryDataToOutput<T, V>,
  useQueryFn: (baseOptions: QueryHookOptions<T, U>) => QueryResult<T, U>,
  defaultReturn: V
) {
  return function useQuery({
    fetchPolicy,
    nextFetchPolicy,
    variables,
    skip,
  }: UseQueryOptions<U> | undefined = {}): UseQueryReturn<T, U> & V {
    const {
      data,
      error,
      fetchMore,
      loading: isLoading,
    } = useQueryFn({
      fetchPolicy,
      nextFetchPolicy,
      variables,
      skip,
    });

    const toReturn: UseQueryReturn<T, U> & V = {
      error: undefined,
      fetchMore,
      isLoading,
      ...defaultReturn,
    };

    if (isLoading) {
      toReturn.isLoading = isLoading;
      return toReturn;
    }

    if (error || !data) {
      toReturn.error = error
        ? error.message
        : 'An error occurred while fetching data.';
      return toReturn;
    }

    const result = hookDataToOutput(data);

    return { ...toReturn, ...result };
  };
}

export default generateQueryHook;
