import { Container } from 'react-bootstrap';

import { Footer, TopNav } from 'apps/shared/scripts/components';
import { UploadModalContainer } from 'apps/upload/scripts/containers';

import { Hero } from '../../components';

import Questions from './Questions';

const FaqContainer = () => {
  return (
    <>
      <TopNav />
      <Container>
        <Hero />
        <Questions />
        <UploadModalContainer />
      </Container>
      <Footer />
    </>
  );
};

FaqContainer.displayName = 'FaqContainer';

export default FaqContainer;
