import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
export type PostFragment = { readonly __typename: 'Post', readonly createdAt: any, readonly id: string, readonly imageUrl: string, readonly updatedAt: any, readonly author: { readonly __typename: 'Author', readonly name: string, readonly profileImageUrl?: string | null, readonly username: string }, readonly metadata: { readonly __typename: 'Metadata', readonly aircraft?: { readonly __typename: 'Aircraft', readonly manufacturer?: string | null, readonly type?: string | null, readonly variant?: string | null } | null, readonly airline?: { readonly __typename: 'Airline', readonly iata?: string | null, readonly icao?: string | null, readonly name?: string | null } | null, readonly airport?: { readonly __typename: 'Airport', readonly iata?: string | null, readonly icao?: string | null, readonly name?: string | null, readonly location?: { readonly __typename: 'Location', readonly country: string, readonly city: string, readonly lat: string, readonly lon: string, readonly state: string } | null } | null } };

export const PostFragmentDoc = gql`
    fragment PostFragment on Post {
  author {
    name
    profileImageUrl
    username
  }
  createdAt
  id
  imageUrl
  metadata {
    aircraft {
      manufacturer
      type
      variant
    }
    airline {
      iata
      icao
      name
    }
    airport {
      iata
      icao
      location {
        country
        city
        lat
        lon
        state
      }
      name
    }
  }
  updatedAt
}
    `;