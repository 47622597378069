import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Centered, Label, Layout } from 'apps/auth/scripts/components';
import { EmailSvg } from 'apps/shared/scripts/assets';
import { useSendEmailVerification, useUser } from 'apps/shared/scripts/graphql';
import { useToast } from 'apps/shared/scripts/hooks';

import { descriptionClassName } from './styles';

const ResendVerification = () => {
  const { user } = useUser();
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('r');

  const { mutate: resendEmail, isMutating: isLoading } =
    useSendEmailVerification();
  const toast = useToast();

  const navigate = useNavigate();

  const onSubmit = useCallback(async () => {
    const { errors } = await resendEmail();
    if (errors.length) {
      return toast.error(errors[0].message);
    }
    toast.success(`An email has been sent to ${user!.email}`);
    referrer ? navigate(-1) : navigate('/settings');
  }, [navigate, referrer, resendEmail, toast, user]);

  return (
    <Layout
      centered
      buttonLabel={'Resend Email'}
      heading={'Verify Your Email'}
      icon={<EmailSvg />}
      isLoading={isLoading}
      subheader={
        <Centered>
          <Label
            value={
              <div className={descriptionClassName}>
                <span>We've sent an email with a verification link to</span>
                <span>
                  <b>{user!.email}</b>
                </span>
                <span>
                  Just click on the link to complete your profile. If you don't
                  see it, please <b>check your spam</b> folder.
                </span>
                <span>Still can't find the email? No problem.</span>
              </div>
            }
          />
        </Centered>
      }
      onSubmit={onSubmit}
    />
  );
};

ResendVerification.displayName = 'ResendVerification';

export default ResendVerification;
