import { gql } from '@apollo/client';

import { generateMutationHook } from '../../utils';

import {
  useValidateOAuthUserMutation,
  ValidateOAuthUserMutationResult,
  ValidateOAuthUserMutationVariables,
} from './useValidateOAuthUser.generated';

gql`
  mutation ValidateOAuthUser($input: ValidateOAuthUserInput!) {
    validateOAuthUser(input: $input) {
      _empty
    }
  }
`;

interface HookDataToOutputReturn {
  result?: boolean;
}

const hookDataToOutput = (
  data?: ValidateOAuthUserMutationResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    result: undefined,
  };

  if (data?.validateOAuthUser.__typename === 'EmptyResponse') {
    toReturn.result = true;
  }

  return toReturn;
};

const useValidateOAuthUser = generateMutationHook<
  ValidateOAuthUserMutationResult,
  ValidateOAuthUserMutationVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useValidateOAuthUserMutation);

export default useValidateOAuthUser;
