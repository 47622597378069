import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLoginUser } from 'apps/auth/scripts/hooks';
import { TopNav } from 'apps/shared/scripts/components';
import { useValidateOAuthUser } from 'apps/shared/scripts/graphql';

import LoginForm, { FormValues } from './LoginForm';

const LoginContainer = () => {
  const { isLoading, login } = useLoginUser();
  const { mutate: validateOAuthUser } = useValidateOAuthUser();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('r');

  const handleLogin = useCallback(
    async ({ email, password }: FormValues) => {
      const { result, error } = await login(email, password);
      if (error.message || !result) {
        throw new Error(error.message);
      }
      if (referrer) {
        window.location.href = referrer;
      } else {
        navigate(`/profile`, { replace: true });
      }
    },
    [login, navigate, referrer]
  );

  const handleOAuthLogin = useCallback(
    async (email: string) => {
      const { result, errors } = await validateOAuthUser({ input: { email } });
      if (!result || errors.length > 0) {
        throw new Error('No account with this email could be found.');
      }
      navigate('/profile', { replace: true });
    },
    [navigate, validateOAuthUser]
  );

  return (
    <>
      <TopNav />
      <LoginForm
        handleOAuthLogin={handleOAuthLogin}
        handleLogin={handleLogin}
        isLoading={isLoading}
      />
    </>
  );
};

LoginContainer.displayName = 'LoginContainer';

export default LoginContainer;
