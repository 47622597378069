import { usePostById, useUser } from '../../graphql';

interface Options {
  postId: string;
}

const useIsPostOwner = ({ postId }: Options) => {
  const { isLoading: isLoadingUser, user } = useUser();
  const { isLoading: isLoadingPost, post } = usePostById({
    variables: { id: postId },
  });

  return {
    isLoading: isLoadingPost || isLoadingUser,
    isPostOwner: post?.author.username === user?.username,
  };
};

export default useIsPostOwner;
