import { cx } from '@emotion/css';

import { aftClassName, iconClassName, preClassName } from './styles';
import { ButtonProps } from './types';
import { faToSvg } from './utils';

interface Props {
  aft?: boolean;
  className?: ButtonProps['iconClassName'];
  fa?: ButtonProps['faIcon'];
  pre?: boolean;
  svg?: ButtonProps['svgIcon'];
}

const Icon = ({ aft = false, className = '', fa, pre = false, svg }: Props) => {
  return (
    <i
      className={cx(iconClassName, {
        [preClassName]: pre,
        [aftClassName]: aft,
        [className]: !!className,
      })}
    >
      {fa && faToSvg(fa)}
      {svg && svg}
    </i>
  );
};

Icon.displayName = 'Icon';

export default Icon;
