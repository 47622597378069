import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ForgotPasswordMutationVariables = Types.Exact<{
  input: Types.ForgotPasswordInput;
}>;


export type ForgotPasswordMutationResult = { readonly __typename: 'Mutation', readonly forgotPassword: { readonly __typename: 'EmptyResponse', readonly _empty?: boolean | null } };


export const ForgotPasswordMutationDocument = gql`
    mutation ForgotPasswordMutation($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    _empty
  }
}
    `;
export type ForgotPasswordMutationMutationFn = Apollo.MutationFunction<ForgotPasswordMutationResult, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutationResult, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutationResult, ForgotPasswordMutationVariables>(ForgotPasswordMutationDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutationResult, ForgotPasswordMutationVariables>;