import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const dropzoneClassName = css({
  alignItems: 'center',
  border: '2px dashed transparent',
  borderRadius: 8,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  minHeight: 250,
  transition: '0.2s',
  width: '100%',

  '&:hover': {
    borderColor: 'transparent',
    boxShadow: 'none',
  },
});

export const innerClassName = css({
  background: Colors.White,
  overflow: 'hidden',
  padding: `${Spacing.XL}px ${Spacing.MD}`,
  position: 'relative',
  textAlign: 'center',
  width: '100%',
});

export const descriptionClassName = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const iconClassName = css({
  background: 'rgba(146, 159, 173, 0.1)',
  borderRadius: 8,
  display: 'block',
  lineHeight: '0',
  marginBottom: Spacing.MD,
  padding: Spacing.XS,

  '& svg': {
    color: Colors.Secondary,
    height: 24,
    width: 24,
  },
});

export const headingClassName = css({
  color: Colors.Secondary,
  display: 'block',
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.BOLD,
  marginBottom: Spacing.SM,
  lineHeight: LineHeight.REGULAR,
});

export const subheadingClassName = css({
  color: Colors.Secondary,
  display: 'block',
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.MEDIUM,
  marginBottom: Spacing.SM,
  lineHeight: LineHeight.REGULAR,
});

export const dropzoneActiveStyle = css({
  borderColor: 'transparent',
  boxShadow: '0px 0px 0px 3px rgba(0, 106, 255, 0.25)',
});

export const dropzoneDangerStyle = css({
  borderColor: 'transparent',
  boxShadow: '0 0 0 3px rgba(235, 23, 0, 0.25)',
});

export const dangerTextStyle = css({
  color: Colors.Red,
});
