import * as yup from 'yup';

import { FORM_FIELDS } from './constants';

export const loginSchema = yup.object().shape({
  [FORM_FIELDS.EMAIL.name]: yup
    .string()
    .email('Email is invalid')
    .required('Email is required'),
  [FORM_FIELDS.PASSWORD.name]: yup.string().required('Password is required'),
});
