import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifyEmailMutationVariables = Types.Exact<{
  input: Types.EmailVerificationInput;
}>;


export type VerifyEmailMutationResult = { readonly __typename: 'Mutation', readonly verifyEmail: { readonly __typename: 'EmptyResponse', readonly _empty?: boolean | null } };


export const VerifyEmailMutationDocument = gql`
    mutation VerifyEmailMutation($input: EmailVerificationInput!) {
  verifyEmail(input: $input) {
    _empty
  }
}
    `;
export type VerifyEmailMutationMutationFn = Apollo.MutationFunction<VerifyEmailMutationResult, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutationResult, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutationResult, VerifyEmailMutationVariables>(VerifyEmailMutationDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutationResult, VerifyEmailMutationVariables>;