import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
import { PostFragmentDoc } from '../../fragments/PostFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PostsByUsernameQueryVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type PostsByUsernameQueryResult = { readonly __typename: 'Query', readonly postsByUsername: { readonly __typename: 'PostConnection', readonly totalCount: number, readonly edges?: ReadonlyArray<{ readonly __typename: 'PostEdge', readonly cursor: string, readonly node?: { readonly __typename: 'Post', readonly createdAt: any, readonly id: string, readonly imageUrl: string, readonly updatedAt: any, readonly author: { readonly __typename: 'Author', readonly name: string, readonly profileImageUrl?: string | null, readonly username: string }, readonly metadata: { readonly __typename: 'Metadata', readonly aircraft?: { readonly __typename: 'Aircraft', readonly manufacturer?: string | null, readonly type?: string | null, readonly variant?: string | null } | null, readonly airline?: { readonly __typename: 'Airline', readonly iata?: string | null, readonly icao?: string | null, readonly name?: string | null } | null, readonly airport?: { readonly __typename: 'Airport', readonly iata?: string | null, readonly icao?: string | null, readonly name?: string | null, readonly location?: { readonly __typename: 'Location', readonly country: string, readonly city: string, readonly lat: string, readonly lon: string, readonly state: string } | null } | null } } | null } | null> | null, readonly pageInfo: { readonly __typename: 'PageInfo', readonly endCursor: string, readonly hasNextPage: boolean } } };


export const PostsByUsernameQueryDocument = gql`
    query PostsByUsernameQuery($username: String!, $first: Int, $after: String) {
  postsByUsername(username: $username, first: $first, after: $after) {
    ... on PostConnection {
      totalCount
      edges {
        ... on PostEdge {
          cursor
          node {
            ... on Post {
              ...PostFragment
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
    ${PostFragmentDoc}`;

/**
 * __usePostsByUsernameQuery__
 *
 * To run a query within a React component, call `usePostsByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePostsByUsernameQuery(baseOptions: Apollo.QueryHookOptions<PostsByUsernameQueryResult, PostsByUsernameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostsByUsernameQueryResult, PostsByUsernameQueryVariables>(PostsByUsernameQueryDocument, options);
      }
export function usePostsByUsernameQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsByUsernameQueryResult, PostsByUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostsByUsernameQueryResult, PostsByUsernameQueryVariables>(PostsByUsernameQueryDocument, options);
        }
export type PostsByUsernameQueryHookResult = ReturnType<typeof usePostsByUsernameQuery>;
export type PostsByUsernameQueryLazyQueryHookResult = ReturnType<typeof usePostsByUsernameQueryLazyQuery>;