import { css } from '@emotion/css';

import { Colors, FontWeight, LineHeight, Spacing } from 'apps/shared/scripts/styles/constants';

export const heroClassName = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '64px 0 32px',
  textAlign: 'center',
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: 36,
  fontWeight: FontWeight.SEMI_BOLD,
});

export const subheadingClassName = css({
  color: Colors.Secondary,
  fontSize: 18,
  fontWeight: FontWeight.NORMAL,
  lineHeight: LineHeight.LARGE,
  marginTop: Spacing.LG,
  maxWidth: 700,
});