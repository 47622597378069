import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Collapsible, Group, Input } from 'apps/shared/scripts/components';
import { useToast, useYupValidation } from 'apps/shared/scripts/hooks';

import { DEFAULT_VALUES, FORM_FIELDS } from './constants';
import { labelClassName } from './styles';
import { FormValues } from './types';
import { schema } from './validation';

export interface Props {
  handleSave: (data: FormValues) => Promise<void>;
  isSaving: boolean;
}

const PasswordForm = ({ handleSave, isSaving }: Props) => {
  const {
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
    reset,
  } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    resolver: useYupValidation<FormValues>(schema),
  });
  const [isActive, setActive] = useState<boolean>(false);

  const toast = useToast();

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data) => {
      try {
        await handleSave(data);
        reset();
        setActive(false);
        toast.success(`Password updated successfully`);
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }
    },
    [handleSave, reset, toast]
  );

  const onCancel = useCallback(async () => {
    reset();
    setActive(false);
  }, [reset]);

  const onEdit = useCallback(async () => {
    setActive(true);
  }, []);

  const onSave = handleSubmit(onSubmit);

  return (
    <form onSubmit={onSave}>
      <Collapsible
        error={errors[FORM_FIELDS.CONFIRM_PASSWORD.name]}
        isActive={isActive}
        isDirty={Object.keys(dirtyFields).length === 3}
        isSaving={isSaving}
        label="Password"
        onCancel={onCancel}
        onEdit={onEdit}
        onSave={onSave}
      >
        <Group
          labelClassName={labelClassName}
          label={FORM_FIELDS.CURRENT_PASSWORD.label}
        >
          <Input
            control={control}
            name={FORM_FIELDS.CURRENT_PASSWORD.name}
            type="password"
          />
        </Group>
        <Group
          labelClassName={labelClassName}
          label={FORM_FIELDS.NEW_PASSWORD.label}
          spacing
        >
          <Input
            control={control}
            name={FORM_FIELDS.NEW_PASSWORD.name}
            type="password"
          />
        </Group>
        <Group
          labelClassName={labelClassName}
          label={FORM_FIELDS.CONFIRM_PASSWORD.label}
          spacing
        >
          <Input
            control={control}
            name={FORM_FIELDS.CONFIRM_PASSWORD.name}
            type="password"
          />
        </Group>
      </Collapsible>
    </form>
  );
};

PasswordForm.displayName = 'PasswordForm';

export default PasswordForm;
