import { css } from '@emotion/css';

import { Breakpoints, Colors, Spacing } from 'apps/shared/scripts/styles/constants';

export const dismissClassName = css({
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 999,
});

export const dismissButtonClassName = css({
  background: 'none',
  border: 'none',
  borderRadius: '50%',
  padding: 0,
  position: 'relative',
  i: {
    marginRight: 0,
    svg: {
      fill: Colors.Primary,
      height: 20,
      width: 20,
    },
  },
  '&:hover, &:active': {
    '@media (min-width: 768px)': {
      background: 'rgba(255, 255, 255, 0.2)',
      '&::before': {
        background: 'rgba(66, 79, 96, 0.05)',
      },
    },
  },
  '&::before': {
    content: '""',
    display: 'block',
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(calc(-50% - 0.5px), calc(-50% - 0.5px))',
    height: 40,
    width: 40,
  },
});

export const mobileDismissClassName = css({
  display: 'block',
  marginLeft: `-${Spacing.XS}px`,
  marginRight: Spacing.SM,
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    display: 'none',
  },
});

export const mobileDismissButtonClassName = css({
  border: 'none',
  padding: 0,
  i: {
    marginRight: 0,
    svg: {
      fill: Colors.Primary,
      height: 28,
      width: 28,
    },
  },
});

export const dismissFixedClassName = css({
  display: 'none',
  height: 20,
  width: 20,
  margin: `${Spacing.SM}px ${Spacing.MD}px`,
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    display: 'block',
  },
  [`@media(min-width: ${Breakpoints.MD}px)`]: {
    height: 'auto',
    width: 'auto',
    margin: 'auto',
    position: 'fixed',
    top: Spacing.MD,
  },
  [`@media(min-width: ${Breakpoints.LG}px)`]: {
    left: Spacing.MD,
  },
});

export const dismissButtonFixedClassName = css({
  i: {
    marginRight: 0,
    svg: {
      fill: 'rgba(255, 255, 255, 0.9)',
      height: 21,
      width: 21,
    },
  },
  '&:hover, &:active': {
    'i > svg': {
      fill: Colors.White,
    },
  },
  '@media (min-width: 768px)': {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '0 50% 50% 0',
    padding: '0.6rem 0.6rem 0.6rem 0.3rem',
  },
  '@media (min-width: 992px)': {
    borderRadius: '50%',
    padding: Spacing.XS,
    height: 42,
    width: 42,
  },
});
