import { css } from '@emotion/css';

import { Breakpoints, Spacing } from 'apps/shared/scripts/styles/constants';

export const uploadDropzoneClassName = css({
  marginBottom: Spacing.LG,
  marginTop: Spacing['2XS'],
  padding: `0 ${Spacing.MD}px`,
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    padding: `0 ${Spacing.LG}px`,
  },
});
