import { useCallback } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { TopNav } from 'apps/shared/scripts/components';
import { useResetPassword } from 'apps/shared/scripts/graphql';

import ResetForm, { FormValues } from './ResetForm';
import { toInput } from './utils';

const ResetContainer = () => {
  const { isMutating: isLoading, mutate: resetPassword } = useResetPassword();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');

  const navigate = useNavigate();

  const handleReset = useCallback(
    async (data: FormValues) => {
      const input = toInput(data, token!);
      const { result, errors } = await resetPassword({ input });
      if (!result || errors.length > 0) {
        throw new Error(errors[0].message);
      }
      navigate('/login');
    },
    [token, resetPassword, navigate]
  );

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <TopNav />
      <ResetForm handleReset={handleReset} isLoading={isLoading} />
    </>
  );
};

ResetContainer.displayName = 'ResetContainer';

export default ResetContainer;
