import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Centered, Label, Layout } from 'apps/auth/scripts/components';
import { EmailSvg } from 'apps/shared/scripts/assets';

import { descriptionClassName } from './styles';

const VerificationFailed = () => {
  const navigate = useNavigate();

  const onSubmit = useCallback(async () => {
    navigate('/settings');
  }, [navigate]);

  return (
    <Layout
      centered
      buttonLabel={'Return Home'}
      heading={'Verification Failed'}
      icon={<EmailSvg />}
      subheader={
        <Centered>
          <Label
            value={
              <div className={descriptionClassName}>
                <span>We were unable to verify your email.</span>
                <span>
                  Either the verification link may have expired, or the
                  verification token does not exist.
                </span>
              </div>
            }
          />
        </Centered>
      }
      onSubmit={onSubmit}
    />
  );
};

VerificationFailed.displayName = 'VerificationFailed';

export default VerificationFailed;
