import { FormValues } from './types';

export const FORM_FIELDS = {
  EMAIL: {
    name: 'email',
    label: 'Email',
  } as const,
  NAME: {
    name: 'name',
    label: 'Name',
  } as const,
  PHOTO: {
    name: 'photo',
    label: 'Photo',
  } as const,
  USERNAME: {
    name: 'username',
    label: 'Username',
  } as const,
};

export const DEFAULT_VALUES: FormValues = {
  email: '',
  name: '',
  photo: '',
  username: '',
};
