import { css } from '@emotion/css';

export const pageLoaderClassName = css({
  alignItems: 'center',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
});

export const spinnerClassName = css({

});

export const iconClassName = css({
  height: 24,
  width: 24,
});
