import { useApolloClient } from '@apollo/client';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

import Modal, { Card, Dismiss } from '../../components/Modal';
import { PostFragmentDoc, usePostById } from '../../graphql';
import {
  modalDataIdSelector,
  selectIsPhotoModalVisible,
} from '../../redux/selectors';

import Controls from './Controls';
import Head from './Head';
import Meta from './Meta';
import Photo from './Photo';
import { cardClassName } from './styles';

const PhotoModalContainer = () => {
  const isVisible = useSelector(selectIsPhotoModalVisible);
  const postId = useSelector(modalDataIdSelector);
  const client = useApolloClient();
  const ref = useRef<HTMLDivElement>(null);

  const cachedPost = client.readFragment({
    id: `Post:${postId}`,
    fragment: PostFragmentDoc,
  });

  const { isLoading, post: fetchedPost } = usePostById({
    variables: { id: postId },
    skip: !!cachedPost || !postId,
  });

  const post = cachedPost || fetchedPost;

  return (
    <Modal isScrollable isVisible={isVisible} draggable childRef={ref}>
      <Dismiss fixed />
      <Controls id={postId} />
      <Card className={cardClassName} ref={ref}>
        <Head postId={postId} />
        {isLoading || !post ? null : (
          <>
            <Photo
              image={{
                url: post.imageUrl,
              }}
            />
            <Meta metadata={post.metadata} />
          </>
        )}
      </Card>
    </Modal>
  );
};

PhotoModalContainer.displayName = 'PhotoModalContainer';

export default PhotoModalContainer;
