import {
  AbstractElement,
  icon,
  IconLookup,
  IconName,
} from '@fortawesome/fontawesome-svg-core';
import omit from 'lodash/omit';
import { createElement, ReactElement } from 'react';

const CONFIG = {
  className: 'css-svg-icon',
  filteredProps: ['children'],
  omittedAttributes: {
    class: true,
    'data-prefix': true,
  },
};

interface parsedFaProps {
  type: string;
  props: any;
}

const abstractFa = (fa: IconName | IconLookup): AbstractElement => {
  return icon(fa).abstract[0];
};

const parseFa = (fa: AbstractElement): parsedFaProps => {
  const props: Record<string, any> = {};
  Object.entries(fa.attributes)
    .filter(([k]) => !(k in CONFIG.omittedAttributes))
    .forEach(([k, v]) => (props[k] = v));
  const children = fa.children?.map((child) => parseFa(child)) || null;
  return {
    type: fa.tag,
    props: { ...props, children },
  };
};

const generateSvg = (fa: parsedFaProps): ReactElement => {
  if (!fa.props.children) return createElement(fa.type, fa.props, null);
  const filteredProps = omit(fa.props, CONFIG.filteredProps);
  return createElement(
    fa.type,
    { className: CONFIG.className, ...filteredProps },
    generateSvg(fa.props.children[0])
  );
};

export const faToSvg = (fa: IconName | IconLookup): ReactElement => {
  const abstractedFa = abstractFa(fa);
  const parsedFa = parseFa(abstractedFa);
  const generatedSvg = generateSvg(parsedFa);
  return generatedSvg;
};
