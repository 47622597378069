import debounce from 'lodash/debounce';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';

import { Combobox, Group } from 'apps/shared/scripts/components';
import { useAutocompleteAirline } from 'apps/shared/scripts/graphql';

import { FORM_FIELDS } from '../../containers/UploadFormContainer/constants';

interface Props<T extends FieldValues> {
  control: Control<T>;
  errors?: FieldErrors<T>;
}

const UploadMetadata = <T extends FieldValues>({
  control,
  errors,
}: Props<T>) => {
  const [query, setQuery] = useState<string>('');
  const { isLoading, suggestions } = useAutocompleteAirline({
    variables: {
      query,
    },
  });
  return (
    <Row>
      <Col xs={12}>
        <Group
          errors={errors}
          label={FORM_FIELDS.AIRLINE.label}
          validate={FORM_FIELDS.AIRLINE.name}
        >
          <Combobox
            isLoading={isLoading}
            onAutocomplete={debounce((v: string) => setQuery(v), 500)}
            control={control}
            name={FORM_FIELDS.AIRLINE.name}
            options={suggestions}
          />
        </Group>
      </Col>
    </Row>
  );
};

UploadMetadata.displayName = 'UploadMetadata';

export default UploadMetadata;
