import { cx } from '@emotion/css';
import { IconLookup, IconName } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';

import { Button } from 'apps/shared/scripts/components';

import { activeClassName, buttonClassName } from './styles';
import { Metadata } from './types';

interface Props {
  active: Metadata | null;
  faIcon: IconLookup | IconName;
  label: string;
  onToggle: (tab: Metadata) => void;
  type: Metadata;
}

const FieldButton = ({ active, faIcon, label, onToggle, type }: Props) => {
  const isActive = active === type;

  const onClick = useCallback(() => {
    onToggle(type);
  }, [onToggle, type]);

  return (
    <Button
      className={cx(buttonClassName, { [activeClassName]: isActive })}
      faIcon={faIcon}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

FieldButton.displayName = 'FieldButton';

export default FieldButton;
