import { css } from '@emotion/css';

import { Colors, FontWeight, Spacing } from 'apps/shared/scripts/styles/constants';

export const headerClassName = css({
  paddingTop: Spacing['3XL'],
  position: 'relative',
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: 24,
  fontWeight: FontWeight.SEMI_BOLD,
});
