import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useLogoutUser } from '../../hooks';

const LogoutContainer = () => {
  const { isLoading, logout } = useLogoutUser();

  useEffect(() => {
    logout();
  }, [logout]);

  return isLoading ? null : <Navigate to={'/'} replace />;
};

LogoutContainer.displayName = 'LogoutContainer';

export default LogoutContainer;
