import { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import { ObjectSchema } from 'yup';

const useYupValidation = <T extends FieldValues>(
  validationSchema: ObjectSchema<T>,
  showAll: boolean = false
) => {
  return useCallback(
    async (data: any) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: showAll
            ? errors.inner.reduce(
                (allErrors: any, currentError: any) => ({
                  ...allErrors,
                  [currentError.path]: {
                    type: currentError.type ?? 'validation',
                    message: currentError.message,
                  },
                }),
                {}
              )
            : {
                [errors.inner[0].path]: {
                  type: errors.inner[0].type ?? 'validation',
                  message: errors.inner[0].message,
                },
              },
        };
      }
    },
    [showAll, validationSchema]
  );
};

export default useYupValidation;
