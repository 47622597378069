import { Image } from 'apps/shared/scripts/components';

import { imageClassName, photoClassName } from './styles';

interface Props {
  id: string;
  onClick: (id: string) => void;
  src: string;
}

const Photo = ({ id, onClick, src }: Props) => {
  return (
    <div className={photoClassName}>
      <Image
        className={imageClassName}
        onClick={() => onClick(id)}
        src={src}
        scaled
      />
    </div>
  );
};

Photo.displayName = 'Photo';

export default Photo;
