import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const galleryClassName = css({
  marginTop: 44,
});

export const colClassName = css({
  marginBottom: 'var(--bs-gutter-x)',
});

export const emptyPlaceholderClassName = css({
  alignItems: 'center',
  border: '1px dashed #ddd',
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: 50,
  marginBottom: 75,
  padding: 100,
});

export const iconClassName = css({
  color: Colors.Secondary,
  height: 30,
  width: 30,
});

export const labelClassName = css({
  color: Colors.Secondary,
  display: 'block',
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  marginTop: Spacing.MD,
});

export const skeletonClassName = css({
  marginBottom: Spacing.LG,
  minHeight: 325,
});
