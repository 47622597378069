import * as yup from 'yup';

import { FORM_FIELDS } from './constants';

export const schema = yup.object().shape({
  [FORM_FIELDS.CURRENT_PASSWORD.name]: yup
    .string()
    .required('Current password is required'),
  [FORM_FIELDS.NEW_PASSWORD.name]: yup
    .string()
    .required('New password is required'),
  [FORM_FIELDS.CONFIRM_PASSWORD.name]: yup
    .string()
    .required('Confirm password is required')
    .oneOf(
      [yup.ref(FORM_FIELDS.NEW_PASSWORD.name), ''],
      'Passwords do not match'
    ),
});
