import { Image } from 'apps/shared/scripts/components';

import { imageClassName, uploadPreviewClassName } from './styles';

interface Props {
  url: string;
}

const UploadPreview = ({ url }: Props) => {
  return (
    <div className={uploadPreviewClassName}>
      <Image className={imageClassName} src={url} scaled />
    </div>
  );
};

UploadPreview.displayName = 'UploadPreview';

export default UploadPreview;
