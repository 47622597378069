import { forwardRef, ForwardRefRenderFunction, useEffect } from 'react';
import {
  ActionMeta,
  components,
  GroupBase,
  InputActionMeta,
  InputProps,
  OptionProps,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';

import control from '../control';

import * as styles from './styles';
import type { ComboboxOption, ComboboxProps } from './types';

const Input = (
  props: InputProps<ComboboxOption, false, GroupBase<ComboboxOption>>
) => <components.Input {...{ ...props, isHidden: false }} />;

const Option = (
  props: OptionProps<ComboboxOption, false, GroupBase<ComboboxOption>>
) => (
  <components.Option {...{ ...props, isHidden: false }}>
    {props.data.tag ? (
      <div className={styles.optionClassName}>
        <span className={styles.tagClassName}>{props.data.tag}</span>
        <span>{props.data.label}</span>
      </div>
    ) : (
      props.children
    )}
  </components.Option>
);

const Combobox: ForwardRefRenderFunction<any, ComboboxProps> = (
  {
    inputRef,
    name,
    onAutocomplete,
    onBlur,
    onChange,
    onFocus,
    options,
    value,
    ...props
  },
  ref
) => {
  const handleBlur = () => {
    if (onAutocomplete) {
      onAutocomplete(value);
    }
    if (onBlur) {
      onBlur();
    }
  };

  const handleChange = (
    option: ComboboxOption | null,
    _: ActionMeta<ComboboxOption>
  ) => {
    onChange(option ? option.label : '');
  };

  const handleInputChange = (
    newValue: string,
    { action }: InputActionMeta
  ): void => {
    if (action === 'input-change') {
      onChange(newValue);
      if (onAutocomplete) {
        onAutocomplete(newValue);
      }
    }
  };

  useEffect(() => {
    if (onAutocomplete) {
      onAutocomplete(value);
    }
  }, []); // eslint-disable-line

  return (
    <CreatableSelect
      {...props}
      isLoading={false} // note: loading state affects menu placement UX
      inputValue={value}
      isClearable={true}
      isSearchable={true}
      menuPlacement="top"
      menuPosition="absolute"
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      onInputChange={handleInputChange}
      options={options}
      placeholder=""
      ref={inputRef ?? ref}
      styles={styles}
      theme={styles.theme}
      components={{ Input, Option }}
      filterOption={() => true}
    />
  );
};

Combobox.displayName = 'Combobox';

export default control(forwardRef<any, ComboboxProps>(Combobox));
