import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const dropdownClassName = css({
  position: 'relative',
});

export const buttonClassName = css({
  padding: 0,
});

export const menuClassName = css({
  background: Colors.White,
  borderRadius: 12,
  boxShadow: '0 2px 16px rgba(0,0,0,0.12)',
  marginTop: Spacing.LG,
  position: 'absolute',
  right: 0,
  top: '50%',
  maxHeight: 'calc(100vh - 100px)',
  overflowY: 'auto',
  zIndex: 2,
  minWidth: 190,
});

export const itemClassName = css({
  alignItems: 'center',
  color: Colors.Primary,
  cursor: 'pointer',
  display: 'flex',
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  minHeight: 50,
  padding: `${Spacing.SM}px ${Spacing.MD}px`,
  transition: '0.2s',
  '&:hover': {
    background: 'rgba(108, 119, 134, 0.05)',
  },
  '&:first-child': {
    paddingTop: Spacing.MD,
  },
  '&:last-child': {
    paddingBottom: Spacing.MD,
  },
});

export const loadingClassName = css({
  justifyContent: 'center',
});

export const disabledClassName = css({
  cursor: 'default !important',
  '&:hover': {
    background: 'none',
  },
});
