import { gql } from '@apollo/client';

import { generateMutationHook } from '../../utils';

import {
  ResetPasswordMutationResult,
  ResetPasswordMutationVariables,
  useResetPasswordMutation,
} from './useResetPassword.generated';

gql`
  mutation ResetPasswordMutation($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      _empty
    }
  }
`;

interface HookDataToOutputReturn {
  result?: boolean;
}

const hookDataToOutput = (
  data?: ResetPasswordMutationResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    result: undefined,
  };

  if (data?.resetPassword.__typename === 'EmptyResponse') {
    toReturn.result = true;
  }

  return toReturn;
};

const useResetPassword = generateMutationHook<
  ResetPasswordMutationResult,
  ResetPasswordMutationVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useResetPasswordMutation);

export default useResetPassword;
