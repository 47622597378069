import { useParams } from 'react-router-dom';

import { Image } from 'apps/shared/scripts/components';
import { useUserProfile } from 'apps/shared/scripts/graphql';

import {
  headingClassName,
  headProfileClassName,
  imageClassName,
  subheadingClassName,
  textClassName,
} from './styles';

const HeadProfile = () => {
  const { username } = useParams();
  const { profile } = useUserProfile({
    variables: { username: username! },
  });

  const image = profile?.profileImageUrl
    ? profile.profileImageUrl
    : 'https://res.cloudinary.com/dutl6xlom/image/upload/v1698626088/5976702cffb33336cf827d95_gzvtxl.jpg';

  return (
    <div className={headProfileClassName}>
      <Image className={imageClassName} src={image} scaled />
      <div className={textClassName}>
        <h2 className={headingClassName}>{profile!.name}</h2>
        <h4 className={subheadingClassName}>@{profile!.username}</h4>
      </div>
    </div>
  );
};

HeadProfile.displayName = 'HeadProfile';

export default HeadProfile;
