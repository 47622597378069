import { gql } from '@apollo/client';

import { generateQueryHook } from '../../utils';

import {
  AutocompleteAircraftManufacturerQueryResult,
  AutocompleteAircraftManufacturerQueryVariables,
  useAutocompleteAircraftManufacturerQuery,
} from './useAutocompleteAircraftManufacturer.generated';

gql`
  query AutocompleteAircraftManufacturerQuery($manufacturer: String!) {
    autocompleteAircraftManufacturer(manufacturer: $manufacturer)
  }
`;

interface Suggestion {
  label: string;
  value: string;
}

interface HookDataToOutputReturn {
  suggestions: Suggestion[];
}

const DEFAULT_RETURN: HookDataToOutputReturn = {
  suggestions: [],
};

const hookDataToOutput = (
  data?: AutocompleteAircraftManufacturerQueryResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    suggestions: [],
  };

  if (data?.autocompleteAircraftManufacturer) {
    data.autocompleteAircraftManufacturer.forEach((result) => {
      toReturn.suggestions.push({
        label: result!,
        value: result!,
      });
    });
  }

  return toReturn;
};

const useAutocompleteAircraftManufacturer = generateQueryHook<
  AutocompleteAircraftManufacturerQueryResult,
  AutocompleteAircraftManufacturerQueryVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useAutocompleteAircraftManufacturerQuery, DEFAULT_RETURN);

export default useAutocompleteAircraftManufacturer;
