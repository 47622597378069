import { useCallback } from 'react';

import { Dropzone } from 'apps/shared/scripts/components';
import { useUploadImage } from 'apps/shared/scripts/hooks';

import { uploadDropzoneClassName } from './styles';

interface Props {
  onUploadPhoto: (file: File | null, url: string) => void;
}

const UploadDropzone = ({ onUploadPhoto }: Props) => {
  const { isUploading, onUpload, percentage } = useUploadImage();

  const handleUpload = useCallback(
    async (file: File) => {
      const { file: resizedFile, url, error } = await onUpload(file);
      if (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return;
      }
      onUploadPhoto(resizedFile, url);
    },
    [onUpload, onUploadPhoto]
  );

  return (
    <div className={uploadDropzoneClassName}>
      <Dropzone
        handleError={() => {}}
        handleUpload={handleUpload}
        isUploading={isUploading}
        percentage={percentage}
      />
    </div>
  );
};

UploadDropzone.displayName = 'UploadDropzone';

export default UploadDropzone;
