import { ResetPasswordInput } from 'apps/shared/scripts/__generated__/graphql.generated';

import { FormValues } from './ResetForm';

export const toInput = (data: FormValues, token: string): ResetPasswordInput => {
  const { password } = data;
  const input: Mutable<ResetPasswordInput> = {
    password: '',
    token,
  };

  if (password) {
    input.password = password;
  }

  if (token) {
    input.token = token;
  }

  return input;
};
