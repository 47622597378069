import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useUser } from 'apps/shared/scripts/graphql';

import BackButton from './BackButton';
import {
  AlreadyVerified,
  ResendVerification,
  VerificationExpired,
  VerificationFailed,
  VerificationSuccess,
  VerifyEmail,
} from './layouts';

const VerifyContainer = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('c');
  const referrer = searchParams.get('r');

  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<boolean>(false);

  const { isLoading, user } = useUser();

  if (isLoading) {
    return null;
  }

  if (user!.isVerified) {
    return <AlreadyVerified />;
  }

  if (error) {
    if (error.includes('Verification code has expired')) {
      return <VerificationExpired />;
    }
    return <VerificationFailed />;
  }

  if (success) {
    return <VerificationSuccess />;
  }

  if (code) {
    return (
      <VerifyEmail
        code={code}
        onError={(m) => setError(m)}
        onSuccess={() => setSuccess(true)}
      />
    );
  }

  return (
    <>
      {referrer && <BackButton />}
      <ResendVerification />
    </>
  );
};

VerifyContainer.displayName = 'VerifyContainer';

export default VerifyContainer;
