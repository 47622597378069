import { Image } from 'apps/shared/scripts/components';

import { imageClassName } from './styles';

interface Props {
  imageUrl?: string | null;
}

const ProfilePhoto = ({ imageUrl }: Props) => {
  const image = imageUrl
    ? imageUrl
    : 'https://res.cloudinary.com/dutl6xlom/image/upload/v1698626088/5976702cffb33336cf827d95_gzvtxl.jpg';

  return <Image className={imageClassName} src={image} scaled />;
};

ProfilePhoto.displayName = 'ProfilePhoto';

export default ProfilePhoto;
