import { css } from '@emotion/css';

import {
  Colors,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const sectionClassName = css({
  borderTop: '1px solid #ddd',
  paddingTop: Spacing.XL,
  '&:first-of-type': {
    borderTop: 'none',
    paddingTop: 0,
  },
});

export const headerClassName = css({
  marginBottom: Spacing.LG,
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: 22,
  fontWeight: FontWeight.SEMI_BOLD,
});

export const bodyClassName = css({});
