import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../Button';

import { signupButtonClassName } from './styles';

const SignupButton = () => {
  const navigate = useNavigate();

  const onLogin = useCallback(() => {
    navigate('/signup');
  }, [navigate]);

  return (
    <Button className={signupButtonClassName} onClick={onLogin}>
      Sign Up
    </Button>
  );
};

SignupButton.displayName = 'SignupButton';

export default SignupButton;
