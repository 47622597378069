import { cx } from '@emotion/css';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonTypes, Image } from 'apps/shared/scripts/components';
import { useUser } from 'apps/shared/scripts/graphql';
import { useIsAuthenticated } from 'apps/shared/scripts/hooks';

import {
  buttonClassName,
  formGroupClassName,
  headerClassName,
  headingClassName,
  iconClassName,
  inputClassName,
  landingHeroClassName,
  mediaClassName,
  prefixClassName,
  secondaryIconClassName,
  subheadingClassName,
} from './styles';

const LandingHero = () => {
  const [copied, setCopied] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const { user } = useUser({
    skip: !isAuthenticated,
  });

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  }, []);

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (!!username) {
        navigate(`/signup?u=${username}`);
      }
    },
    [navigate, username]
  );

  const onCopy = useCallback(() => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, []);

  return (
    <div className={landingHeroClassName}>
      <Container>
        <Row>
          <Col xs={12} lg={5}>
            <div className={headerClassName}>
              <h2 className={headingClassName}>
                All your aviation photos. In one simple link.
              </h2>
              <h4 className={subheadingClassName}>
                Instantly upload, tag, and share your best shots with the world.
                {!isAuthenticated && '\nClaim your profile today.'}
              </h4>
              {!isAuthenticated ? (
                <form onSubmit={onSubmit}>
                  <div className={formGroupClassName}>
                    <span className={prefixClassName}>flytepost.com/</span>
                    <input
                      className={inputClassName}
                      onChange={onInputChange}
                      value={username}
                    />
                    <Button
                      className={buttonClassName}
                      iconClassName={iconClassName}
                      faIcon={faArrowRight}
                      type={ButtonTypes.Submit}
                    />
                  </div>
                </form>
              ) : (
                <div className={formGroupClassName}>
                  <span className={prefixClassName}>
                    flytepost.com/{user?.username}
                  </span>
                  <CopyToClipboard
                    text={`flytepost.com/${user?.username}`}
                    onCopy={onCopy}
                  >
                    <Button
                      className={buttonClassName}
                      iconClassName={cx(iconClassName, secondaryIconClassName)}
                      faIcon={copied ? faCheck : faCopy}
                      type={ButtonTypes.Button}
                    />
                  </CopyToClipboard>
                </div>
              )}
            </div>
          </Col>
          <Col xs={12} lg={7}>
            <div className={mediaClassName}>
              <Image
                src={
                  'https://res.cloudinary.com/dutl6xlom/image/upload/v1701247043/mj3ytywku5ghwzyh5sxd.jpg'
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

LandingHero.displayName = 'LandingHero';

export default LandingHero;
