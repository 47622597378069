import {
  AircraftInput,
  AirlineInput,
  AirportInput,
  MetadataInput,
  Post,
  PostInput,
} from 'apps/shared/scripts/__generated__/graphql.generated';

import { DEFAULT_VALUES } from './constants';
import { FormValues } from './types';

export const toInput = (data: FormValues): PostInput => {
  const metadata: Mutable<MetadataInput> = {};

  if (data.aircraft || data.type || data.variant) {
    const aircraft: Mutable<AircraftInput> = {};
    if (data.aircraft) {
      aircraft.manufacturer = data.aircraft;
    }
    if (data.type) {
      aircraft.type = data.type;
    }
    if (data.variant) {
      aircraft.variant = data.variant;
    }
    metadata.aircraft = aircraft;
  }

  if (data.airline) {
    const airline: Mutable<AirlineInput> = {};
    airline.name = data.airline;
    metadata.airline = airline;
  }

  if (data.airport) {
    const airport: Mutable<AirportInput> = {};
    airport.name = data.airport;
    metadata.airport = airport;
  }

  return { metadata };
};

export const toFormValues = (post: Post): FormValues => {
  const { imageUrl, metadata } = post;

  const formValues: FormValues = { ...DEFAULT_VALUES };

  if (imageUrl) {
    formValues.photo = imageUrl;
  }

  if (metadata.aircraft) {
    if (metadata.aircraft.manufacturer) {
      formValues.aircraft = metadata.aircraft.manufacturer;
    }
    if (metadata.aircraft.type) {
      formValues.type = metadata.aircraft.type;
    }
    if (metadata.aircraft.variant) {
      formValues.variant = metadata.aircraft.variant;
    }
  }

  if (metadata.airline) {
    if (metadata.airline.name) {
      formValues.airline = metadata.airline.name;
    }
  }

  if (metadata.airport) {
    if (metadata.airport.name) {
      formValues.airport = metadata.airport.name;
    }
  }

  return formValues;
};
