import { cx } from '@emotion/css';
import { ReactNode } from 'react';

import Spinner from '../Spinner';

import {
  disabledClassName,
  itemClassName,
  loadingClassName,
} from './styles';

interface Props {
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const Item = ({ children, disabled, loading, onClick }: Props) => {
  const disableOnClick = disabled || loading;
  return (
    <div
      className={cx(itemClassName, {
        [disabledClassName]: disabled,
        [loadingClassName]: loading,
      })}
      onClick={disableOnClick ? undefined : onClick}
      role="button"
    >
      {loading ? <Spinner /> : children}
    </div>
  );
};

Item.displayName = 'Item';

export default Item;
