import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const collapsibleClassName = css({
  borderTop: '1px solid #ddd',
  paddingTop: Spacing.LG,
  paddingBottom: Spacing.LG,
  '&:first-of-type': {
    borderTop: 'none',
    paddingTop: 0,
  },
});

export const headClassName = css({
  marginBottom: Spacing.SM,
});

export const headerClassName = css({
  alignItems: 'center',
  display: 'flex',
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  lineHeight: 1.6,
});

export const rightHeaderClassName = css({
  marginLeft: 'auto',
});

export const subheaderClassName = css({
  marginTop: Spacing.XS,
});

export const descriptionClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.NORMAL,
});

export const buttonSecondaryClassName = css({
  color: Colors.Brand,
  fontWeight: FontWeight.MEDIUM,
  padding: 0,
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const bodyClassName = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
});

export const disabledSecondaryButtonClassName = css({
  color: 'rgba(38, 167, 222, 0.5)',
  '&:hover': {
    textDecoration: 'none',
  },
});

export const valueClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
});

export const footerClassName = css({
  position: 'relative',
});

export const buttonPrimaryClassName = css({
  background: Colors.Brand,
  color: Colors.White,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.SEMI_BOLD,
  marginTop: 20,
  padding: `${Spacing.SM}px ${Spacing.LG}px`,
  '&:hover': {
    opacity: 0.9,
  },
});

export const disabledPrimaryButtonClassName = css({
  background: '#F0F0F0',
  color: '#BDBDBD',
});

export const dangerClassName = css({
  color: Colors.Red,
});

export const errorClassName = css({
  marginTop: Spacing.LG,
});
