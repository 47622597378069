import {
  ApolloProvider,
} from '@apollo/client';
import { ReactNode, useEffect, useState } from 'react';

import { Firebase } from 'apps/shared/scripts/services';

import { graphqlClient } from '../../graphql';

interface Props {
  children: ReactNode;
}

const Initializer = ({ children }: Props) => {
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    Firebase.config.auth.onAuthStateChanged(() => {
      setInitialized(true);
    });
  }, []);

  if (!isInitialized) return null;

  return <ApolloProvider client={graphqlClient}>{children}</ApolloProvider>;
};

Initializer.displayName = 'Initializer';

export default Initializer;
