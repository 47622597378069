import { cx } from '@emotion/css';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import Icon from '../Icon';

import {
  iconClassName as defaultIconClassName,
  spinnerClassName,
} from './styles';

interface Props {
  className?: string;
  iconClassName?: string;
}

const Spinner = ({ className = '', iconClassName = '' }: Props) => {
  return (
    <div className={cx(spinnerClassName, { [className]: !!className })}>
      <Icon
        className={cx(defaultIconClassName, {
          [iconClassName]: !!iconClassName,
        })}
        fa={faCircleNotch}
      />
    </div>
  );
};

Spinner.displayName = 'Spinner';

export default Spinner;
