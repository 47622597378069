import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const ctaClassName = css({
  color: Colors.Primary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.BOLD,
  marginLeft: Spacing.XS,
  '&:hover': {
    textDecoration: 'underline',
  }
});

export const footerClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.BOLD,
  marginTop: Spacing['2XL'],
  textDecoration: 'underline',
});
