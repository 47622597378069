import { Container } from 'react-bootstrap';

import { TopNav } from 'apps/shared/scripts/components';
import { Header, Page, Section } from 'apps/shared/scripts/components/Dashboard';
import { UploadModalContainer } from 'apps/upload/scripts/containers';

import AccountFormContainer from '../AccountFormContainer';
import PasswordFormContainer from '../PasswordFormContainer';

const SettingsContainer = () => {
  return (
    <>
      <TopNav />
      <Container>
        <Header />
        <Page split>
          <Section title={'Account'}>
            <AccountFormContainer />
          </Section>
          <Section title={'Security'}>
            <PasswordFormContainer />
          </Section>
        </Page>
        <UploadModalContainer />
      </Container>
    </>
  );
};

SettingsContainer.displayName = 'SettingsContainer';

export default SettingsContainer;
