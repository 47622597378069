import cx from 'classnames';
import { forwardRef, ForwardRefRenderFunction } from 'react';

import control from '../control';

import { errorClassName, inputClassName } from './styles';
import { InputProps } from './types';

const Input: ForwardRefRenderFunction<any, InputProps> = (
  {
    autoComplete = 'off',
    className = '',
    hasError,
    maxLength,
    name,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    inputRef,
    placeholder,
    spellCheck = false,
    tabIndex,
    type,
    value,
  },
  ref
) => {
  return (
    <input
      autoComplete={autoComplete}
      className={cx(inputClassName, {
        [className]: !!className,
        [errorClassName]: hasError,
      })}
      maxLength={maxLength}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      ref={inputRef ?? ref}
      spellCheck={spellCheck}
      tabIndex={tabIndex}
      type={type}
      value={value}
    />
  );
};

Input.displayName = 'Input';

export default control(forwardRef<any, InputProps>(Input));
