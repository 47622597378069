import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const redirect = useCallback(
    (path: string) => {
      if (location.pathname !== path) {
        navigate(path);
      }
    },
    [location, navigate]
  );

  return redirect;
};

export default useRedirect;
