import { dividerClassName, labelClassName } from './styles';

const Divider = () => {
  return (
    <div className={dividerClassName}>
      <span className={labelClassName}>or with email</span>
    </div>
  );
};

Divider.displayName = 'Divider';

export default Divider;
