import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateProfilePhotoMutationVariables = Types.Exact<{
  input: Types.ImageUploadInput;
}>;


export type UpdateProfilePhotoMutationResult = { readonly __typename: 'Mutation', readonly updateProfilePhoto?: { readonly __typename: 'EmptyResponse', readonly _empty?: boolean | null } | { readonly __typename: 'ImageUpload', readonly publicUrl?: string | null, readonly signedUrl: string } | null };


export const UpdateProfilePhotoDocument = gql`
    mutation UpdateProfilePhoto($input: ImageUploadInput!) {
  updateProfilePhoto(input: $input) {
    ... on EmptyResponse {
      _empty
    }
    ... on ImageUpload {
      publicUrl
      signedUrl
    }
  }
}
    `;
export type UpdateProfilePhotoMutationFn = Apollo.MutationFunction<UpdateProfilePhotoMutationResult, UpdateProfilePhotoMutationVariables>;

/**
 * __useUpdateProfilePhotoMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePhotoMutation, { data, loading, error }] = useUpdateProfilePhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfilePhotoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfilePhotoMutationResult, UpdateProfilePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfilePhotoMutationResult, UpdateProfilePhotoMutationVariables>(UpdateProfilePhotoDocument, options);
      }
export type UpdateProfilePhotoMutationHookResult = ReturnType<typeof useUpdateProfilePhotoMutation>;
export type UpdateProfilePhotoMutationOptions = Apollo.BaseMutationOptions<UpdateProfilePhotoMutationResult, UpdateProfilePhotoMutationVariables>;