import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
export type UserFragment = { readonly __typename: 'User', readonly email: string, readonly isVerified: boolean, readonly name: string, readonly profileImageUrl?: string | null, readonly username: string };

export const UserFragmentDoc = gql`
    fragment UserFragment on User {
  email
  isVerified
  name
  profileImageUrl
  username
}
    `;