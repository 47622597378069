import { useState } from 'react';
import { Container } from 'react-bootstrap';

import { Footer, Markdown, TopNav } from 'apps/shared/scripts/components';

import Terms from './Terms.md';

const TermsContainer = () => {
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <>
      <TopNav />
      <Container>
        <Markdown onLoad={() => setLoaded(true)} file={Terms} />
      </Container>
      {/* Flash of Unstyled Text */}
      {loaded && <Footer />}
    </>
  );
};

TermsContainer.displayName = 'TermsContainer';

export default TermsContainer;
