import axios from 'axios';
import { useCallback, useState } from 'react';

const usePutSignedUrl = () => {
  const [isUploading, setUploading] = useState<boolean>(false);
  // @todo(jgan): load size limit from server
  const maxSize = Math.floor(1 * 1024 * 1024); // 1MB

  const putSignedUrl = useCallback(async (url: string, file: File) => {
    setUploading(true);
    const fileExtension = file.type.split('/').pop()!;
    await axios.put(url, file, {
      headers: {
        'Content-Type': `image/${fileExtension}`,
        'x-goog-acl': 'public-read',
        'x-goog-content-length-range': `0,${maxSize}`,
      },
    });
    setUploading(false);
  }, [maxSize]);

  return { isUploading, putSignedUrl };
};

export default usePutSignedUrl;
