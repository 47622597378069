import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useIsAuthenticated } from '../../hooks';

interface Props {
  children?: ReactElement;
}

const RequireAuth = ({ children }: Props): ReactElement => {
  const isAuthenticated = useIsAuthenticated();
  if (!isAuthenticated) {
    return (
      <Navigate
        to={`/login?r=${encodeURIComponent(window.location.href)}`}
        replace
      />
    );
  }
  return children ? children : <Outlet />;
};

export default RequireAuth;
