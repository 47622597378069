import { gql } from '@apollo/client';

import { Post } from 'apps/shared/scripts/__generated__/graphql.generated';

import { PostFragmentDoc } from '../../fragments';
import { generateQueryHook } from '../../utils';

import {
  PostQueryResult,
  PostQueryVariables,
  usePostQuery,
} from './usePostById.generated';

gql`
  query PostQuery($id: ID!) {
    post(id: $id) {
      ...PostFragment
    }
  }
  ${PostFragmentDoc}
`;

interface HookDataToOutputReturn {
  post?: Post;
}

const DEFAULT_RETURN: HookDataToOutputReturn = {
  post: undefined,
};

const hookDataToOutput = (
  data?: PostQueryResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    post: undefined,
  };

  if (data?.post) {
    toReturn.post = data.post;
  }

  return toReturn;
};

const usePostById = generateQueryHook<
  PostQueryResult,
  PostQueryVariables,
  HookDataToOutputReturn
>(hookDataToOutput, usePostQuery, DEFAULT_RETURN);

export default usePostById;
