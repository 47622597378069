import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'apps/shared/scripts/components';

import { backButtonClassName, buttonClassName, iconClassName } from './styles';

const BackButton = () => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className={backButtonClassName}>
      <Button
        className={buttonClassName}
        faIcon={faArrowLeft}
        iconClassName={iconClassName}
        onClick={onClick}
      />
    </div>
  );
};

BackButton.displayName = 'BackButton';

export default BackButton;
