import { cx } from '@emotion/css';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import Icon from '../../Icon';

import {
  dangerTextStyle,
  descriptionClassName,
  dropzoneActiveStyle,
  dropzoneClassName,
  dropzoneDangerStyle,
  headingClassName,
  iconClassName,
  innerClassName,
  subheadingClassName,
} from './styles';
import { DropzoneProps } from './types';

export default function Dropzone({
  className = '',
  handleError,
  handleUpload,
  headingClassName: customHeadingClassName = '',
  isUploading,
  percentage,
}: DropzoneProps) {
  const onDrop = useCallback(
    async (files: File[], errors: FileRejection[]) => {
      if (errors.length > 0) {
        return handleError(errors[0].errors[0].message);
      }
      const file = files[0];
      handleUpload(file);
    },
    [handleError, handleUpload]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        'image/jpeg': [],
      },
      maxFiles: 1,
    });

  return (
    <div
      {...getRootProps({
        className: cx(dropzoneClassName, {
          [dropzoneActiveStyle]: isDragActive,
          [dropzoneDangerStyle]: isDragReject,
          [className]: !!className,
        }),
      })}
    >
      <div className={innerClassName}>
        <input {...getInputProps()} />
        <div className={descriptionClassName}>
          <Icon className={iconClassName} fa={faImage} />
          <span
            className={cx(headingClassName, {
              [customHeadingClassName]: !!customHeadingClassName,
              [dangerTextStyle]: isDragReject,
            })}
          >
            {isUploading
              ? 'Uploading'
              : isDragReject
              ? 'Invalid file selection'
              : 'Click or Drag Photo Here'}
          </span>
          <span
            className={cx(subheadingClassName, {
              [dangerTextStyle]: isDragReject,
            })}
          >
            {isUploading ? `${percentage}%` : 'File should be .jpg'}
          </span>
        </div>
      </div>
    </div>
  );
}
