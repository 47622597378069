import { css } from '@emotion/css';

export const imageClassName = css({
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  userSelect: 'none',
  width: '100%',
});

export const innerClassName = css({
  height: '100%',
  width: '100%',
});

export const pictureClassName = css({
  display: 'flex',
  width: '100%',
});

export const imgClassName = css({
  width: '100%',
});

export const scaledClassName = css({
  '.image-inner': {
    paddingBottom: '66.6667%',

    '.image-picture': {
      height: '100%',

      '.image-img': {
        height: '100%',
        objectFit: 'cover',
        width: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
  },
});
