import { ErrorMessage } from '@hookform/error-message';
import cx from 'classnames';
import { cloneElement, FC, isValidElement } from 'react';

import Error from '../../Error';

import {
  bodyClassName,
  errorColorClassName,
  groupClassName,
  headClassName,
  headerClassName,
  headingClassName,
  spacingClassName,
  subheaderClassName,
  subheadingClassName,
} from './styles';
import { GroupProps } from './types';

const Group: FC<GroupProps> = ({
  children,
  className = '',
  description,
  errorClassName = '',
  errors = {},
  label,
  labelClassName = '',
  spacing,
  validate,
}) => {
  const hasError = validate ? validate in errors : false;
  return (
    <div
      className={cx(groupClassName, {
        [spacingClassName]: !!spacing,
        [className]: !!className,
      })}
    >
      {label && (
        <div className={headClassName}>
          <div className={headerClassName}>
            <span
              className={cx(headingClassName, {
                [labelClassName]: labelClassName,
                [errorColorClassName]: hasError,
              })}
            >
              {label}
            </span>
          </div>
          <div className={subheaderClassName}>
            {description && (
              <span className={subheadingClassName}>{description}</span>
            )}
          </div>
        </div>
      )}
      <div className={bodyClassName}>
        {isValidElement(children) && hasError
          ? cloneElement(children, { hasError } as any)
          : children}
      </div>
      {errors && validate && (
        <ErrorMessage
          className={errorClassName}
          errors={errors}
          name={validate}
          as={<Error />}
        />
      )}
    </div>
  );
};

Group.displayName = 'Group';

export default Group;
