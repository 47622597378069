import { RefObject, useEffect } from 'react';

const useOutsideClick = (
  sourceRef: RefObject<HTMLDivElement | HTMLButtonElement>,
  targetRef: RefObject<HTMLDivElement>,
  callback: (...args: unknown[]) => unknown
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        sourceRef &&
        sourceRef.current &&
        sourceRef.current.contains(event.target as Node)
      )
        return;
      if (
        targetRef &&
        targetRef.current &&
        targetRef.current.contains(event.target as Node)
      )
        return;
      return callback();
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, sourceRef, targetRef]);
};

export default useOutsideClick;
