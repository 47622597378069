import { css } from '@emotion/css';

import {
  Breakpoints,
  Colors,
  FontWeight,
  LineHeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const photoRowClassName = css({
  padding: `${Spacing.XL}px 0`,
  [`@media(min-width: ${Breakpoints.MD}px)`]: {
    margin: 'auto',
  },
  [`@media(min-width: ${Breakpoints.LG}px)`]: {
    margin: 'auto',
    maxWidth: '82%',
  },
});

export const textClassName = css({
  marginTop: Spacing['3XL'],
  [`@media(min-width: ${Breakpoints.MD}px)`]: {
    marginTop: 0,
  },
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: 36,
  fontWeight: FontWeight.BOLD,
  lineHeight: LineHeight.REGULAR,
});

export const descriptionClassName = css({
  color: Colors.Secondary,
  fontSize: 18,
  fontWeight: FontWeight.NORMAL,
  lineHeight: LineHeight.EXTRA_LARGE,
  marginTop: Spacing.LG,
  [`@media(min-width: ${Breakpoints.LG}px)`]: {
    fontSize: 19,
  },
});

export const mediaClassName = css({});

export const imageClassName = css({
  border: '1px solid #ddd',
  borderRadius: 12,
});

export const leftClassName = css({
  [`@media(min-width: ${Breakpoints.MD}px)`]: {
    alignItems: 'center',
  },
  [`@media(min-width: ${Breakpoints.LG}px)`]: {
    margin: 'auto',
    maxWidth: '80%',
  },
});

export const rightClassName = css({
  [`@media(min-width: ${Breakpoints.MD}px)`]: {
    alignItems: 'center',
    marginLeft: 32,
  },
  [`@media(min-width: ${Breakpoints.LG}px)`]: {
    marginLeft: 36,
    maxWidth: '80%',
  },
});
