import { css } from '@emotion/css';

export const iconClassName = css({
  display: 'flex',
  fontStyle: 'normal',
  'svg': {
    fill: 'inherit',
    height: 'auto',
    width: 'auto',
    transition: '0.2s',
  }
});
