import { css } from '@emotion/css';

import { Colors, FontSize, FontWeight, Spacing } from 'apps/shared/scripts/styles/constants';

export const dividerClassName = css({
  alignItems: 'center',
  display: 'flex',
  flexGrow: 0,
  webkitBoxFlex: 0,
  webkitBoxAlign: 'center',
  marginBottom: Spacing.SM,
  marginTop: Spacing.MD,
  '&::before, &::after': {
    backgroundColor: '#DDD',
    content: '""',
    display: 'block',
    flexGrow: 1,
    webkitBoxFlex: 1,
    height: 1,
  },
  '&::before': {
    marginRight: Spacing.SM,
  },
  '&::after': {
    marginLeft: Spacing.SM,
  },
});

export const labelClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.MEDIUM,
});
