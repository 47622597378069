import * as yup from 'yup';

import { FORM_FIELDS } from './constants';

export const schema = yup.object().shape({
  [FORM_FIELDS.EMAIL.name]: yup
  .string()
  .email('Email is invalid')
  .required('Email is required'),
});
