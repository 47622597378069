import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserProfileQueryVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;


export type UserProfileQueryResult = { readonly __typename: 'Query', readonly userProfile: { readonly __typename: 'Profile', readonly name: string, readonly profileImageUrl?: string | null, readonly username: string } };


export const UserProfileQueryDocument = gql`
    query UserProfileQuery($username: String!) {
  userProfile(username: $username) {
    name
    profileImageUrl
    username
  }
}
    `;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions: Apollo.QueryHookOptions<UserProfileQueryResult, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQueryResult, UserProfileQueryVariables>(UserProfileQueryDocument, options);
      }
export function useUserProfileQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQueryResult, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQueryResult, UserProfileQueryVariables>(UserProfileQueryDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileQueryLazyQueryHookResult = ReturnType<typeof useUserProfileQueryLazyQuery>;