import { css } from '@emotion/css';

import { Spacing } from 'apps/shared/scripts/styles/constants';

export const questionsClassName = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 650,
  marginBottom: Spacing['2XL'],
  marginLeft: 'auto',
  marginRight: 'auto',
});
