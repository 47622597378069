import { FirebaseError } from 'firebase/app';
import {
  EmailAuthProvider,
  getAuth,
  getIdToken,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
} from 'firebase/auth';

/**
 * Authenticate user with email/password credentials
 */
export const login = (email: string, password: string) =>
  signInWithEmailAndPassword(getAuth(), email, password);

/**
 * Authenticate user with Google OAuth
 */
export const googleOAuth = async () => {
  try {
    const { user } = await signInWithPopup(getAuth(), new GoogleAuthProvider());
    const idToken = await user.getIdToken();
    const { email } = user;
    return { email, idToken };
  } catch (e) {
    if (e instanceof FirebaseError) {
      const errorCode = e.code;
      const errorMessage = e.message;
      // https://stackoverflow.com/questions/62116406/firebase-popup-signin-popup-closed-by-user-error
      if (
        errorCode !== 'auth/cancelled-popup-request' &&
        errorCode !== 'auth/popup-closed-by-user'
      ) {
        throw new Error(errorMessage);
      }
    } else {
      throw new Error('Unable to authenticate user with Google OAuth');
    }
    return {};
  }
};

/**
 * Logout user
 */
export const logout = () => signOut(getAuth());

/**
 * Get refreshed ID token
 */
export const getToken = () => getIdToken(getAuth().currentUser!);

/**
 * Authenticate user with Custom token
 */
export const authenticateToken = (token: string) =>
  signInWithCustomToken(getAuth(), token);

/**
 * Change user password
 */
export const changePassword = (
  currentPassword: string,
  newPassword: string
) => {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      return reject(new Error('User could not be found'));
    }
    const credential = EmailAuthProvider.credential(
      user.email!,
      currentPassword
    );
    reauthenticateWithCredential(user, credential)
      .then(() => {
        // User re-authenticated.
        updatePassword(user, newPassword)
          .then(() => {
            // Update successful.
            resolve(null);
          })
          .catch((error) => {
            // An error ocurred
            reject(error);
          });
      })
      .catch((error) => {
        // An error ocurred
        reject(error);
      });
  });
};
