import { ReactNode } from 'react';

import { bodyClassName, headerClassName, headingClassName, sectionClassName } from './styles';

interface Props {
  children: ReactNode;
  title: string;
}

export const Section = ({ children, title }: Props) => {
  return (
    <div className={sectionClassName}>
      <div className={headerClassName}>
        <h4 className={headingClassName}>{title}</h4>
      </div>
      <div className={bodyClassName}>
        {children}
      </div>
    </div>
  );
};

Section.displayName = 'Section';

export default Section;
