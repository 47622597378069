import { cx } from '@emotion/css';
import { animated, config, useSpring } from '@react-spring/web';
import { useDrag, useScroll } from '@use-gesture/react';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { useDispatch } from 'react-redux';

import { useInsideClick } from '../../hooks';
import { closeModal } from '../../redux';

import { disable, enable } from './Inobounce';
import Overlay from './Overlay';
import {
  containerClassName,
  containerScrollableStyle,
  modalClassName,
  modalScrollableStyle,
} from './styles';
import { getHeight } from './utils';

export interface Props {
  childRef?: any;
  children: ReactNode;
  className?: string;
  draggable?: boolean;
  isScrollable?: boolean;
  isVisible: boolean;
}

const Modal = ({
  childRef,
  children,
  className = '',
  draggable,
  isScrollable,
  isVisible,
}: Props) => {
  const [isV, setV] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const { isPortrait, isLandscape } = useMobileOrientation();

  const dragEnabled = draggable && isMobile && isPortrait;

  const [{ y, opacity }, api] = useSpring(() => ({
    y: getHeight(),
    opacity: 0,
  }));

  const open = useCallback(() => {
    if (dragEnabled) {
      enable();
    }
    api.start({
      y: 0,
      immediate: false,
      config: isMobile
        ? {
            clamp: true,
            duration: 250,
            friction: 20,
            mass: 1,
            tension: 210,
          }
        : { ...config.default, clamp: false },
      opacity: 1,
      onStart: {
        y: () => setV(true),
      },
    });
  }, [api, dragEnabled]);

  const close = useCallback(
    (velocity: number = 0) => {
      disable();
      api.start({
        y: getHeight(),
        immediate: false,
        config: isMobile
          ? velocity < 0
            ? {
                clamp: true,
                duration: 250,
                friction: 20,
                mass: 1,
                tension: 210,
              }
            : { ...config.stiff, velocity }
          : config.slow,
        opacity: 0,
        onRest: {
          y: () => setV(false),
        },
      });
    },
    [api, setV]
  );

  const display = y.to((py) => (isV && py < getHeight() ? 'flex' : 'none'));

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
      open();
    } else {
      document.body.style.removeProperty('overflow');
      close();
    }
  }, [close, isVisible, open]);

  useInsideClick(containerRef, () => dispatch(closeModal()));

  useEffect(() => {
    if (isLandscape) {
      disable();
    }
  }, [isLandscape]);

  /**
   * Begin React useGesture
   */
  const BOUNCE_THRESHOLD = 0.25;

  const bindDrag = useDrag(
    ({ last, movement: [, my], cancel }) => {
      // Don't move if modal is scrolled
      if (childRef.current.scrollTop > 0) {
        return cancel();
      }
      // Prevent full modal from moving up at all
      if (my < 0) {
        return cancel();
      }
      if (last) {
        if (my > getHeight() * BOUNCE_THRESHOLD) {
          dispatch(closeModal());
        } else {
          document.body.style.overflow = 'hidden';
          open();
        }
      } else {
        api.start({ y: my, immediate: true });
      }
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  const bindScroll = useScroll(
    () => {
      childRef.current.scrollTop === 0 ? enable() : disable();
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );
  // ^^^ End React useGesture ^^^

  return (
    <>
      <animated.div
        className={cx(modalClassName, {
          [modalScrollableStyle]: isScrollable,
          [className]: !!className,
        })}
        style={{
          display,
          bottom: `calc(-100vh + ${getHeight() - 100}px)`,
          y,
          opacity,
        }}
        {...(dragEnabled ? bindDrag() : {})}
        {...(dragEnabled ? bindScroll() : {})}
      >
        {isV && (
          <div
            className={cx(containerClassName, {
              [containerScrollableStyle]: isScrollable,
            })}
            ref={containerRef}
          >
            {children}
          </div>
        )}
      </animated.div>
      <Overlay isVisible={isVisible} />
    </>
  );
};

Modal.displayName = 'Modal';

export default Modal;
