import { FC, forwardRef } from 'react';
import { Control as RHFControl, Controller } from 'react-hook-form';

export type Control = {
  control: RHFControl;
};

const control = (Component: FC<any>) => {
  const controlledComponent = (
    // @ts-ignore
    { control, defaultValue, name, ...props },
    // @ts-ignore
    ref
  ) => {
    if (!control)
      return (
        <Component
          defaultValue={defaultValue}
          name={name}
          {...props}
          ref={ref}
        />
      );
    return (
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field: { onBlur, onChange, ref: inputRef, value } }) => {
          return (
            <Component
              {...props}
              controlObj={control}
              name={name}
              onBlur={props.onBlur ? () => props.onBlur() && onBlur() : onBlur}
              onChange={
                props.onChange
                  ? (e: any) => {
                      onChange(e);
                      props.onChange(e);
                    }
                  : onChange
              }
              value={value}
              inputRef={inputRef}
              ref={ref}
            />
          );
        }}
      />
    );
  };

  return forwardRef(controlledComponent);
};

export default control;
