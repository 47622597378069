import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { TopNav } from 'apps/shared/scripts/components';
import { useCreateOAuthUser, useCreateUser } from 'apps/shared/scripts/graphql';
import { useFormAsync } from 'apps/shared/scripts/hooks';

import { useLoginUser } from '../../hooks';

import SignupForm, { FormValues } from './SignupForm';
import { toInput, toOAuthInput } from './utils';

const SignupContainer = () => {
  const { mutate: createUser } = useCreateUser();
  const { mutate: createOAuthUser } = useCreateOAuthUser();
  const { login } = useLoginUser();

  const { isLoading, onStart, onFail, onSuccess } = useFormAsync();

  const navigate = useNavigate();

  const handleSignup = useCallback(
    async (data: FormValues) => {
      onStart();
      const input = toInput(data);
      const { result, errors } = await createUser({ input });
      if (!result || errors.length > 0) {
        onFail();
        throw new Error(errors[0].message);
      }
      await login(data.email, data.password);
      onSuccess();
      navigate(`/${data.username}`);
    },
    [createUser, login, navigate, onFail, onStart, onSuccess]
  );

  const handleOAuthSignup = useCallback(
    async (idToken: string, username: string) => {
      onStart();
      const input = toOAuthInput(idToken, username);
      const { result, errors } = await createOAuthUser({ input });
      if (!result || errors.length > 0) {
        onFail();
        throw new Error(errors[0].message);
      }
      onSuccess();
      navigate(`/${username}`);
    },
    [createOAuthUser, navigate, onFail, onStart, onSuccess]
  );

  return (
    <>
      <TopNav />
      <SignupForm
        handleOAuthSignup={handleOAuthSignup}
        handleSignup={handleSignup}
        isLoading={isLoading}
      />
    </>
  );
};

SignupContainer.displayName = 'SignupContainer';

export default SignupContainer;
