import { cx } from '@emotion/css';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'apps/shared/scripts/components';
import { updateModal } from 'apps/shared/scripts/redux';

import {
  buttonClassName,
  controlNextClassName,
  controlPrevClassName,
  disabledClassName,
} from './styles';

interface Props {
  id: string | null;
  isDisabled?: boolean;
  isNext?: boolean;
}

const Control = ({ id, isDisabled, isNext }: Props) => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(updateModal({ id }));
  }, [dispatch, id]);

  return (
    <div className={isNext ? controlNextClassName : controlPrevClassName}>
      <Button
        className={cx(buttonClassName, {
          [disabledClassName]: isDisabled || !id,
        })}
        disabled={isDisabled}
        faIcon={isNext ? faChevronRight : faChevronLeft}
        onClick={!!id ? onClick : undefined}
      />
    </div>
  );
};

Control.displayName = 'Control';

export default Control;
