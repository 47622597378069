import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useToast, useYupValidation } from 'apps/shared/scripts/hooks';

import {
  UploadContent,
  UploadDropzone,
  UploadFooter,
  UploadHead,
  UploadMetadata,
  UploadPreview,
} from '../../components';

import { DEFAULT_VALUES, FORM_FIELDS } from './constants';
import { FormValues } from './types';
import { uploadSchema } from './validation';

export interface Props {
  defaultValues?: FormValues;
  handleSave: (data: FormValues, file: File) => Promise<void>;
  isEditMode?: boolean;
  isSaving?: boolean;
}

const UploadForm = ({
  defaultValues = DEFAULT_VALUES,
  handleSave,
  isEditMode,
  isSaving,
}: Props) => {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    setValue,
  } = useForm<FormValues>({
    defaultValues,
    resolver: useYupValidation<FormValues>(uploadSchema),
  });
  const [upload, setUpload] = useState<File | null>(
    defaultValues.photo ? new File([], '') : null
  );

  const toast = useToast();

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data) => {
      // filter empty values (e.g. { key1: '', key2: 'defined' } => { key2: 'defined' } )
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v !== '')
      ) as FormValues;
      try {
        await handleSave(filteredData, upload!);
        toast.success(
          `Success! Post was ${isEditMode ? 'updated' : 'created'}`
        );
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }
    },
    [handleSave, isEditMode, toast, upload]
  );

  const onCancel = useCallback(() => {
    reset();
    setUpload(null);
  }, [reset]);

  const onUploadPhoto = useCallback(
    (photo: File | null, url: string) => {
      setUpload(photo);
      setValue(FORM_FIELDS.PHOTO.name, url, { shouldDirty: true });
    },
    [setValue]
  );

  return (
    <>
      <UploadHead isEditMode={isEditMode} />
      <UploadContent>
        {!upload ? (
          <UploadDropzone onUploadPhoto={onUploadPhoto} />
        ) : (
          <>
            <UploadPreview url={getValues(FORM_FIELDS.PHOTO.name)} />
            <UploadMetadata control={control} errors={errors} />
          </>
        )}
      </UploadContent>
      {!!upload && (
        <UploadFooter
          isEditMode={isEditMode}
          isSubmitting={isSaving}
          onCancel={onCancel}
          onSubmit={handleSubmit(onSubmit)}
        />
      )}
    </>
  );
};

UploadForm.displayName = 'UploadForm';

export default UploadForm;
