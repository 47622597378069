import { gql } from '@apollo/client';

import { generateQueryHook } from '../../utils';

import {
  AutocompleteAircraftTypeQueryResult,
  AutocompleteAircraftTypeQueryVariables,
  useAutocompleteAircraftTypeQuery,
} from './useAutocompleteAircraftType.generated';

gql`
  query AutocompleteAircraftTypeQuery($manufacturer: String!, $type: String) {
    autocompleteAircraftType(manufacturer: $manufacturer, type: $type)
  }
`;

interface Suggestion {
  label: string;
  value: string;
}

interface HookDataToOutputReturn {
  suggestions: Suggestion[];
}

const DEFAULT_RETURN: HookDataToOutputReturn = {
  suggestions: [],
};

const hookDataToOutput = (
  data?: AutocompleteAircraftTypeQueryResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    suggestions: [],
  };

  if (data?.autocompleteAircraftType) {
    data.autocompleteAircraftType.forEach((result) => {
      toReturn.suggestions.push({
        label: result!,
        value: result!,
      });
    });
  }

  return toReturn;
};

const useAutocompleteAircraftType = generateQueryHook<
  AutocompleteAircraftTypeQueryResult,
  AutocompleteAircraftTypeQueryVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useAutocompleteAircraftTypeQuery, DEFAULT_RETURN);

export default useAutocompleteAircraftType;
