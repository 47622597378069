import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

import { IconSvg, LogoSvg } from 'apps/shared/scripts/assets';

import Icon from '../Icon';

import { leftContentClassName, logoClassName } from './styles';

const LeftContent = () => {
  return (
    <div className={leftContentClassName}>
      <Link to={'/'}>
        <Icon
          className={logoClassName}
          svg={isMobile ? <IconSvg /> : <LogoSvg />}
        />
      </Link>
    </div>
  );
};

LeftContent.displayName = 'LeftContent';

export default LeftContent;
