import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import {
  enterActiveClassName,
  enterClassName,
  exitActiveClassName,
  exitClassName,
  overlayClassName,
} from './styles';

interface Props {
  isVisible: boolean;
}

const Overlay = ({ isVisible }: Props) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      in={isVisible}
      classNames={{
        enter: enterClassName,
        exit: exitClassName,
        enterActive: enterActiveClassName,
        exitActive: exitActiveClassName,
      }}
      nodeRef={nodeRef}
      timeout={{ enter: 400, exit: 400 }}
      unmountOnExit
    >
      <div data-modal="true" className={overlayClassName} ref={nodeRef} />
    </CSSTransition>
  );
};

Overlay.displayName = 'Overlay';

export default Overlay;
