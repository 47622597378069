import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import {
  BackButton,
  Centered,
  Cta,
  Divider,
  Footnote,
  GoogleOauth,
  Label,
  Layout,
} from 'apps/auth/scripts/components';
import { Group, Input, PrefixInput } from 'apps/shared/scripts/components';
import { useToast, useYupValidation } from 'apps/shared/scripts/hooks';

import { FORM_FIELDS } from './constants';
import messages from './messages.json';
import { FormValues } from './types';
import { oAuthSignupSchema, preSignupSchema, signupSchema } from './validation';

interface Props {
  handleOAuthSignup: (idToken: string, username: string) => Promise<void>;
  handleSignup: (data: FormValues) => Promise<void>;
  isLoading: boolean;
}

const SignupForm = ({ handleOAuthSignup, handleSignup, isLoading }: Props) => {
  const [isUsernameStep, setUsernameStep] = useState<boolean>(false);
  const [oAuthIdToken, setOAuthIdToken] = useState<string | undefined>();
  const [searchParams] = useSearchParams();
  const defaultUsername = searchParams.get('u');
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      username: defaultUsername ?? '',
    },
    resolver: useYupValidation<Partial<FormValues>>(
      oAuthIdToken
        ? oAuthSignupSchema
        : isUsernameStep
        ? signupSchema
        : preSignupSchema
    ),
  });
  const toast = useToast();

  const onSignup: SubmitHandler<FormValues> = async (data) => {
    if (!isUsernameStep) {
      setUsernameStep(true);
      return;
    }
    try {
      if (oAuthIdToken) {
        await handleOAuthSignup(oAuthIdToken, data.username);
      } else {
        await handleSignup(data);
      }
      toast.success('Successfully created account');
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  };

  const onOAuthSignup = async (idToken: string) => {
    setOAuthIdToken(idToken);
    setUsernameStep(true);
  };

  return (
    <Layout
      centered
      buttonLabel={
        isUsernameStep ? messages.secondaryButtonLabel : messages.buttonLabel
      }
      heading={isUsernameStep ? messages.secondaryHeading : messages.heading}
      isLoading={isLoading}
      subheader={
        <Centered>
          <Label
            value={
              isUsernameStep
                ? messages.secondarySubheaderLabel
                : messages.subheaderLabel
            }
          />
          {!isUsernameStep && (
            <Cta label={messages.subheaderButton} to={'/login'} />
          )}
        </Centered>
      }
      footer={!isUsernameStep && <Footnote />}
      onSubmit={handleSubmit(onSignup)}
    >
      {isUsernameStep ? (
        <>
          <BackButton onClick={() => setUsernameStep(false)} />
          <Group errors={errors} validate={FORM_FIELDS.USERNAME.name} spacing>
            <PrefixInput
              control={control}
              name={FORM_FIELDS.USERNAME.name}
              prefix="flytepost.com/"
            />
          </Group>
        </>
      ) : (
        <>
          <GoogleOauth onOAuthSignup={onOAuthSignup} signup />
          <Divider />
          <Row>
            <Col>
              <Group
                errors={errors}
                label={FORM_FIELDS.FIRST_NAME.label}
                validate={FORM_FIELDS.FIRST_NAME.name}
              >
                <Input control={control} name={FORM_FIELDS.FIRST_NAME.name} />
              </Group>
            </Col>
            <Col>
              <Group
                errors={errors}
                label={FORM_FIELDS.LAST_NAME.label}
                validate={FORM_FIELDS.LAST_NAME.name}
              >
                <Input control={control} name={FORM_FIELDS.LAST_NAME.name} />
              </Group>
            </Col>
          </Row>
          <Group
            errors={errors}
            label={FORM_FIELDS.EMAIL.label}
            validate={FORM_FIELDS.EMAIL.name}
            spacing
          >
            <Input control={control} name={FORM_FIELDS.EMAIL.name} />
          </Group>
          <Group
            errors={errors}
            label={FORM_FIELDS.PASSWORD.label}
            validate={FORM_FIELDS.PASSWORD.name}
            spacing
          >
            <Input
              control={control}
              name={FORM_FIELDS.PASSWORD.name}
              type="password"
            />
          </Group>
        </>
      )}
    </Layout>
  );
};

SignupForm.displayName = 'SignupForm';

export default SignupForm;
