import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';

import { marginTopStyle } from '../styles';

import ManufacturerField from './ManufacturerField';
import TypeField from './TypeField';
import VariantField from './VariantField';

interface Props<T extends FieldValues> {
  control: Control<T>;
  errors?: FieldErrors<T>;
}

const AircraftField = <T extends FieldValues>({
  control,
  errors,
}: Props<T>) => {
  const [manufacturer, setManufacturer] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [variant, setVariant] = useState<string>('');

  return (
    <>
      <ManufacturerField
        control={control}
        errors={errors}
        manufacturer={manufacturer}
        onManufacturer={(v: string) => setManufacturer(v)}
      />
      <Row style={marginTopStyle}>
        <Col xs={6}>
          <TypeField
            control={control}
            errors={errors}
            manufacturer={manufacturer}
            type={type}
            onType={(v: string) => setType(v)}
          />
        </Col>
        <Col xs={6}>
          <VariantField
            control={control}
            errors={errors}
            manufacturer={manufacturer}
            type={type}
            variant={variant}
            onVariant={(v: string) => setVariant(v)}
          />
        </Col>
      </Row>
    </>
  );
};

AircraftField.displayName = 'AircraftField';

export default AircraftField;
