import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
import { PostFragmentDoc } from '../../fragments/PostFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PostQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PostQueryResult = { readonly __typename: 'Query', readonly post: { readonly __typename: 'Post', readonly createdAt: any, readonly id: string, readonly imageUrl: string, readonly updatedAt: any, readonly author: { readonly __typename: 'Author', readonly name: string, readonly profileImageUrl?: string | null, readonly username: string }, readonly metadata: { readonly __typename: 'Metadata', readonly aircraft?: { readonly __typename: 'Aircraft', readonly manufacturer?: string | null, readonly type?: string | null, readonly variant?: string | null } | null, readonly airline?: { readonly __typename: 'Airline', readonly iata?: string | null, readonly icao?: string | null, readonly name?: string | null } | null, readonly airport?: { readonly __typename: 'Airport', readonly iata?: string | null, readonly icao?: string | null, readonly name?: string | null, readonly location?: { readonly __typename: 'Location', readonly country: string, readonly city: string, readonly lat: string, readonly lon: string, readonly state: string } | null } | null } } };


export const PostQueryDocument = gql`
    query PostQuery($id: ID!) {
  post(id: $id) {
    ...PostFragment
  }
}
    ${PostFragmentDoc}`;

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostQuery(baseOptions: Apollo.QueryHookOptions<PostQueryResult, PostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostQueryResult, PostQueryVariables>(PostQueryDocument, options);
      }
export function usePostQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostQueryResult, PostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostQueryResult, PostQueryVariables>(PostQueryDocument, options);
        }
export type PostQueryHookResult = ReturnType<typeof usePostQuery>;
export type PostQueryLazyQueryHookResult = ReturnType<typeof usePostQueryLazyQuery>;