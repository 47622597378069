import debounce from 'lodash/debounce';
import { useState } from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';

import { Combobox, Group } from 'apps/shared/scripts/components';
import { useAutocompleteAirport } from 'apps/shared/scripts/graphql';

import { FORM_FIELDS } from '../../containers/UploadFormContainer/constants';

interface Props<T extends FieldValues> {
  control: Control<T>;
  errors?: FieldErrors<T>;
}

const AirportField = <T extends FieldValues>({ control, errors }: Props<T>) => {
  const [query, setQuery] = useState<string>('');
  const { isLoading, suggestions } = useAutocompleteAirport({
    variables: {
      query,
    },
  });
  return (
    <Group
      errors={errors}
      label={FORM_FIELDS.AIRPORT.label}
      validate={FORM_FIELDS.AIRPORT.name}
    >
      <Combobox
        onAutocomplete={debounce((v: string) => setQuery(v), 500)}
        control={control}
        isLoading={isLoading}
        name={FORM_FIELDS.AIRPORT.name}
        options={suggestions}
      />
    </Group>
  );
};

AirportField.displayName = 'AirportField';

export default AirportField;
