import { profileFooterClassName } from './styles';

const ProfileFooter = () => {
  return (
    <div className={profileFooterClassName} />
  );
};

ProfileFooter.displayName = 'ProfileFooter';

export default ProfileFooter;
