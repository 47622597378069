import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import { galleryClassName, skeletonClassName } from './styles';

const LoadingSkeleton = () => {
  return (
    <div className={galleryClassName}>
      <Row>
        <Col md={6} xl={4}>
          <Skeleton className={skeletonClassName} />
        </Col>
        <Col md={6} xl={4}>
          <Skeleton className={skeletonClassName} />
        </Col>
        <Col md={6} xl={4}>
          <Skeleton className={skeletonClassName} />
        </Col>
      </Row>
    </div>
  );
};

LoadingSkeleton.displayName = 'LoadingSkeleton';

export default LoadingSkeleton;
