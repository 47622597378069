export const FORM_FIELDS = {
  CONFIRM_PASSWORD: {
    name: 'confirmPassword',
    label: 'Confirm Password',
  } as const,
  PASSWORD: {
    name: 'password',
    label: 'New Password',
  } as const,
};
