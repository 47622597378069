import { cx } from '@emotion/css';
import { useCallback, useState } from 'react';

import { Image } from 'apps/shared/scripts/components';

import {
  containerClassName,
  containerScaledStyle,
  imageClassName,
  imageScaledStyle,
  innerClassName,
  photoClassName,
  photoScaledStyle,
} from './styles';

interface Props {
  image: {
    url: string;
  };
}

const Photo = ({ image }: Props) => {
  const [scaled, setScaled] = useState<boolean>(true);

  const onClick = useCallback(() => {
    setScaled(!scaled);
  }, [scaled]);

  return (
    <div
      className={cx(photoClassName, {
        [photoScaledStyle]: scaled,
      })}
    >
      <div
        className={cx(containerClassName, {
          [containerScaledStyle]: scaled,
        })}
      >
        <div className={innerClassName}>
          <Image
            className={cx(imageClassName, {
              [imageScaledStyle]: scaled,
            })}
            onClick={onClick}
            src={image.url}
            scaled
          />
        </div>
      </div>
    </div>
  );
};

Photo.displayName = 'Photo';

export default Photo;
