import { css } from '@emotion/css';

import { Spacing } from '../../styles/constants';

export const spinnerClassName = css({
  marginTop: Spacing.MD,
  marginBottom: Spacing.MD,
});

export const iconClassName = css({
  height: 22,
  width: 22,
});
