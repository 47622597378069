import cx from 'classnames';

import { Button, ButtonTypes } from 'apps/shared/scripts/components';

import {
  actionClassName,
  buttonPrimaryClassName,
  centeredClassName,
  footerClassName,
  headerClassName,
  headingClassName,
  iconClassName,
  layoutClassName,
  mediaClassName,
  subheaderClassName,
  subheadingClassName,
} from './styles';
import type { LayoutProps } from './types';

type Props = LayoutProps;

const Layout = ({
  centered,
  children,
  buttonLabel,
  error,
  footer,
  heading,
  icon,
  isLoading = false,
  onSubmit,
  subheader,
  subheading,
}: Props) => {
  return (
    <div className={cx(layoutClassName, { [centeredClassName]: centered })}>
      <div className={headerClassName}>
        {icon && (
          <div className={mediaClassName}>
            <i className={iconClassName}>{icon}</i>
          </div>
        )}
        <div className={headingClassName}>{heading}</div>
        {(subheader || subheading) && (
          <div className={subheaderClassName}>
            {subheader}
            {subheading && (
              <div className={subheadingClassName}>{subheading}</div>
            )}
          </div>
        )}
      </div>
      <form onSubmit={onSubmit}>
        {children}
        <div className={actionClassName}>
          <Button
            className={buttonPrimaryClassName}
            disabled={isLoading}
            loading={isLoading}
            onClick={onSubmit}
            fullWidth
            type={ButtonTypes.Submit}
          >
            {buttonLabel}
          </Button>
        </div>
      </form>
      {/*error && (
        <div className={styles.error}>
          <Error.Banner message={error} />
        </div>
      )*/}
      {footer && <div className={footerClassName}>{footer}</div>}
    </div>
  );
};

Layout.displayName = 'Layout';

export default Layout;
