import { useApolloClient } from '@apollo/client';
import { useState } from 'react';

import { Firebase } from 'apps/shared/scripts/services';

interface LogoutReturn {
  result: boolean;
}

interface UseLogoutUserReturn {
  isLoading: boolean;
  logout: () => Promise<LogoutReturn>;
}

const useLogoutUser = (): UseLogoutUserReturn => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const client = useApolloClient();

  const logout = async (): Promise<LogoutReturn> => {
    let result = false;
    try {
      await Firebase.auth.logout();
      client.cache.reset();
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
    return { result };
  };

  return {
    isLoading,
    logout,
  };
};

export default useLogoutUser;
