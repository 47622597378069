import { labelClassName, metaItemClassName, valueClassName } from './styles';

interface Props {
  label: string;
  value: string;
}

const MetaItem = ({ label, value }: Props) => {
  return (
    <div className={metaItemClassName}>
      <span className={labelClassName}>{label}</span>
      <span className={valueClassName}>{value}</span>
    </div>
  );
};

MetaItem.displayName = 'MetaItem';

export default MetaItem;
