import { gql } from '@apollo/client';

import { ImageUpload } from 'apps/shared/scripts/__generated__/graphql.generated';

import { generateMutationHook } from '../../utils';

import {
  CreatePostMutationResult,
  CreatePostMutationVariables,
  useCreatePostMutation,
} from './useCreatePost.generated';

gql`
  mutation CreatePost($input: PostInput!) {
    createPost(input: $input) {
      signedUrl
    }
  }
`;

interface HookDataToOutputReturn {
  result?: ImageUpload;
}

const hookDataToOutput = (
  data?: CreatePostMutationResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    result: undefined,
  };

  if (data?.createPost) {
    toReturn.result = data.createPost;
  }

  return toReturn;
};

const useCreatePost = generateMutationHook<
  CreatePostMutationResult,
  CreatePostMutationVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useCreatePostMutation);

export default useCreatePost;
