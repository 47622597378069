import { useCallback } from 'react';
import toast from 'react-hot-toast';

const ERROR_COLOR = '#E00B41';
const SUCCESS_COLOR = '#0ABF53';

const useToast = () => {
  const error = useCallback(
    (message: string) =>
      toast.error(message, {
        position: 'top-right',
        style: {
          border: `1px solid ${ERROR_COLOR}`,
          padding: '16px',
          color: ERROR_COLOR,
        },
        iconTheme: {
          primary: ERROR_COLOR,
          secondary: '#FFFFFF',
        },
      }),
    []
  );

  const success = useCallback(
    (message: string) =>
      toast.success(message, {
        position: 'top-right',
        style: {
          border: `1px solid ${SUCCESS_COLOR}`,
          padding: '16px',
          color: SUCCESS_COLOR,
        },
        iconTheme: {
          primary: SUCCESS_COLOR,
          secondary: '#FFFFFF',
        },
      }),
    []
  );

  return { error, success };
};

export default useToast;
