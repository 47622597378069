import { gql } from '@apollo/client';

import { generateMutationHook } from '../../utils';

import {
  UpdateUserMutationResult,
  UpdateUserMutationVariables,
  useUpdateUserMutation,
} from './useUpdateUser.generated';

gql`
  mutation UpdateUserMutation($input: UpdateUserInput!){
    updateUser(input: $input) {
      _empty
    }
  }
`;

interface HookDataToOutputReturn {
  result?: boolean;
}

const hookDataToOutput = (
  data?: UpdateUserMutationResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    result: undefined,
  };

  if (data?.updateUser.__typename === 'EmptyResponse') {
    toReturn.result = true;
  }

  return toReturn;
};

const useUpdateUser = generateMutationHook<
  UpdateUserMutationResult,
  UpdateUserMutationVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useUpdateUserMutation);

export default useUpdateUser;
