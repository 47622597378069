import { gql } from '@apollo/client';

import { generateMutationHook } from '../../utils';

import {
  ForgotPasswordMutationResult,
  ForgotPasswordMutationVariables,
  useForgotPasswordMutation,
} from './useForgotPassword.generated';

gql`
  mutation ForgotPasswordMutation($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      _empty
    }
  }
`;

interface HookDataToOutputReturn {
  result?: boolean;
}

const hookDataToOutput = (
  data?: ForgotPasswordMutationResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    result: undefined,
  };

  if (data?.forgotPassword.__typename === 'EmptyResponse') {
    toReturn.result = true;
  }

  return toReturn;
};

const useForgotPassword = generateMutationHook<
  ForgotPasswordMutationResult,
  ForgotPasswordMutationVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useForgotPasswordMutation);

export default useForgotPassword;
