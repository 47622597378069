import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AutocompleteAirlineQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type AutocompleteAirlineQueryResult = { readonly __typename: 'Query', readonly autocompleteAirline: ReadonlyArray<{ readonly __typename: 'Airline', readonly iata?: string | null, readonly icao?: string | null, readonly name?: string | null }> };


export const AutocompleteAirlineQueryDocument = gql`
    query AutocompleteAirlineQuery($query: String) {
  autocompleteAirline(query: $query) {
    ... on Airline {
      iata
      icao
      name
    }
  }
}
    `;

/**
 * __useAutocompleteAirlineQuery__
 *
 * To run a query within a React component, call `useAutocompleteAirlineQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteAirlineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteAirlineQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAutocompleteAirlineQuery(baseOptions?: Apollo.QueryHookOptions<AutocompleteAirlineQueryResult, AutocompleteAirlineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutocompleteAirlineQueryResult, AutocompleteAirlineQueryVariables>(AutocompleteAirlineQueryDocument, options);
      }
export function useAutocompleteAirlineQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutocompleteAirlineQueryResult, AutocompleteAirlineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutocompleteAirlineQueryResult, AutocompleteAirlineQueryVariables>(AutocompleteAirlineQueryDocument, options);
        }
export type AutocompleteAirlineQueryHookResult = ReturnType<typeof useAutocompleteAirlineQuery>;
export type AutocompleteAirlineQueryLazyQueryHookResult = ReturnType<typeof useAutocompleteAirlineQueryLazyQuery>;