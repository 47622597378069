import cx from 'classnames';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ctaClassName, footerClassName } from './styles';

interface Props {
  footer?: boolean;
  label: string;
  to: string;
}

const Cta = ({ footer = false, label, to }: Props) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(to);
  }, [navigate, to]);

  return (
    <span
      className={cx(ctaClassName, {
        [footerClassName]: footer,
      })}
      onClick={onClick}
      role="button"
    >
      {label}
    </span>
  );
};

Cta.displayName = 'Cta';

export default Cta;
