import { css } from '@emotion/css';

import { Breakpoints, Colors } from 'apps/shared/scripts/styles/constants';

export const photoClassName = css({
  cursor: 'pointer',
  position: 'relative',
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    '&::after': {
      backgroundColor: Colors.Black,
      content: "''",
      opacity: 0,
      pointerEvents: 'none',
      transitionDuration: '0s',
      transitionProperty: 'opacity',
      transitionTimingFunction: 'ease-out',
      position: 'absolute',
      inset: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9,
    },
    '&:hover': {
      '&::after': {
        opacity: 0.15,
        transitionDuration: '0.3s',
      },
    },
  },
});

export const imageClassName = css({
  borderRadius: 4,
  '.image-inner': {
    paddingBottom: '78.25%',
  },
});
