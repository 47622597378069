import { useState } from 'react';

import { Firebase } from 'apps/shared/scripts/services';

interface ChangePasswordReturn {
  error: {
    code: string;
    message: string;
  };
  result: boolean;
}

interface UseChangePasswordReturn {
  isLoading: boolean;
  changePassword: (
    currentPassword: string,
    newPassword: string
  ) => Promise<ChangePasswordReturn>;
}

const useChangePassword = (): UseChangePasswordReturn => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const changePassword = async (
    currentPassword: string,
    newPassword: string
  ): Promise<ChangePasswordReturn> => {
    let error = { code: '', message: '' };
    let result = false;
    try {
      setLoading(true);
      await Firebase.auth.changePassword(currentPassword, newPassword);
      result = true;
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      error = parseError(e.code);
    }
    return { error, result };
  };

  return {
    isLoading,
    changePassword,
  };
};

export default useChangePassword;

const parseError = (code: string) => {
  switch (code) {
    case 'auth/wrong-password':
      return {
        code,
        message: 'Password is incorrect',
      };
    case 'auth/user-not-found':
      return {
        code,
        message: 'Email could not be found',
      };
    case 'auth/too-many-requests':
      return {
        code,
        message: 'Too many attempts - please try again later',
      };
    default:
      return {
        code,
        message: 'An error has occurred',
      };
  }
};
