import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';

import generatedIntrospection from '../../__generated__/possibleTypes.generated';
import { Firebase } from '../../services';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_BASE_URL,
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const token = await Firebase.auth.getToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  } catch (e) {
    return null;
  }
});

export const graphqlClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          posts: relayStylePagination(),
          postsByUsername: relayStylePagination(['username']),
        },
      },
    },
    possibleTypes: generatedIntrospection.possibleTypes,
  }),
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    watchQuery: {
      errorPolicy: 'all',
    },
  },
  link: authLink.concat(httpLink),
});
