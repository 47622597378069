import { cx } from '@emotion/css';
import Skeleton from 'react-loading-skeleton';

import { headerClassName, headingSkeletonClassName, imageSkeletonClassName, profileHeroClassName, skeletonClassName, subheadingSkeletonClassName } from './styles';

const LoadingSkeleton = () => {
  return (
    <div className={cx(profileHeroClassName, skeletonClassName)}>
      <Skeleton className={imageSkeletonClassName} />
      <div className={headerClassName}>
        <Skeleton className={headingSkeletonClassName} />
        <Skeleton className={subheadingSkeletonClassName} />
      </div>
    </div>
  );
};

LoadingSkeleton.displayName = 'LoadingSkeleton';

export default LoadingSkeleton;
