import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AutocompleteAirportQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type AutocompleteAirportQueryResult = { readonly __typename: 'Query', readonly autocompleteAirport: ReadonlyArray<{ readonly __typename: 'Airport', readonly iata?: string | null, readonly icao?: string | null, readonly name?: string | null, readonly location?: { readonly __typename: 'Location', readonly city: string, readonly country: string } | null }> };


export const AutocompleteAirportQueryDocument = gql`
    query AutocompleteAirportQuery($query: String) {
  autocompleteAirport(query: $query) {
    ... on Airport {
      iata
      icao
      location {
        city
        country
      }
      name
    }
  }
}
    `;

/**
 * __useAutocompleteAirportQuery__
 *
 * To run a query within a React component, call `useAutocompleteAirportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteAirportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteAirportQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAutocompleteAirportQuery(baseOptions?: Apollo.QueryHookOptions<AutocompleteAirportQueryResult, AutocompleteAirportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutocompleteAirportQueryResult, AutocompleteAirportQueryVariables>(AutocompleteAirportQueryDocument, options);
      }
export function useAutocompleteAirportQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutocompleteAirportQueryResult, AutocompleteAirportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutocompleteAirportQueryResult, AutocompleteAirportQueryVariables>(AutocompleteAirportQueryDocument, options);
        }
export type AutocompleteAirportQueryHookResult = ReturnType<typeof useAutocompleteAirportQuery>;
export type AutocompleteAirportQueryLazyQueryHookResult = ReturnType<typeof useAutocompleteAirportQueryLazyQuery>;