import { useState } from 'react';

import { Firebase } from 'apps/shared/scripts/services';

interface LoginReturn {
  error: {
    code: string;
    message: string;
  };
  result: boolean;
}

interface UseLoginUserReturn {
  isLoading: boolean;
  login: (email: string, password: string) => Promise<LoginReturn>;
}

const useLoginUser = (): UseLoginUserReturn => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const login = async (
    email: string,
    password: string
  ): Promise<LoginReturn> => {
    let error = { code: '', message: '' };
    let result = false;
    try {
      setLoading(true);
      await Firebase.auth.login(email, password);
      result = true;
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      error = parseError(e.code);
    }
    return { error, result };
  };

  return {
    isLoading,
    login,
  };
};

export default useLoginUser;

const parseError = (code: string) => {
  switch (code) {
    case 'auth/invalid-login-credentials':
      return {
        code,
        message: 'Invalid email or password',
      };
    case 'auth/wrong-password':
      return {
        code,
        message: 'Password is incorrect',
      };
    case 'auth/user-not-found':
      return {
        code,
        message: 'Email could not be found',
      };
    case 'auth/too-many-requests':
      return {
        code,
        message: 'Too many attempts - please try again later',
      };
    default:
      return {
        code,
        message: 'An error has occurred',
      };
  }
};
