import { cx } from '@emotion/css';
import { ReactNode } from 'react';

import {
  bodyClassName,
  headerClassName,
  headingClassName,
  sectionClassName,
  subheadingClassName,
} from './styles';

interface Props {
  children: ReactNode;
  className?: string;
}

const Section = ({ children, className = '' }: Props) => {
  return (
    <div className={cx(sectionClassName, { [className]: !!className })}>
      <div className={headerClassName}>
        <h2 className={headingClassName}>Metadata</h2>
        <h4 className={subheadingClassName}>
          Tag your photo with optional context and details.
        </h4>
      </div>
      <div className={bodyClassName}>{children}</div>
    </div>
  );
};

Section.displayName = 'Section';

export default Section;
