import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const groupClassName = css({
  marginBottom: 0,
});

export const spacingClassName = css({
  marginTop: Spacing.MD,
});

export const errorColorClassName = css({
  '&&': {
    color: Colors.Red,
  },
});

export const headClassName = css({
  marginBottom: Spacing.XS,
});

export const headerClassName = css({
  alignItems: 'center',
  display: 'flex',
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.SEMI_BOLD,
  lineHeight: 1.6,
});

export const subheaderClassName = css({
  display: 'block',
});

export const subheadingClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.NORMAL,
  lineHeight: 1.6,
});

export const bodyClassName = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
});

