import { css } from '@emotion/css';

import {
  Breakpoints,
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const uploadHeadClassName = css({
  background: Colors.White,
  padding: `${Spacing.LG}px ${Spacing.MD}px`,
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',

  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    padding: `${Spacing.LG}px ${Spacing.LG}px ${Spacing.MD * 1.25}px`,
  },
});

export const headerClassName = css({
  alignItems: 'center',
  display: 'flex',
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: FontSize.LARGE,
  fontWeight: FontWeight.BOLD,
});

export const actionClassName = css({
  marginLeft: 'auto',
});

export const buttonClassName = css({});
