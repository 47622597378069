import { css } from '@emotion/css';

import { Breakpoints } from '../../styles/constants';

export const modalClassName = css({
  borderRadius: 0,
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  touchAction: 'none',
  width: '100vw',
  zIndex: '9999',
});

export const containerClassName = css({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const modalScrollableStyle = css({
  display: 'block',
  overflow: 'auto',
  pointerEvents: 'all',
});

export const containerScrollableStyle = css({
  alignItems: 'flex-start',
  height: '100%',
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    height: 'auto',
  },
});
