import { headingClassName, heroClassName, subheadingClassName } from './styles';

const Hero = () => {
  return (
    <div className={heroClassName}>
      <h2 className={headingClassName}>FAQs</h2>
      <h4 className={subheadingClassName}>
        We've compiled a list of most frequently asked questions to help you get
        started with Flytepost or resolve a common problem.
      </h4>
    </div>
  );
};

Hero.displayName = 'Hero';

export default Hero;
