import { Metadata } from 'apps/shared/scripts/__generated__/graphql.generated';

import MetaItem from './MetaItem';
import { innerClassName, metaClassName } from './styles';

interface Props {
  metadata: Metadata;
}

const Meta = ({ metadata }: Props) => {
  const { aircraft, airline, airport } = metadata;
  return (
    <div className={metaClassName}>
      <div className={innerClassName}>
        {airport?.name && <MetaItem label="Airport" value={airport.name} />}
        {airline?.name && <MetaItem label="Airline" value={airline.name} />}
        {aircraft && (
          <MetaItem
            label="Aircraft"
            value={`${aircraft.manufacturer} ${aircraft.variant}`}
          />
        )}
      </div>
    </div>
  );
};

Meta.displayName = 'Meta';

export default Meta;
