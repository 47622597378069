import { Link } from 'react-router-dom';

import { footnoteClassName, linkClassName, textClassName } from './styles';

const Footnote = () => {
  return (
    <div className={footnoteClassName}>
      <span className={textClassName}>
        By creating an account, you agree to Flytepost's{' '}
        <Link
          className={linkClassName}
          to="/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </Link>{' '}
        and confirm you have reviewed our{' '}
        <Link
          className={linkClassName}
          to="/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Notice
        </Link>
        .
      </span>
    </div>
  );
};

Footnote.displayName = 'Footnote';

export default Footnote;
