import { cx } from '@emotion/css';
import { ReactNode, useCallback, useRef, useState } from 'react';

import { useOutsideClick } from '../../hooks';
import Button from '../Button';

import Menu from './Menu';
import { buttonClassName, dropdownClassName } from './styles';

interface Props {
  activeClassName?: string;
  anchor: ReactNode;
  anchorClassName?: string;
  children: ReactNode;
  loading?: boolean;
  menuClassName?: string;
}

const Dropdown = ({
  activeClassName = '',
  anchor,
  anchorClassName = '',
  children,
  loading,
  menuClassName = '',
}: Props) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClick(buttonRef, menuRef, () => setOpen(false));

  const onToggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <div className={dropdownClassName}>
      <Button
        className={cx(buttonClassName, {
          [anchorClassName]: !!anchorClassName,
          [activeClassName]: !!activeClassName && isOpen,
        })}
        disabled={loading}
        onClick={onToggle}
        ref={buttonRef}
      >
        {anchor}
      </Button>
      {isOpen && (
        <Menu className={menuClassName} ref={menuRef}>
          {children}
        </Menu>
      )}
    </div>
  );
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
