import { cx } from '@emotion/css';
import { CSSProperties } from 'react';

import {
  imageClassName,
  imgClassName,
  innerClassName,
  pictureClassName,
  scaledClassName,
} from './styles';

interface Props {
  alt?: string;
  className?: string;
  onClick?: () => void;
  scaled?: boolean;
  src: string;
  style?: CSSProperties;
}

const Image = ({
  alt = '',
  className = '',
  onClick,
  scaled = false,
  src,
  style,
}: Props) => {
  return (
    <div
      className={cx(imageClassName, {
        [scaledClassName]: scaled,
        [className]: !!className,
      })}
      onClick={onClick}
      style={style}
    >
      <div className={cx('image-inner', innerClassName)}>
        <picture className={cx('image-picture', pictureClassName)}>
          <img className={cx('image-img', imgClassName)} alt={alt} src={src} />
        </picture>
      </div>
    </div>
  );
};

Image.displayName = 'Image';

export default Image;
