import { gql } from '@apollo/client';

import { generateMutationHook } from '../../utils';

import {
  SendEmailVerificationMutationResult,
  SendEmailVerificationMutationVariables,
  useSendEmailVerificationMutation,
} from './useSendEmailVerification.generated';

gql`
  mutation SendEmailVerificationMutation {
    sendEmailVerification {
      _empty
    }
  }
`;

interface HookDataToOutputReturn {
  result?: boolean;
}

const hookDataToOutput = (
  data?: SendEmailVerificationMutationResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    result: undefined,
  };

  if (data?.sendEmailVerification.__typename === 'EmptyResponse') {
    toReturn.result = true;
  }

  return toReturn;
};

const useSendEmailVerification = generateMutationHook<
  SendEmailVerificationMutationResult,
  SendEmailVerificationMutationVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useSendEmailVerificationMutation);

export default useSendEmailVerification;
