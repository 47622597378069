import { css } from '@emotion/css';

import { Breakpoints } from 'apps/shared/scripts/styles/constants';

export const enterClassName = css({
  opacity: 0,
});

export const enterActiveClassName = css({
  opacity: 1,
  transition: '0.4s',
});

export const exitClassName = css({
  opacity: 1,
});

export const exitActiveClassName = css({
  opacity: 0,
  transition: '0.4s',
});

export const overlayClassName = css({
  background: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none',
  height: '100vh',
  width: '100vw',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 999,
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    background: 'rgba(25, 25, 25, 0.6)',
  },
});
