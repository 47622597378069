import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const profilePhotoClassName = css({
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
});

export const imageClassName = css({
  borderRadius: 100,
  height: 92,
  width: 92,
});

export const editClassName = css({
  marginTop: '-12px',
});

export const pillButtonClassName = css({
  background: Colors.White,
  borderRadius: 100,
  boxShadow: 'rgba(0, 0, 0, 0.12) 0px 6px 16px',
  color: Colors.Primary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.MEDIUM,
  minHeight: 32,
  minWidth: 82,
  padding: `0 ${Spacing.MD}px`,
});

export const iconClassName = css({
  height: 14,
  width: 14,
  svg: {
    transition: '0s',
  },
});

export const wrapperClassName = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const deleteClassName = css({
  color: Colors.Red,
});
