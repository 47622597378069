import { Col, Container, Row } from 'react-bootstrap';

import PhotoRowMedia, { Props as MediaProps } from './PhotoRowMedia';
import PhotoRowText, { Props as TextProps } from './PhotoRowText';
import { leftClassName, photoRowClassName, rightClassName } from './styles';

interface OwnProps {
  rtl?: boolean;
}

type Props = MediaProps & TextProps & OwnProps;

const PhotoRow = ({ description, rtl, src, title }: Props) => {
  return (
    <div className={photoRowClassName}>
      <Container>
        <Row className="align-items-center">
          <Col
            xs={{ order: rtl ? 'last' : 'first' }}
            md={{ order: 'first', span: 6 }}
          >
            <div className={leftClassName}>
              {rtl ? (
                <PhotoRowText description={description} title={title} />
              ) : (
                <PhotoRowMedia src={src} />
              )}
            </div>
          </Col>
          <Col
            xs={{ order: rtl ? 'first' : 'last' }}
            md={{ order: 'last', span: 6 }}
          >
            <div className={rightClassName}>
              {rtl ? (
                <PhotoRowMedia src={src} />
              ) : (
                <PhotoRowText description={description} title={title} />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

PhotoRow.displayName = 'PhotoRow';

export default PhotoRow;
