import { gql } from '@apollo/client';

import { generateMutationHook } from '../../utils';

import {
  UpdatePostMutationResult,
  UpdatePostMutationVariables,
  useUpdatePostMutation,
} from './useUpdatePost.generated';

gql`
  mutation UpdatePost($id: ID!, $input: PostInput!) {
    updatePost(id: $id, input: $input) {
      _empty
    }
  }
`;

interface HookDataToOutputReturn {
  result?: boolean;
}

const hookDataToOutput = (
  data?: UpdatePostMutationResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    result: undefined,
  };

  if (data?.updatePost.__typename === 'EmptyResponse') {
    toReturn.result = true;
  }

  return toReturn;
};

const useUpdatePost = generateMutationHook<
  UpdatePostMutationResult,
  UpdatePostMutationVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useUpdatePostMutation);

export default useUpdatePost;
