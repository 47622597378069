import { css } from '@emotion/css';

import { Breakpoints } from '../../styles/constants';

export const cardClassName = css({
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    borderRadius: 4,
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 25px',
    minHeight: 'calc(100vh - 2.5rem - 20px)',
    margin: 'auto auto 1rem',
    maxHeight: 'none',
    maxWidth: 'calc(100vw - 2rem)',
  },
  [`@media(min-width: ${Breakpoints.MD}px)`]: {
    margin: '1rem auto',
    minHeight: 'calc(100vh - 2rem)',
    maxWidth: 'calc(100vw - 8rem)',
    overflow: 'hidden',
  },
  [`@media(min-width: ${Breakpoints.LG}px)`]: {
    maxWidth: 'calc(100vw - 15rem)',
  },
});
