import { css } from '@emotion/css';

import { Colors, FontSize, FontWeight, Spacing } from '../../styles/constants';

export const errorClassName = css({
  alignItems: 'center',
  display: 'flex',
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.BOLD,
  marginTop: Spacing.XS,
});

export const errorMessageClassName = css({
  color: Colors.Red,
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 1.2,
});
