import { useCallback, useState } from 'react';

interface Options {
  showSuccess?: boolean; // for components that consume the "isSuccess" field
}

const useFormAsync = ({ showSuccess }: Options | undefined = {}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);

  const onStart = useCallback(() => {
    setLoading(true);
  }, []);

  const onSuccess = useCallback(
    async (callback?: () => void | Promise<void>) => {
      if (showSuccess) {
        setLoading(false);
        setSuccess(true);
        setTimeout(async () => {
          if (callback) {
            await callback();
          }
          setSuccess(false);
        }, 1500);
      } else {
        if (callback) {
          await callback();
        }
        setLoading(false);
      }
    },
    [showSuccess]
  );

  const onFail = useCallback(() => {
    setLoading(false);
  }, []);

  return { isLoading, isSuccess, onStart, onFail, onSuccess };
};

export default useFormAsync;
