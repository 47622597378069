import { css } from '@emotion/css';

import { Breakpoints } from 'apps/shared/scripts/styles/constants';

export const photoClassName = css({
  pointerEvents: 'none',
  position: 'relative',
  userSelect: 'none',
  '@media (min-width: 768px)': {
    pointerEvents: 'all',
  },
});

export const containerClassName = css({
  paddingRight: 0,
  paddingLeft: 0,
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    paddingRight: 'auto',
    paddingLeft: 'auto',
  },
});

export const innerClassName = css({
  margin: '0 auto',
  position: 'relative',
  height: '100%',
  top: 0,
  width: '100%',
});

export const imageClassName = css({
  height: '100%',
  '@media (min-width: 768px)': {
    cursor: 'zoom-out',
  },
  '.image-inner': {
    paddingBottom: 0,
    '.image-picture .image-img': {
      objectFit: 'contain',
      position: 'relative',
    },
  },
});

export const photoScaledStyle = css({
  marginTop: '0',
  '@media (min-width: 768px)': {
    height: 'calc(100vh - 72px - 4.5rem)',
    minHeight: 400,
  },
});

export const containerScaledStyle = css({
  '@media (min-width: 768px)': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    WebkitTransform: 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    height: '100%',
    width: 'calc(100% - 2rem)',
  },
});

export const imageScaledStyle = css({
  '@media (min-width: 768px)': {
    cursor: 'zoom-in',
    '.image-inner .image-picture .image-img': {
      position: 'absolute',
    },
  },
});
