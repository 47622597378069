import { useUserProfile } from 'apps/shared/scripts/graphql';

import LoadingSkeleton from './LoadingSkeleton';
import ProfilePhoto from './ProfilePhoto';
import {
  headerClassName,
  headingClassName,
  profileHeroClassName,
  subheadingClassName,
} from './styles';

interface Props {
  username: string;
}

const ProfileHero = ({ username }: Props) => {
  const { profile, isLoading } = useUserProfile({
    variables: { username },
  });

  if (isLoading || !profile) {
    return <LoadingSkeleton />;
  }

  const { name, profileImageUrl } = profile;

  return (
    <div className={profileHeroClassName}>
      <ProfilePhoto imageUrl={profileImageUrl} />
      <div className={headerClassName}>
        <h2 className={headingClassName}>{name}</h2>
        <h4 className={subheadingClassName}>@{username}</h4>
      </div>
    </div>
  );
};

ProfileHero.displayName = 'ProfileHero';

export default ProfileHero;
