import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AutocompleteAircraftManufacturerQueryVariables = Types.Exact<{
  manufacturer: Types.Scalars['String']['input'];
}>;


export type AutocompleteAircraftManufacturerQueryResult = { readonly __typename: 'Query', readonly autocompleteAircraftManufacturer: ReadonlyArray<string> };


export const AutocompleteAircraftManufacturerQueryDocument = gql`
    query AutocompleteAircraftManufacturerQuery($manufacturer: String!) {
  autocompleteAircraftManufacturer(manufacturer: $manufacturer)
}
    `;

/**
 * __useAutocompleteAircraftManufacturerQuery__
 *
 * To run a query within a React component, call `useAutocompleteAircraftManufacturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteAircraftManufacturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteAircraftManufacturerQuery({
 *   variables: {
 *      manufacturer: // value for 'manufacturer'
 *   },
 * });
 */
export function useAutocompleteAircraftManufacturerQuery(baseOptions: Apollo.QueryHookOptions<AutocompleteAircraftManufacturerQueryResult, AutocompleteAircraftManufacturerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutocompleteAircraftManufacturerQueryResult, AutocompleteAircraftManufacturerQueryVariables>(AutocompleteAircraftManufacturerQueryDocument, options);
      }
export function useAutocompleteAircraftManufacturerQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutocompleteAircraftManufacturerQueryResult, AutocompleteAircraftManufacturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutocompleteAircraftManufacturerQueryResult, AutocompleteAircraftManufacturerQueryVariables>(AutocompleteAircraftManufacturerQueryDocument, options);
        }
export type AutocompleteAircraftManufacturerQueryHookResult = ReturnType<typeof useAutocompleteAircraftManufacturerQuery>;
export type AutocompleteAircraftManufacturerQueryLazyQueryHookResult = ReturnType<typeof useAutocompleteAircraftManufacturerQueryLazyQuery>;