export const getHeight = (): number => {
  if (typeof window !== 'undefined') {
    return (
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    );
  }
  return 0;
};
