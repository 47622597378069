import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const prefixInputClassName = css({
  alignItems: 'center',
  boxShadow: '#DDDDDD 0px 0px 0px 1px inset',
  borderRadius: 8,
  display: 'flex',
  minHeight: 50,
  padding: `${Spacing.XS}px ${Spacing.MD}px`,
  '&:focus-within': {
    boxShadow: `${Colors.Primary} 0px 0px 0px 2px inset`,
  },
});

export const prefixClassName = css({
  display: 'block',
  color: Colors.Secondary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  marginRight: Spacing['2XS'],
  whiteSpace: 'nowrap',
});

export const inputClassName = css({
  borderRadius: 0,
  boxShadow: 'none',
  minHeight: '100%',
  padding: 0,
  '&:focus': {
    boxShadow: 'none',
  },
});

export const errorClassName = css({
  boxShadow: `${Colors.Red} 0px 0px 0px 2px inset !important`,
});
