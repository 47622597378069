import { useCallback, useEffect } from 'react';

import { PageLoader } from 'apps/shared/scripts/components';
import { useVerifyEmail } from 'apps/shared/scripts/graphql';

interface Props {
  code: string;
  onError: (message?: string) => void;
  onSuccess: () => void;
}

const VerifyEmail = ({ code, onError, onSuccess }: Props) => {
  const { mutate: verifyEmail } = useVerifyEmail();

  const onVerify = useCallback(
    async (code: string) => {
      const { errors } = await verifyEmail({
        input: {
          code,
        },
      });
      if (errors.length) {
        onError(errors[0].message);
      } else {
        onSuccess();
      }
    },
    [onError, onSuccess, verifyEmail]
  );

  useEffect(() => {
    if (code) {
      onVerify(code);
    }
  }, []); // eslint-disable-line

  return <PageLoader />;
};

VerifyEmail.displayName = 'VerifyEmail';

export default VerifyEmail;
