import { gql } from '@apollo/client';

import { generateMutationHook } from '../../utils';

import {
  DeletePostMutationResult,
  DeletePostMutationVariables,
  useDeletePostMutation,
} from './useDeletePost.generated';

gql`
  mutation DeletePost($id: ID!) {
    deletePost(id: $id) {
      _empty
    }
  }
`;

interface HookDataToOutputReturn {
  result?: boolean;
}

const hookDataToOutput = (
  data?: DeletePostMutationResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    result: undefined,
  };

  if (data?.deletePost.__typename === 'EmptyResponse') {
    toReturn.result = true;
  }

  return toReturn;
};

const useDeletePost = generateMutationHook<
  DeletePostMutationResult,
  DeletePostMutationVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useDeletePostMutation);

export default useDeletePost;
