import * as yup from 'yup';

import { FORM_FIELDS } from './constants';

export const uploadSchema = yup.object().shape({
  [FORM_FIELDS.AIRCRAFT.name]: yup.string(),
  [FORM_FIELDS.AIRLINE.name]: yup.string(),
  [FORM_FIELDS.AIRPORT.name]: yup.string(),
  [FORM_FIELDS.PHOTO.name]: yup.string().required('A photo is required'),
  [FORM_FIELDS.TYPE.name]: yup.string(),
  [FORM_FIELDS.VARIANT.name]: yup.string(),
});
