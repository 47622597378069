import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const rightContentClassName = css({
  alignItems: 'center',
  display: 'flex',
  flex: '1',
  gap: Spacing.MD,
  justifyContent: 'flex-end',
});

export const uploadButtonClassName = css({
  background: '#F0F0F0',
  borderRadius: 6,
  color: Colors.Primary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.SEMI_BOLD,
  minHeight: 40,
  padding: `${Spacing.XS}px ${Spacing.MD}px`,
  '&:hover': {
    background: '#E8E8E8',
  },
});

export const loginButtonClassName = css({
  border: `1px solid rgb(233, 233, 233)`,
  borderRadius: 6,
  color: Colors.Primary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.SEMI_BOLD,
  minHeight: 40,
  padding: `${Spacing.XS}px ${Spacing.MD}px`,
  '&:hover': {
    opacity: 0.9,
  },
});

export const signupButtonClassName = css({
  background: '#F0F0F0',
  borderRadius: 6,
  color: Colors.Primary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.SEMI_BOLD,
  minHeight: 40,
  padding: `${Spacing.XS}px ${Spacing.MD}px`,
  '&:hover': {
    background: '#E8E8E8',
  },
});

export const imageClassName = css({
  borderRadius: 100,
  height: 40,
  width: 40,
});

export const menuClassName = css({
  marginTop: 28,
});

export const skeletonClassName = css({
  borderRadius: `${100}px !important`,
  height: 40,
  width: `${40}px !important`,
});
