import { css } from '@emotion/css';

import { Colors } from 'apps/shared/scripts/styles/constants';

export const uploadPreviewClassName = css({
  margin: 0,
});

export const imageClassName = css({
  background: Colors.White,
  borderRadius: 0,
  maxWidth: '100%',
  width: '100%',
  '& .image-inner': {
    paddingBottom: '60%',
    '& .image-picture .image-img': {
      objectFit: 'cover',
    },
  },
});
