import { Button } from 'apps/shared/scripts/components';

import { ReactComponent as ChevronDownIconSvg } from './chevron-down.svg';
import { mobileDismissButtonClassName, mobileDismissClassName } from './styles';

interface Props {
  onClick: () => void;
}

const MobileDismissButton = ({ onClick }: Props) => {
  return (
    <div className={mobileDismissClassName}>
      <Button
        className={mobileDismissButtonClassName}
        svgIcon={<ChevronDownIconSvg />}
        onClick={onClick}
        bounce
      />
    </div>
  );
};

MobileDismissButton.displayName = 'MobileDismissButton';

export default MobileDismissButton;
