import debounce from 'lodash/debounce';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';

import { Combobox, Group } from 'apps/shared/scripts/components';
import { useAutocompleteAircraftVariant } from 'apps/shared/scripts/graphql';

import { FORM_FIELDS } from '../../../containers/UploadFormContainer/constants';

interface Props<T extends FieldValues> {
  control: Control<T>;
  errors?: FieldErrors<T>;
  manufacturer: string;
  type: string;
  variant: string;
  onVariant: (v: string) => void;
}

const VariantField = <T extends FieldValues>({
  control,
  errors,
  manufacturer,
  type,
  variant,
  onVariant,
}: Props<T>) => {
  const { isLoading, suggestions } = useAutocompleteAircraftVariant({
    variables: {
      manufacturer,
      type,
      variant,
    },
  });

  return (
    <Group
      errors={errors}
      label={FORM_FIELDS.VARIANT.label}
      validate={FORM_FIELDS.VARIANT.name}
    >
      <Combobox
        isLoading={isLoading}
        onAutocomplete={debounce(onVariant, 500)}
        control={control}
        name={FORM_FIELDS.VARIANT.name}
        options={suggestions}
      />
    </Group>
  );
};

VariantField.displayName = 'VariantField';

export default VariantField;
