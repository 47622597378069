import { css } from '@emotion/css';

import { Colors, FontSize, FontWeight, LineHeight, Spacing } from 'apps/shared/scripts/styles/constants';

export const metaClassName = css({
  padding: `${Spacing.LG}px ${Spacing.MD}px`,
  '@media (min-width: 768px)': {
    padding: `2rem 1.5rem 1.75rem`,
  },
});

export const innerClassName = css({
  width: '100%',
});

export const metaItemClassName = css({
  alignItems: 'center',
  display: 'flex',
  marginBottom: Spacing.XS,
  '&:last-of-type': {
    marginBottom: 0,
  }
});

export const labelClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  display: 'block',
  lineHeight: LineHeight.LARGE,
  marginRight: Spacing.MD,
  minWidth: 92,
});

export const valueClassName = css({
  color: Colors.Primary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.NORMAL,
  display: 'block',
  lineHeight: LineHeight.LARGE,
});
