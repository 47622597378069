import { Maybe } from '../__generated__/graphql.generated';

type Edge<T> = {
  readonly node?: Maybe<T>;
};

type Connection<T> = {
  readonly edges?: Maybe<ReadonlyArray<Maybe<Edge<T>>>>;
};

const connectionEdgesToList = <T extends {}>(
  connection: Connection<T>
): T[] => {
  const output: T[] = [];
  if (!connection.edges) return output;
  for (const edge of connection.edges) {
    if (edge && edge.node) {
      output.push(edge.node);
    }
  }
  return output;
};

export default connectionEdgesToList;
