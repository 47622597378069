import * as yup from 'yup';

import { FORM_FIELDS } from './constants';

export const signupSchema = yup.object().shape({
  [FORM_FIELDS.FIRST_NAME.name]: yup
    .string()
    .required('First name is required'),
  [FORM_FIELDS.LAST_NAME.name]: yup.string().required('Last name is required'),
  [FORM_FIELDS.EMAIL.name]: yup
    .string()
    .email('Email is invalid')
    .required('Email is required'),
  [FORM_FIELDS.PASSWORD.name]: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  [FORM_FIELDS.USERNAME.name]: yup
    .string()
    .min(2, 'Username must be at least 2 characters')
    .required('Username is required'),
});

export const preSignupSchema = signupSchema.shape({
  [FORM_FIELDS.USERNAME.name]: yup.string().optional(),
});

export const oAuthSignupSchema = signupSchema.shape({
  [FORM_FIELDS.FIRST_NAME.name]: yup.string().optional(),
  [FORM_FIELDS.LAST_NAME.name]: yup.string().optional(),
  [FORM_FIELDS.EMAIL.name]: yup.string().optional(),
  [FORM_FIELDS.PASSWORD.name]: yup.string().optional(),
  [FORM_FIELDS.USERNAME.name]: yup
    .string()
    .min(2, 'Username must be at least 2 characters')
    .required('Username is required'),
});