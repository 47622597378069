import { cx } from '@emotion/css';
import { forwardRef } from 'react';

import Icon from './Icon';
import {
  bounceClassName,
  buttonClassName,
  contentClassName,
  disabledClassName,
  fullWidthClassName,
  hiddenClassName,
  loadingClassName,
  successClassName,
} from './styles';
import SuperIcon from './SuperIcon';
import Text from './Text';
import { ButtonProps, ButtonTypes } from './types';

type Ref = HTMLButtonElement;

type Props = ButtonProps;

const Button = forwardRef<Ref, Props>(
  (
    {
      bounce,
      className = '',
      children,
      disabled = false,
      faIcon = undefined,
      fullWidth = false,
      iconAft = false,
      iconClassName = '',
      loading = false,
      onClick = () => {},
      success = false,
      style = {},
      svgIcon = undefined,
      textClassName = '',
      type = ButtonTypes.Button,
    },
    ref
  ) => {
    const isLoadingOrSuccess = loading || success;

    const handleClick: ButtonProps['onClick'] = (e) => {
      if (disabled || isLoadingOrSuccess) {
        return e?.preventDefault();
      }
      return onClick(e);
    };

    return (
      <button
        className={cx(buttonClassName, {
          [bounceClassName]: bounce,
          [disabledClassName]: disabled,
          [fullWidthClassName]: fullWidth,
          [loadingClassName]: loading,
          [successClassName]: success,
          [className]: !!className,
        })}
        disabled={disabled}
        onClick={handleClick}
        style={style}
        type={type}
        ref={ref}
      >
        <SuperIcon
          iconClassName={iconClassName}
          loading={loading}
          success={success}
        />
        <span
          data-test-id="button-content"
          className={cx(contentClassName, {
            [hiddenClassName]: isLoadingOrSuccess,
          })}
        >
          {!iconAft && (faIcon || svgIcon) && (
            <Icon
              className={iconClassName}
              fa={faIcon}
              svg={svgIcon}
              pre={!!children}
            />
          )}
          {!!children && <Text className={textClassName}>{children}</Text>}
          {iconAft && (
            <Icon className={iconClassName} fa={faIcon} svg={svgIcon} aft />
          )}
        </span>
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
