import { SubmitHandler, useForm } from 'react-hook-form';

import { Centered, Cta, Label, Layout } from 'apps/auth/scripts/components';
import { Group, Input } from 'apps/shared/scripts/components';
import { useToast, useYupValidation } from 'apps/shared/scripts/hooks';

import { FORM_FIELDS } from './constants';
import messages from './messages.json';
import { FormValues } from './types';
import { schema } from './validation';

interface Props {
  handleReset: (data: FormValues) => Promise<void>;
  isLoading: boolean;
}

const ResetForm = ({ handleReset, isLoading }: Props) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      confirmPassword: '',
      password: '',
    },
    resolver: useYupValidation<FormValues>(schema),
  });
  const toast = useToast();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await handleReset(data);
      toast.success('Success! Password was reset');
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  };

  return (
    <Layout
      centered
      buttonLabel={messages.buttonLabel}
      heading={messages.heading}
      isLoading={isLoading}
      subheader={
        <Centered>
          <Label value={messages.subheaderLabel} />
        </Centered>
      }
      footer={<Cta label={messages.footerLabel} to={'/login'} footer />}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Group
        errors={errors}
        label={FORM_FIELDS.PASSWORD.label}
        validate={FORM_FIELDS.PASSWORD.name}
        spacing
      >
        <Input control={control} name={FORM_FIELDS.PASSWORD.name} type="password" />
      </Group>
      <Group
        errors={errors}
        label={FORM_FIELDS.CONFIRM_PASSWORD.label}
        validate={FORM_FIELDS.CONFIRM_PASSWORD.name}
        spacing
      >
        <Input control={control} name={FORM_FIELDS.CONFIRM_PASSWORD.name} type="password" />
      </Group>
    </Layout>
  );
};

ResetForm.displayName = 'ResetForm';

export default ResetForm;
