import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePostMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.PostInput;
}>;


export type UpdatePostMutationResult = { readonly __typename: 'Mutation', readonly updatePost: { readonly __typename: 'EmptyResponse', readonly _empty?: boolean | null } };


export const UpdatePostDocument = gql`
    mutation UpdatePost($id: ID!, $input: PostInput!) {
  updatePost(id: $id, input: $input) {
    _empty
  }
}
    `;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutationResult, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutationResult, UpdatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostMutationResult, UpdatePostMutationVariables>(UpdatePostDocument, options);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutationResult, UpdatePostMutationVariables>;