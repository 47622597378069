import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { TopNav } from 'apps/shared/scripts/components';
import { useForgotPassword } from 'apps/shared/scripts/graphql';

import ForgotForm, { FormValues } from './ForgotForm';

const ForgotContainer = () => {
  const { isMutating: isLoading, mutate: forgotPassword } = useForgotPassword();

  const navigate = useNavigate();

  const handleForgot = useCallback(
    async (data: FormValues) => {
      const { result, errors } = await forgotPassword({ input: data });
      if (!result || errors.length > 0) {
        throw new Error(errors[0].message);
      }
      navigate('/login');
    },
    [forgotPassword, navigate]
  );

  return (
    <>
      <TopNav />
      <ForgotForm handleForgot={handleForgot} isLoading={isLoading} />
    </>
  );
};

ForgotContainer.displayName = 'ForgotContainer';

export default ForgotContainer;
