import { css } from '@emotion/css';

import { Colors, Spacing } from 'apps/shared/scripts/styles/constants';

export const backButtonClassName = css({
  position: 'absolute',
  top: Spacing.LG,
  left: Spacing.LG,
});

export const buttonClassName = css({
  background: 'none',
  padding: 0,
});

export const iconClassName = css({
  color: Colors.Secondary,
  height: 24,
  width: 24,
  '&:hover': {
    color: Colors.Primary,
  }
});
