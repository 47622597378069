import { ApolloQueryResult, QueryResult } from '@apollo/client';
import { useState } from 'react';
import { useInView } from 'react-cool-inview';

import { PaginationInput } from '../../__generated__/graphql.generated';
import Spinner from '../Spinner';

import { iconClassName, spinnerClassName } from './styles';

interface Props<T> {
  cursor: string;
  fetchMore: ({
    variables: { after },
  }: {
    variables: PaginationInput;
  }) => Promise<ApolloQueryResult<T>>;
  hasNextPage: boolean;
}

const InfiniteLoader = <T extends QueryResult>({
  cursor,
  fetchMore,
  hasNextPage,
}: Props<T>) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );

  const { observe: loadMoreRef } = useInView({
    rootMargin: `${vh}px 0px`,
    onEnter: async ({ observe, unobserve }) => {
      setLoading(true);
      unobserve();
      if (hasNextPage) {
        await fetchMore({ variables: { after: cursor } });
      }
      observe();
      setLoading(false);
    },
  });

  return (
    <>
      {isLoading && (
        <Spinner className={spinnerClassName} iconClassName={iconClassName} />
      )}
      <div ref={loadMoreRef} />
    </>
  );
};

InfiniteLoader.displayName = 'InfiniteLoader';

export default InfiniteLoader;
