import { createSelector } from '@reduxjs/toolkit';

import { ModalTypes } from './constants';
import { RootState } from './store';

const selectModalData = (state: RootState) => state.modal.data;
const selectModalIsVisible = (state: RootState) => state.modal.isVisible;
export const selectModalType = (state: RootState) => state.modal.type;

export const selectIsPhotoModalVisible = createSelector(
  selectModalIsVisible,
  selectModalType,
  (isVisible, type) => isVisible && type === ModalTypes.Photo
);

export const selectIsUploadModalVisible = createSelector(
  selectModalIsVisible,
  selectModalType,
  (isVisible, type) => isVisible && (type === ModalTypes.Upload || type === ModalTypes.Edit)
);

export const modalDataIdSelector = createSelector(
  selectModalData,
  (data) => data.id
);

export const modalDataSelector = createSelector(
  selectModalData,
  (data) => data
);
