import { gql } from '@apollo/client';

import { UserFragment, UserFragmentDoc } from '../../fragments';
import { generateQueryHook } from '../../utils';

import {
  UserQueryResult,
  UserQueryVariables,
  useUserQuery,
} from './useUser.generated';

gql`
  query UserQuery {
    user {
      ...UserFragment
    }
  }
  ${UserFragmentDoc}
`;

interface HookDataToOutputReturn {
  user?: UserFragment;
}

const DEFAULT_RETURN: HookDataToOutputReturn = {
  user: undefined,
};

const hookDataToOutput = (
  data?: UserQueryResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    user: undefined,
  };

  if (data?.user) {
    toReturn.user = data.user;
  }

  return toReturn;
};

const useUser = generateQueryHook<
  UserQueryResult,
  UserQueryVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useUserQuery, DEFAULT_RETURN);

export default useUser;
