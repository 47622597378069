import { IconLookup, IconName } from '@fortawesome/fontawesome-common-types';
import React, { ReactElement, ReactNode } from 'react';

export enum ButtonTypes {
  Button = 'button',
  Submit = 'submit',
}

export interface ButtonProps {
  bounce?: boolean;
  children?: ReactNode | string | string[];
  className?: string;
  disabled?: boolean;
  faIcon?: IconLookup | IconName;
  fullWidth?: boolean;
  iconAft?: boolean;
  iconClassName?: string;
  loading?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  success?: boolean;
  svgIcon?: ReactElement | boolean;
  style?: React.CSSProperties;
  textClassName?: string;
  type?: ButtonTypes;
}
