import { Footer, TopNav } from 'apps/shared/scripts/components';
import { UploadModalContainer } from 'apps/upload/scripts/containers';

import { CtaBanner, LandingHero, PhotoRow } from '../../components';

const LandingContainer = () => {
  return (
    <>
      <TopNav />
      <LandingHero />
      <PhotoRow
        title="Upload high resolution photos"
        description="Say goodbye to pixelated photos, image size restrictions, and moderator approvals. You're in control of what gets uploaded, anytime you want."
        src="https://res.cloudinary.com/dutl6xlom/image/upload/v1701635537/s6lswctacsg6diellej6.png"
      />
      <PhotoRow
        title="Tag your catches"
        description="Keep track of your catches with metadata tags including airport location, date taken, airline name, aircraft type, and more."
        src="https://res.cloudinary.com/dutl6xlom/image/upload/v1701676860/ljbixo9x2fotozfh55ji.png"
        rtl
      />
      <PhotoRow
        title="Share with the world"
        description="Easily share your link with friends and family. Or, add it to your social media profile and let followers view full size versions of your photos."
        src="https://res.cloudinary.com/dutl6xlom/image/upload/v1701676747/szeazo9grss0msecx6px.png"
      />
      <CtaBanner />
      <Footer />
      <UploadModalContainer />
    </>
  );
};

LandingContainer.displayName = 'LandingContainer';

export default LandingContainer;
