import { useIsAuthenticated } from 'apps/shared/scripts/hooks';

import AccountDropdown from './AccountDropdown';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import { rightContentClassName } from './styles';
import UploadButton from './UploadButton';

const RightContent = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className={rightContentClassName}>
      {isAuthenticated ? (
        <>
          <UploadButton />
          <AccountDropdown />
        </>
      ) : (
        <>
          <LoginButton />
          <SignupButton />
        </>
      )}
    </div>
  );
};

RightContent.displayName = 'RightContent';

export default RightContent;
