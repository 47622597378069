import { cx } from '@emotion/css';
import { faCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import Icon from './Icon';
import { superIconClassName } from './styles';
import { ButtonProps } from './types';

type Props = Pick<ButtonProps, 'iconClassName' | 'loading' | 'success'>;

const SuperIcon = ({ iconClassName, loading, success }: Props) => {
  if (loading || success) {
    return (
      <span className={cx(superIconClassName, 'super-icon')}>
        <Icon
          className={iconClassName}
          fa={loading ? faCircleNotch : faCheck}
        />
      </span>
    );
  }
  return null;
};

SuperIcon.displayName = 'SuperIcon';

export default SuperIcon;
