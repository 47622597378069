import { faImage } from '@fortawesome/free-regular-svg-icons';

import { Icon } from '../../components';

import { emptyPlaceholderClassName, iconClassName, labelClassName } from './styles';

const EmptyPlaceholder = () => {
  return (
    <div className={emptyPlaceholderClassName}>
      <Icon className={iconClassName} fa={faImage} />
      <span className={labelClassName}>No posts found</span>
    </div>
  );
};

EmptyPlaceholder.displayName = 'EmptyPlaceholder';

export default EmptyPlaceholder;
