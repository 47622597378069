import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { useCreatePost } from 'apps/shared/scripts/graphql';
import { PostsByUsernameQueryDocument } from 'apps/shared/scripts/graphql/hooks/usePostsByUsername/usePostsByUsername.generated';
import { useFormAsync, usePutSignedUrl } from 'apps/shared/scripts/hooks';

import { FormValues } from './types';
import UploadForm from './UploadForm';
import { toInput } from './utils';

interface Props {
  onSuccess: () => void;
}

const UploadFormCreateContainer = ({ onSuccess: onSuccessCallback }: Props) => {
  const client = useApolloClient();
  const { mutate: onCreate } = useCreatePost({
    awaitRefetchQueries: true,
  });
  const { putSignedUrl } = usePutSignedUrl();
  const { isLoading, onStart, onFail, onSuccess } = useFormAsync();

  const handleSave = useCallback(
    async (data: FormValues, file: File) => {
      onStart();
      const input = toInput(data);
      const { result, errors } = await onCreate({ input });
      if (!result || errors.length > 0) {
        onFail();
        throw new Error('Unable to create post');
      }
      try {
        await putSignedUrl(result.signedUrl, file);
      } catch (e) {
        if (e instanceof Error) {
          onFail();
          throw new Error('Unable to upload post');
        }
      }
      await client.refetchQueries({
        include: [PostsByUsernameQueryDocument],
      });
      onSuccess(onSuccessCallback);
    },
    [
      client,
      onCreate,
      onFail,
      onStart,
      onSuccess,
      onSuccessCallback,
      putSignedUrl,
    ]
  );

  return <UploadForm handleSave={handleSave} isSaving={isLoading} />;
};

UploadFormCreateContainer.displayName = 'UploadFormCreateContainer';

export default UploadFormCreateContainer;
