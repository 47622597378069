import { gql } from '@apollo/client';

import { generateMutationHook } from '../../utils';

import {
  useVerifyEmailMutation,
  VerifyEmailMutationResult,
  VerifyEmailMutationVariables,
} from './useVerifyEmail.generated';

gql`
  mutation VerifyEmailMutation($input: EmailVerificationInput!) {
    verifyEmail(input: $input) {
      _empty
    }
  }
`;

interface HookDataToOutputReturn {
  result?: boolean;
}

const hookDataToOutput = (
  data?: VerifyEmailMutationResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    result: undefined,
  };

  if (data?.verifyEmail.__typename === 'EmptyResponse') {
    toReturn.result = true;
  }

  return toReturn;
};

const useVerifyEmail = generateMutationHook<
  VerifyEmailMutationResult,
  VerifyEmailMutationVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useVerifyEmailMutation);

export default useVerifyEmail;
