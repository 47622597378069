import { css, keyframes } from '@emotion/css';

import { Spacing } from '../../styles/constants';

const spin = keyframes({
  '100%': { transform: 'rotate(360deg)' },
  '75%': { transform: 'rotate(300deg)' },
  '50%': { transform: 'rotate(240deg)' },
  '25%': { transform: 'rotate(90deg)' },
  '0%': { transform: 'rotate(0deg)' },
});

export const buttonClassName = css({
  '--button-icon-size': '16px',
  alignItems: 'center',
  background: 'transparent',
  border: 'none',
  borderRadius: 4,
  color: 'inherit',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  padding: '0.75rem 1rem',
  position: 'relative',
  transition: '0.2s',
  whiteSpace: 'nowrap',
});

export const bounceClassName = css({
  touchAction: 'manipulation',
  transition:
    '-ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s',
  '&:active': {
    transform: 'scale(0.92)',
  },
});

export const contentClassName = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
});

export const hiddenClassName = css({
  visibility: 'hidden',
});

export const disabledClassName = css({
  cursor: 'default',
  touchAction: 'none',
  transition: 'none',
  '&:active': {
    transform: 'none',
  },
});

export const fullWidthClassName = css({
  width: '100%',
});

export const loadingClassName = css({
  '--button-icon-size': '18px',
  cursor: 'default',
  '.super-icon': {
    animationName: spin,
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    lineHeight: 0,
    i: {
      height: 'var(--button-icon-size)',
      width: 'var(--button-icon-size)',
    },
  },
});

export const successClassName = css({
  '--button-icon-size': '18px',
  cursor: 'default',
  '.super-icon > i': {
    height: 'var(--button-icon-size)',
    width: 'var(--button-icon-size)',
  },
});

export const iconClassName = css({
  display: 'block',
  lineHeight: 0,
  '& > svg': {
    color: 'inherit',
    height: 'inherit',
    width: 'inherit',
    transition: '0.2s',
  },
});

export const preClassName = css({
  marginRight: Spacing.XS,
});

export const aftClassName = css({
  marginLeft: Spacing.XS,
});

export const textClassName = css({
  color: 'inherit',
  display: 'block',
  fontSize: 'inherit',
  fontWeight: 'inherit',
});

export const superIconClassName = css({
  left: '50%',
  marginLeft: 'calc((var(--button-icon-size) / 2) * -1)',
  marginTop: 'calc((var(--button-icon-size) / 2) * -1)',
  position: 'absolute',
  top: '50%',
});
