import { UpdateUserInput } from 'apps/shared/scripts/__generated__/graphql.generated';
import { UserFragment } from 'apps/shared/scripts/graphql';

import { DEFAULT_VALUES } from './constants';
import { FormValues } from './types';

export const toInput = (data: FormValues): UpdateUserInput => {
  const { email, name, photo, username } = data;

  const input: Mutable<UpdateUserInput> = {};

  if (email) {
    input.email = data.email;
  }

  if (photo || photo === '') {
    input.profileImageUrl = data.photo;
  }

  if (name) {
    input.name = data.name;
  }

  if (username) {
    input.username = data.username;
  }

  return input;
};

export const toFormValues = (user: UserFragment): FormValues => {
  const { email, name, profileImageUrl, username } = user;

  const formValues: FormValues = { ...DEFAULT_VALUES };

  if (email) {
    formValues.email = email;
  }

  if (name) {
    formValues.name = name;
  }

  if (profileImageUrl) {
    formValues.photo = profileImageUrl;
  }

  if (username) {
    formValues.username = username;
  }

  return formValues;
};
