import { Dismiss } from 'apps/shared/scripts/components/Modal';
import { useIsAuthenticated, useIsPostOwner } from 'apps/shared/scripts/hooks';

import HeadActions from './HeadActions';
import HeadProfile from './HeadProfile';
import { headClassName, innerClassName } from './styles';

interface Props {
  postId: string;
}

const Head = ({ postId }: Props) => {
  const isAuthenticated = useIsAuthenticated();
  const { isPostOwner } = useIsPostOwner({ postId });

  return (
    <div className={headClassName}>
      <div className={innerClassName}>
        <Dismiss mobile />
        <HeadProfile />
        {isAuthenticated && isPostOwner && <HeadActions postId={postId} />}
      </div>
    </div>
  );
};

Head.displayName = 'Head';

export default Head;
