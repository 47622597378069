import { css } from '@emotion/css';

import { Colors, FontSize, FontWeight, Spacing } from 'apps/shared/scripts/styles/constants';

export const sectionClassName = css({
});

export const headerClassName = css({

});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.SEMI_BOLD,
});

export const subheadingClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.MEDIUM,
  marginTop: Spacing.XS,
});

export const bodyClassName = css({
  marginTop: Spacing.MD,
});
