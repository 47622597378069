import { cx } from '@emotion/css';

import { iconClassName } from './styles';
import { IconProps } from './types';
import { faToSvg } from './utils';

type Props = IconProps;

const Icon = ({ className = '', emoji, fa, svg }: Props) => {
  return (
    <i
      className={cx(iconClassName, {
        [className]: !!className,
      })}
    >
      {emoji && emoji}
      {fa && faToSvg(fa)}
      {svg && svg}
    </i>
  );
};

Icon.displayName = 'Icon';

export default Icon;
