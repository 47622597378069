import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useUser } from 'apps/shared/scripts/graphql';
import { ModalTypes, openModal } from 'apps/shared/scripts/redux';

import Button from '../../Button';

import { uploadButtonClassName } from './styles';

const UploadButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useUser();

  const onUpload = useCallback(() => {
    if (!user!.isVerified) {
      navigate(`/verify?r=${encodeURIComponent(window.location.href)}`);
    } else {
      dispatch(openModal({ type: ModalTypes.Upload }));
    }
  }, [dispatch, navigate, user]);

  return (
    <Button className={uploadButtonClassName} onClick={onUpload}>
      Upload Photo
    </Button>
  );
};

UploadButton.displayName = 'UploadButton';

export default UploadButton;
