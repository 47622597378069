import { useState } from 'react';
import { Container } from 'react-bootstrap';

import { Footer, Markdown, TopNav } from 'apps/shared/scripts/components';

import Privacy from './Privacy.md';

const PrivacyContainer = () => {
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <>
      <TopNav />
      <Container>
        <Markdown onLoad={() => setLoaded(true)} file={Privacy} />
      </Container>
      {/* Flash of Unstyled Text */}
      {loaded && <Footer />}
    </>
  );
};

PrivacyContainer.displayName = 'PrivacyContainer';

export default PrivacyContainer;
