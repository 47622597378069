import { FormValues } from './types';

export const FORM_FIELDS = {
  CURRENT_PASSWORD: {
    name: 'currentPassword',
    label: 'Current Password',
  } as const,
  NEW_PASSWORD: {
    name: 'newPassword',
    label: 'New Password',
  } as const,
  CONFIRM_PASSWORD: {
    name: 'confirmPassword',
    label: 'Confirm Password',
  } as const,
};

export const DEFAULT_VALUES: FormValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};
