import { CreateOAuthUserInput, CreateUserInput } from 'apps/shared/scripts/__generated__/graphql.generated';

import { FormValues } from './SignupForm';

export const toInput = (data: FormValues): CreateUserInput => {
  const { email, firstName, lastName, password, username } = data;
  const input: Mutable<CreateUserInput> = {
    email: '',
    name: '',
    password: '',
    username: '',
  };

  if (email) {
    input.email = email;
  }

  if (firstName && lastName) {
    input.name = `${firstName} ${lastName}`;
  }

  if (password) {
    input.password = password;
  }

  if (username) {
    input.username = username;
  }

  return input;
};

export const toOAuthInput = (idToken: string, username: string): CreateOAuthUserInput => {
  const input: CreateOAuthUserInput = {
    idToken,
    username,
  };

  return input;
};