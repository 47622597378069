import { RefObject, useEffect } from 'react';

const useInsideClick = (
  targetRef: RefObject<HTMLDivElement>,
  callback: (...args: unknown[]) => unknown
) => {
  useEffect(() => {
    function handleClickInside(event: MouseEvent) {
      if (targetRef.current === event.target) {
        return callback();
      }
    }
    document.addEventListener('mouseup', handleClickInside);
    return () => {
      document.removeEventListener('mouseup', handleClickInside);
    };
  }, [callback, targetRef]);
};

export default useInsideClick;
