import { gql } from '@apollo/client';

import { ImageUpload } from 'apps/shared/scripts/__generated__/graphql.generated';

import { generateMutationHook } from '../../utils';

import {
  UpdateProfilePhotoMutationResult,
  UpdateProfilePhotoMutationVariables,
  useUpdateProfilePhotoMutation,
} from './useUpdateProfilePhoto.generated';

gql`
  mutation UpdateProfilePhoto($input: ImageUploadInput!) {
    updateProfilePhoto(input: $input) {
      ... on EmptyResponse {
        _empty
      }
      ... on ImageUpload {
        publicUrl
        signedUrl
      }
    }
  }
`;

interface HookDataToOutputReturn {
  result?: boolean | ImageUpload;
}

const hookDataToOutput = (
  data?: UpdateProfilePhotoMutationResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    result: undefined,
  };

  if (data?.updateProfilePhoto) {
    if (data.updateProfilePhoto.__typename === 'EmptyResponse') {
      toReturn.result = true;
    }
    if (data.updateProfilePhoto.__typename === 'ImageUpload') {
      toReturn.result = data.updateProfilePhoto;
    }
  }

  return toReturn;
};

const useUpdateProfilePhoto = generateMutationHook<
  UpdateProfilePhotoMutationResult,
  UpdateProfilePhotoMutationVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useUpdateProfilePhotoMutation);

export default useUpdateProfilePhoto;
