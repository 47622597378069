import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Collapsible, Input } from 'apps/shared/scripts/components';
import { useToast, useYupValidation } from 'apps/shared/scripts/hooks';

import { ProfilePhoto, VerifiedBadge } from '../../components';

import { DEFAULT_VALUES, FORM_FIELDS } from './constants';
import { FormValues } from './types';
import { schema } from './validation';

export interface Props {
  defaultValues?: FormValues;
  handleSave: (data: FormValues, upload: File | null) => Promise<void>;
  isSaving: boolean;
}

const AccountForm = ({
  defaultValues = DEFAULT_VALUES,
  handleSave,
  isSaving,
}: Props) => {
  const {
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues,
    resolver: useYupValidation<FormValues>(schema),
  });
  const [active, setActive] = useState<string>('');
  const [upload, setUpload] = useState<File | null>(null);
  const { email, name, photo, username } = watch();
  const toast = useToast();

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data) => {
      try {
        await handleSave(data, upload);
        reset(data);
        setActive('');
        toast.success(`Success! Your ${active} was updated`);
        if (active === FORM_FIELDS.EMAIL.name) {
          setTimeout(() => navigate('/logout', { replace: true }), 1000);
        }
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }
    },
    [active, handleSave, navigate, reset, toast, upload]
  );

  const onCancel = useCallback(async () => {
    reset();
    setActive('');
    setUpload(null);
  }, [reset]);

  const onEdit = useCallback(async (field: string) => {
    setActive(field);
  }, []);

  const onUploadPhoto = useCallback(
    (photo: File | null, url: string) => {
      setUpload(photo);
      setValue(FORM_FIELDS.PHOTO.name, url, { shouldDirty: true });
    },
    [setValue]
  );

  const onRemovePhoto = useCallback(() => {
    setUpload(null);
    setValue(FORM_FIELDS.PHOTO.name, '', { shouldDirty: true });
  }, [setValue]);

  const onSave = handleSubmit(onSubmit);

  return (
    <form onSubmit={onSave}>
      <Collapsible
        error={errors[FORM_FIELDS.NAME.name]}
        isActive={active === FORM_FIELDS.NAME.name}
        isDirty={FORM_FIELDS.NAME.name in dirtyFields}
        isDisabled={!!active && active !== FORM_FIELDS.NAME.name}
        isSaving={isSaving}
        label={FORM_FIELDS.NAME.label}
        onCancel={onCancel}
        onEdit={() => onEdit(FORM_FIELDS.NAME.name)}
        onSave={onSave}
        placeholder={name}
      >
        <Input control={control} name={FORM_FIELDS.NAME.name} />
      </Collapsible>
      <Collapsible
        description="You will need to login again after changing emails."
        error={errors[FORM_FIELDS.EMAIL.name]}
        isActive={active === FORM_FIELDS.EMAIL.name}
        isDirty={FORM_FIELDS.EMAIL.name in dirtyFields}
        isDisabled={!!active && active !== FORM_FIELDS.EMAIL.name}
        isSaving={isSaving}
        label={FORM_FIELDS.EMAIL.label}
        onCancel={onCancel}
        onEdit={() => onEdit(FORM_FIELDS.EMAIL.name)}
        onSave={onSave}
        placeholder={<VerifiedBadge email={email} />}
      >
        <Input control={control} name={FORM_FIELDS.EMAIL.name} />
      </Collapsible>
      <Collapsible
        error={errors[FORM_FIELDS.USERNAME.name]}
        isActive={active === FORM_FIELDS.USERNAME.name}
        isDirty={FORM_FIELDS.USERNAME.name in dirtyFields}
        isDisabled={!!active && active !== FORM_FIELDS.USERNAME.name}
        isSaving={isSaving}
        label={FORM_FIELDS.USERNAME.label}
        onCancel={onCancel}
        onEdit={() => onEdit(FORM_FIELDS.USERNAME.name)}
        onSave={onSave}
        placeholder={username}
      >
        <Input control={control} name={FORM_FIELDS.USERNAME.name} />
      </Collapsible>
      <Collapsible
        error={errors[FORM_FIELDS.PHOTO.name]}
        isActive={active === FORM_FIELDS.PHOTO.name}
        isDirty={FORM_FIELDS.PHOTO.name in dirtyFields}
        isDisabled={!!active && active !== FORM_FIELDS.PHOTO.name}
        isSaving={isSaving}
        label={FORM_FIELDS.PHOTO.label}
        onCancel={onCancel}
        onEdit={() => onEdit(FORM_FIELDS.PHOTO.name)}
        onSave={onSave}
        placeholder={<ProfilePhoto src={photo} />}
      >
        <ProfilePhoto
          src={photo}
          editMode
          onUploadPhoto={onUploadPhoto}
          onRemovePhoto={onRemovePhoto}
        />
      </Collapsible>
    </form>
  );
};

AccountForm.displayName = 'AccountForm';

export default AccountForm;
