import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../Button';

import { loginButtonClassName } from './styles';

const LoginButton = () => {
  const navigate = useNavigate();

  const onLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  return (
    <Button className={loginButtonClassName} onClick={onLogin}>
      Log In
    </Button>
  );
};

LoginButton.displayName = 'LoginButton';

export default LoginButton;
