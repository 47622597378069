import { centerContentClassName } from './styles';

const CenterContent = () => {
  return (
    <div className={centerContentClassName}>
    </div>
  );
};

CenterContent.displayName = 'CenterContent';

export default CenterContent;
