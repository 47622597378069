import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  brandClassName,
  footerClassName,
  groupClassName,
  headingClassName,
  itemClassName,
  labelClassName,
  legalClassName,
  linkClassName,
  listClassName,
  noteClassName,
  textClassName,
} from './styles';

const Footer = () => {
  return (
    <div className={footerClassName}>
      <Container>
        <Row>
          <Col xs={12} lg={4} style={{ marginRight: 'auto' }}>
            <div className={brandClassName}>
              <div className={textClassName}>flytepost</div>
            </div>
            <div className={legalClassName}>
              <div className={noteClassName}>&copy; 2024 Flytepost</div>
            </div>
          </Col>
          <Col xs={6} lg={2}>
            <div className={groupClassName}>
              <span className={headingClassName}>Product</span>
              <div className={listClassName}>
                <div className={itemClassName}>
                  <Link className={linkClassName} to={'/'}>
                    <span className={labelClassName}>Overview</span>
                  </Link>
                </div>
                <div className={itemClassName}>
                  <Link className={linkClassName} to={'/faq'}>
                    <span className={labelClassName}>FAQs</span>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={6} lg={2}>
            <div className={groupClassName}>
              <span className={headingClassName}>Support</span>
              <div className={listClassName}>
                <div className={itemClassName}>
                  <Link
                    className={linkClassName}
                    to={
                      'https://drive.google.com/drive/folders/1DgUbs7KuvmvwAkdDfhzGvXI6C6eF5KHG'
                    }
                  >
                    <span className={labelClassName}>Press kit</span>
                  </Link>
                </div>
                <div className={itemClassName}>
                  <Link
                    className={linkClassName}
                    to={'mailto:hello@flytepost.com'}
                  >
                    <span className={labelClassName}>Contact us</span>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={6} lg={2}>
            <div className={groupClassName}>
              <span className={headingClassName}>Resources</span>
              <div className={listClassName}>
                <div className={itemClassName}>
                  <Link className={linkClassName} to={'/terms'}>
                    <span className={labelClassName}>Terms & conditions</span>
                  </Link>
                </div>
                <div className={itemClassName}>
                  <Link className={linkClassName} to={'/privacy'}>
                    <span className={labelClassName}>Privacy policy</span>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Footer.displayName = 'Footer';

export default Footer;
