import { headerClassName, headingClassName } from './styles';

const Header = () => {
  return (
    <div className={headerClassName}>
      <h2 className={headingClassName}>Settings</h2>
    </div>
  );
};

Header.displayName = 'Header';

export default Header;
