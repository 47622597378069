import Skeleton from 'react-loading-skeleton';

import { Dropdown, DropdownItem, Image } from 'apps/shared/scripts/components';
import { useUser } from 'apps/shared/scripts/graphql';
import { useRedirect } from 'apps/shared/scripts/hooks';

import { imageClassName, menuClassName, skeletonClassName } from './styles';

const AccountDropdown = () => {
  const { isLoading, user } = useUser();

  const redirect = useRedirect();

  const image = user?.profileImageUrl
    ? user.profileImageUrl
    : 'https://res.cloudinary.com/dutl6xlom/image/upload/v1698626088/5976702cffb33336cf827d95_gzvtxl.jpg';

  return (
    <Dropdown
      anchor={
        isLoading ? <Skeleton className={skeletonClassName} /> : (
          <Image className={imageClassName} src={image} scaled />
        )
      }
      loading={isLoading}
      menuClassName={menuClassName}
    >
      <DropdownItem onClick={() => redirect(`/${user!.username}`)}>
        Profile
      </DropdownItem>
      <DropdownItem onClick={() => redirect('/settings')}>
        Settings
      </DropdownItem>
      <DropdownItem onClick={() => redirect('/logout')}>Log out</DropdownItem>
    </Dropdown>
  );
};

AccountDropdown.displayName = 'AccountDropdown';

export default AccountDropdown;
