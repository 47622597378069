import { Image } from 'apps/shared/scripts/components';

import { imageClassName, mediaClassName } from './styles';

export interface Props {
  src: string;
}

const PhotoRowMedia = ({ src }: Props) => {
  return (
    <div className={mediaClassName}>
      <Image className={imageClassName} src={src} />
    </div>
  );
};

PhotoRowMedia.displayName = 'PhotoRowMedia';

export default PhotoRowMedia;
