import { css } from '@emotion/css';

import {
  Breakpoints,
  Colors,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from '../../styles/constants';

export const footerClassName = css({
  borderTop: `1px solid rgb(233, 233, 233)`,
  paddingTop: 62,
  paddingBottom: 92,
});

export const brandClassName = css({});

export const textClassName = css({
  color: Colors.Primary,
  fontSize: 20,
  fontWeight: FontWeight.SEMI_BOLD,
});

export const legalClassName = css({
  marginTop: Spacing.XS,
});

export const noteClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.NORMAL,
});

export const groupClassName = css({
  marginTop: 36,
  [`@media(min-width: ${Breakpoints.LG}px)`]: {
    marginTop: 0,
  },
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.SEMI_BOLD,
  lineHeight: LineHeight.EXTRA_LARGE,
});

export const listClassName = css({
  display: 'flex',
  flexDirection: 'column',
});

export const linkClassName = css({
  marginTop: Spacing.SM,
  textDecoration: 'none',
});

export const itemClassName = css({
  display: 'flex',
});

export const labelClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  lineHeight: LineHeight.LARGE,
  '&:hover': {
    opacity: 0.9,
  },
});
