import { cx } from '@emotion/css';

import { Button } from 'apps/shared/scripts/components';

import {
  dismissButtonClassName,
  dismissButtonFixedClassName,
  dismissClassName,
  dismissFixedClassName,
} from './styles';
import { ReactComponent as TimesIconSvg } from './times.svg';

interface Props {
  fixed?: boolean;
  onClick: () => void;
}

const DismissButton = ({ fixed, onClick }: Props) => {
  return (
    <div className={cx(dismissClassName, { [dismissFixedClassName]: fixed })}>
      <Button
        className={cx(dismissButtonClassName, {
          [dismissButtonFixedClassName]: fixed,
        })}
        svgIcon={<TimesIconSvg />}
        onClick={onClick}
        bounce
      />
    </div>
  );
};

DismissButton.displayName = 'DismissButton';

export default DismissButton;
