import { ApolloQueryResult, QueryResult } from '@apollo/client';
import findIndex from 'lodash/findIndex';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PaginationInput, Post } from '../../__generated__/graphql.generated';
import { updateModal } from '../../redux';
import { modalDataIdSelector } from '../../redux/selectors';

interface Options<T> {
  cursor: string;
  fetchMore: ({
    variables: { after },
  }: {
    variables: PaginationInput;
  }) => Promise<ApolloQueryResult<T>>;
  hasNextPage: boolean;
  posts: Post[];
}

export const FETCH_MORE_OFFSET = 3;

const useInfiniteLoad = <T extends QueryResult>({
  cursor,
  fetchMore,
  hasNextPage,
  posts,
}: Options<T>) => {
  const dispatch = useDispatch();
  const postId = useSelector(modalDataIdSelector);

  useEffect(() => {
    // handle case when last item in gallery selected and fetchMore is called
    // => enable "next" modal control
    if (posts.length > 0) {
      dispatch(updateModal({ lastUpdated: Date.now() }));
    }
  }, [dispatch, posts.length]);

  if (!postId || !posts.length) {
    return;
  }

  const activeIndex = findIndex(posts, { id: postId });

  if (hasNextPage && activeIndex >= posts.length - FETCH_MORE_OFFSET) {
    fetchMore({ variables: { after: cursor } });
  }
};

export default useInfiniteLoad;
