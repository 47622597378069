import { Dismiss } from 'apps/shared/scripts/components/Modal';

import {
  actionClassName,
  headerClassName,
  headingClassName,
  uploadHeadClassName,
} from './styles';

interface Props {
  isEditMode?: boolean;
}

const UploadHead = ({ isEditMode }: Props) => {
  return (
    <div className={uploadHeadClassName}>
      <div className={headerClassName}>
        <h2 className={headingClassName}>
          {isEditMode ? 'Edit Post' : 'Upload a Photo'}
        </h2>
        <div className={actionClassName}>
          <Dismiss />
        </div>
      </div>
    </div>
  );
};

UploadHead.displayName = 'UploadHead';

export default UploadHead;
