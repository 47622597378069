import { css } from '@emotion/css';

import {
  Breakpoints,
  Colors,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const headClassName = css({
  background: Colors.White,
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 9,
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    borderRadius: '4px 4px 0 0',
  },
});

export const innerClassName = css({
  alignItems: 'center',
  display: 'flex',
  height: 72,
  padding: Spacing.MD,
  '@media (min-width: 768px)': {
    padding: `${Spacing.MD}px ${Spacing.LG}px`,
  },
});

export const imageClassName = css({
  borderRadius: 100,
  height: 36,
  width: 36,

  '.image-inner': {
    paddingBottom: '100%',
  },
});

export const headProfileClassName = css({
  alignItems: 'center',
  display: 'flex',
});

export const textClassName = css({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: Spacing.XS,
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.BOLD,
  lineHeight: LineHeight.REGULAR,
});

export const subheadingClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.MEDIUM,
  lineHeight: LineHeight.COMPACT,
});

export const headActionsClassName = css({
  marginLeft: 'auto',
});

export const anchorClassName = css({
  '&:hover, &:active': {
    '@media (min-width: 768px)': {
      '&::before': {
        background: 'rgba(108, 119, 134, 0.1)',
      },
    },
  },
  '&::before': {
    content: '""',
    display: 'block',
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(calc(-50% - 0.5px), calc(-50% - 0.5px))',
    transition: '0.2s',
    height: 32,
    width: 32,
  },
  '&:active': {
    transform: 'scale(0.96)',
  },
});

export const activeClassName = css({
  '&::before': {
    background: 'rgba(108, 119, 134, 0.1)',
  },
});

export const iconClassName = css({
  color: Colors.Secondary,
  height: 18,
  width: 18,
});
