import { css } from '@emotion/css';

import {
  Breakpoints,
  Colors,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const ctaBannerClassName = css({
  marginBottom: 72,
  paddingTop: 24,
  [`@media(min-width: ${Breakpoints.LG}px)`]: {
    marginBottom: 78,
    paddingTop: 72,
  },
});

export const headerClassName = css({
  marginTop: Spacing.LG,
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: 40,
  fontWeight: FontWeight.SEMI_BOLD,
  lineHeight: LineHeight.COMPACT,
});

export const subheadingClassName = css({
  color: Colors.Secondary,
  fontSize: 20,
  fontWeight: FontWeight.MEDIUM,
  lineHeight: LineHeight.EXTRA_LARGE,
  marginTop: Spacing.LG,
});

export const bodyClassName = css({
  alignItems: 'center',
  display: 'flex',
  marginTop: Spacing['2XL'],
});

export const buttonClassName = css({
  background: Colors.Primary,
  borderRadius: 6,
  color: Colors.White,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.SEMI_BOLD,
  minHeight: 52,
  padding: `14px 16px`,
  '&:hover': {
    opacity: 0.9,
    i: {
      left: 2,
    },
  },
});

export const secondaryButtonClassName = css({
  border: `1px solid rgb(233, 233, 233)`,
  borderRadius: 6,
  color: Colors.Secondary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.SEMI_BOLD,
  marginLeft: Spacing.MD,
  minHeight: 52,
  padding: `14px 16px`,
  '&:hover': {
    i: {
      left: 2,
    },
  },
});

export const iconClassName = css({
  color: Colors.White,
  display: 'block',
  height: 16,
  left: 0,
  lineHeight: 0,
  position: 'relative',
  transition: '0.2s',
  width: 16,
});

export const secondaryIconClassName = css({
  color: Colors.Secondary,
  display: 'block',
  height: 16,
  left: 0,
  lineHeight: 0,
  position: 'relative',
  transition: '0.2s',
  width: 16,
});
