import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { markdownClassName } from './styles';

interface Props {
  file: string;
  onLoad?: () => void;
}

const Markdown = ({ file, onLoad }: Props) => {
  const [mdText, setMdText] = useState<string>('');

  useEffect(() => {
    fetch(file)
      .then((response) => {
        if (response.ok) {
          if (onLoad) {
            onLoad();
          }
          return response.text();
        } else {
          return Promise.reject("Didn't fetch file correctly");
        }
      })
      .then((text) => {
        setMdText(text);
      });
  }, [file, onLoad]);

  return <ReactMarkdown className={markdownClassName}>{mdText}</ReactMarkdown>;
};

Markdown.displayName = 'Markdown';

export default Markdown;
