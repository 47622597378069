import { gql } from '@apollo/client';

import { Profile } from 'apps/shared/scripts/__generated__/graphql.generated';

import { generateQueryHook } from '../../utils';

import {
  UserProfileQueryResult,
  UserProfileQueryVariables,
  useUserProfileQuery,
} from './useUserProfile.generated';

gql`
  query UserProfileQuery($username: String!) {
    userProfile(username: $username) {
      name
      profileImageUrl
      username
    }
  }
`;

interface HookDataToOutputReturn {
  profile?: Profile;
}

const DEFAULT_RETURN: HookDataToOutputReturn = {
  profile: undefined,
};

const hookDataToOutput = (
  data?: UserProfileQueryResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    profile: undefined,
  };

  if (data?.userProfile) {
    toReturn.profile = data.userProfile;
  }

  return toReturn;
};

const useUserProfile = generateQueryHook<
  UserProfileQueryResult,
  UserProfileQueryVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useUserProfileQuery, DEFAULT_RETURN);

export default useUserProfile;
