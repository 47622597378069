import { css } from '@emotion/css';

import { Breakpoints, Colors } from 'apps/shared/scripts/styles/constants';

export const cardClassName = css({
  background: Colors.White,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  width: '100vw',
  zIndex: 999,
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 25px',
    borderRadius: 12,
    height: 'auto',
    minHeight: 0,
    maxHeight: 'calc(100vh - 2rem)',
    maxWidth: 568,
    width: 'calc(100vw - 2rem)',
  },
});
