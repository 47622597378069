import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResetPasswordMutationVariables = Types.Exact<{
  input: Types.ResetPasswordInput;
}>;


export type ResetPasswordMutationResult = { readonly __typename: 'Mutation', readonly resetPassword: { readonly __typename: 'EmptyResponse', readonly _empty?: boolean | null } };


export const ResetPasswordMutationDocument = gql`
    mutation ResetPasswordMutation($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    _empty
  }
}
    `;
export type ResetPasswordMutationMutationFn = Apollo.MutationFunction<ResetPasswordMutationResult, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutationResult, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutationResult, ResetPasswordMutationVariables>(ResetPasswordMutationDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutationResult, ResetPasswordMutationVariables>;