import { cx } from '@emotion/css';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';

import { Button } from 'apps/shared/scripts/components';

import {
  activeIconClassName,
  bodyClassName,
  buttonClassName,
  collapsibleClassName,
  descriptionClassName,
  headerClassName,
  headingClassName,
  iconClassName,
} from './styles';

interface Props {
  description: string;
  title: string;
}

const Collapsible = ({ title, description }: Props) => {
  const [active, setActive] = useState<boolean>(false);

  const onClick = useCallback(() => {
    setActive(!active);
  }, [active]);

  return (
    <div className={collapsibleClassName} onClick={onClick} role="button">
      <div className={headerClassName}>
        <h2 className={headingClassName}>{title}</h2>
        <Button
          className={buttonClassName}
          iconClassName={cx(iconClassName, { [activeIconClassName]: active })}
          faIcon={faChevronDown}
        />
      </div>
      {active && (
        <div className={bodyClassName}>
          <p className={descriptionClassName}>
           {description}
          </p>
        </div>
      )}
    </div>
  );
};

Collapsible.displayName = 'Collapsible';

export default Collapsible;
