import { css } from '@emotion/css';

import { Colors, FontSize, FontWeight, LineHeight, Spacing } from '../../styles/constants';

export const markdownClassName = css({
  maxWidth: 850,
  margin: 'auto',
  paddingTop: Spacing['3XL'],
  paddingBottom: Spacing['3XL'],
  'h1': {
    color: Colors.Primary,
    fontSize: 28,
    fontWeight: FontWeight.SEMI_BOLD,
    lineHeight: LineHeight.LARGE,
    marginTop: Spacing.LG,
    marginBottom: Spacing.LG,
  },
  'h2': {
    color: Colors.Primary,
    fontSize: FontSize.LARGE,
    fontWeight: FontWeight.SEMI_BOLD,
    lineHeight: LineHeight.LARGE,
    marginTop: Spacing.XL,
    marginBottom: Spacing.LG,
  },
  'h3': {
    color: Colors.Primary,
    fontSize: FontSize.REGULAR,
    fontWeight: FontWeight.MEDIUM,
    lineHeight: LineHeight.LARGE,
    marginTop: Spacing.LG,
    marginBottom: Spacing.MD,
  },
  'p': {
    color: Colors.Primary,
    fontSize: FontSize.REGULAR,
    fontWeight: FontWeight.NORMAL,
    lineHeight: LineHeight.LARGE,
    marginBottom: Spacing.MD,
  },
  'a': {
    color: '#26A7DE',
  }
});
