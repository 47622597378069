import { cx } from '@emotion/css';
import { forwardRef, ReactNode } from 'react';

import { menuClassName } from './styles';

type Ref = HTMLDivElement;

interface Props {
  className?: string;
  children: ReactNode;
}

const Menu = forwardRef<Ref, Props>(({ className = '', children }, ref) => {
  return (
    <div className={cx(menuClassName, { [className]: !!className })} ref={ref}>
      {children}
    </div>
  );
});

Menu.displayName = 'Menu';

export default Menu;
