import { gql } from '@apollo/client';

import { generateQueryHook } from '../../utils';

import {
  AutocompleteAirlineQueryResult,
  AutocompleteAirlineQueryVariables,
  useAutocompleteAirlineQuery,
} from './useAutocompleteAirline.generated';

gql`
  query AutocompleteAirlineQuery($query: String) {
    autocompleteAirline(query: $query) {
      ... on Airline {
        iata
        icao
        name
      }
    }
  }
`;

interface Suggestion {
  label: string;
  tag?: string;
  value: string;
}

interface HookDataToOutputReturn {
  suggestions: Suggestion[];
}

const DEFAULT_RETURN: HookDataToOutputReturn = {
  suggestions: [],
};

const hookDataToOutput = (
  data?: AutocompleteAirlineQueryResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    suggestions: [],
  };

  if (data?.autocompleteAirline) {
    data.autocompleteAirline.forEach((result) => {
      const { iata, name } = result;
      toReturn.suggestions.push({
        label: name!,
        tag: iata!,
        value: name!,
      });
    });
  }

  return toReturn;
};

const useAutocompleteAirline = generateQueryHook<
  AutocompleteAirlineQueryResult,
  AutocompleteAirlineQueryVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useAutocompleteAirlineQuery, DEFAULT_RETURN);

export default useAutocompleteAirline;
