import {
  faMapMarkerAlt,
  faPlaneDeparture,
  faTicket,
} from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';

import { Section } from 'apps/shared/scripts/components/Modal';

import AircraftField from './AircraftField';
import AirlineField from './AirlineField';
import AirportField from './AirportField';
import FieldButton from './FieldButton';
import {
  bodyClassName,
  buttonGroupClassName,
  sectionClassName,
} from './styles';
import { Metadata } from './types';

interface Props<T extends FieldValues> {
  control: Control<T>;
  errors?: FieldErrors<T>;
}

const UploadMetadata = <T extends FieldValues>(props: Props<T>) => {
  const [active, setActive] = useState<Metadata | null>(null);
  const [prev, setPrev] = useState<Metadata | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const onToggle = useCallback(
    (tab: Metadata) => {
      setPrev(active);
      if (tab === active) {
        setActive(null);
      } else {
        setActive(tab);
      }
    },
    [active]
  );

  useEffect(() => {
    if (ref.current && active && prev === null) {
      ref.current.scrollIntoView();
    }
  }, [active, prev]);

  return (
    <Section className={sectionClassName}>
      <div className={buttonGroupClassName} ref={ref}>
        <FieldButton
          active={active}
          faIcon={faMapMarkerAlt}
          label="Airport"
          onToggle={onToggle}
          type={Metadata.AIRPORT}
        />
        <FieldButton
          active={active}
          faIcon={faTicket}
          label="Airline"
          onToggle={onToggle}
          type={Metadata.AIRLINE}
        />
        <FieldButton
          active={active}
          faIcon={faPlaneDeparture}
          label="Aircraft"
          onToggle={onToggle}
          type={Metadata.AIRCRAFT}
        />
      </div>
      <div className={bodyClassName}>
        {active === Metadata.AIRPORT && <AirportField {...props} />}
        {active === Metadata.AIRLINE && <AirlineField {...props} />}
        {active === Metadata.AIRCRAFT && <AircraftField {...props} />}
      </div>
    </Section>
  );
};

UploadMetadata.displayName = 'UploadMetadata';

export default UploadMetadata;
