import { cx } from '@emotion/css';
import { FC } from 'react';

import Button, { ButtonTypes } from '../../Button';
import Error from '../../Error';

import {
  bodyClassName,
  buttonPrimaryClassName,
  buttonSecondaryClassName,
  collapsibleClassName,
  dangerClassName,
  descriptionClassName,
  disabledPrimaryButtonClassName,
  disabledSecondaryButtonClassName,
  errorClassName,
  footerClassName,
  headClassName,
  headerClassName,
  headingClassName,
  rightHeaderClassName,
  subheaderClassName,
} from './styles';
import { CollapsibleProps } from './types';

const Collapsible: FC<CollapsibleProps> = ({
  description,
  error,
  isActive,
  isDirty,
  isDisabled,
  isSaving,
  children,
  className = '',
  label,
  onCancel,
  onEdit,
  onSave,
  placeholder,
}) => {
  return (
    <div className={cx(collapsibleClassName, { [className]: !!className })}>
      <div className={headClassName}>
        <div className={headerClassName}>
          <span
            className={cx(headingClassName, { [dangerClassName]: !!error })}
          >
            {label}
          </span>
          <div className={rightHeaderClassName}>
            <Button
              className={cx(buttonSecondaryClassName, {
                [disabledSecondaryButtonClassName]: isDisabled,
              })}
              disabled={isDisabled}
              onClick={isActive ? onCancel : onEdit}
            >
              {isActive ? 'Cancel' : 'Edit'}
            </Button>
          </div>
        </div>
        {isActive && description && (
          <div className={subheaderClassName}>
            <span className={descriptionClassName}>{description}</span>
          </div>
        )}
      </div>
      <div className={bodyClassName}>{!isActive ? placeholder : children}</div>
      {isActive && (
        <div className={footerClassName}>
          <Button
            className={cx(buttonPrimaryClassName, {
              [disabledPrimaryButtonClassName]: !isDirty,
            })}
            disabled={!isDirty || isSaving}
            loading={isSaving}
            onClick={onSave}
            type={ButtonTypes.Submit}
          >
            {'Save'}
          </Button>
          {!!error && (
            <Error className={errorClassName} message={error.message} />
          )}
        </div>
      )}
    </div>
  );
};

Collapsible.displayName = 'Collapsible';

export default Collapsible;
