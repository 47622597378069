import {
  descriptionClassName,
  headingClassName,
  textClassName,
} from './styles';

export interface Props {
  description: string;
  title: string;
}

const PhotoRowText = ({ description, title }: Props) => {
  return (
    <div className={textClassName}>
      <h2 className={headingClassName}>{title}</h2>
      <p className={descriptionClassName}>{description}</p>
    </div>
  );
};

PhotoRowText.displayName = 'PhotoRowText';

export default PhotoRowText;
