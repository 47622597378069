import Control from './Control';
import useControls from './useControls';

interface Props {
  id: string;
}

const Controls = ({ id }: Props) => {
  const [prevId, nextId] = useControls({ id });
  return (
    <>
      <Control id={prevId} />
      <Control id={nextId} isNext />
    </>
  );
};

Controls.displayName = 'Controls';

export default Controls;
