import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const profileHeroClassName = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 56,
});

export const imageClassName = css({
  borderRadius: 100,
  height: 94,
  width: 94,
});

export const headerClassName = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: Spacing.LG,
  textAlign: 'center',
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: FontSize.LARGE,
  fontWeight: FontWeight.BOLD,
});

export const subheadingClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  marginTop: Spacing.SM,
});

export const skeletonClassName = css({
  'span': {
    display: 'flex',
  }
});

export const imageSkeletonClassName = css({
  borderRadius: `${100}px !important`,
  height: 94,
  width: `${94}px !important`,
});

export const headingSkeletonClassName = css({
  height: 22,
  marginBottom: Spacing.XS,
  width: `${80}px !important`,
});

export const subheadingSkeletonClassName = css({
  height: 20,
  width: `${120}px !important`,
});
