import { ReactNode } from 'react';

import { labelClassName } from './styles';

interface Props {
  value: ReactNode;
}

const Label = ({ value }: Props) => {
  return <span className={labelClassName}>{value}</span>;
};

Label.displayName = 'Label';

export default Label;
