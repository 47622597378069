import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModalTypes } from './constants';

type SliceState = {
  isVisible: boolean;
  data: any;
  type: keyof typeof ModalTypes;
};

type Payload = Required<{
  type: keyof typeof ModalTypes;
}> &
  any;

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    isVisible: false,
    data: {},
    type: ModalTypes.Unset,
  } as SliceState,
  reducers: {
    openModal: (state, action: PayloadAction<Payload>) => {
      const { type, ...payload } = action.payload;
      state.isVisible = true;
      state.data = payload;
      state.type = type;
    },
    closeModal: (state) => {
      state.isVisible = false;
      // NOTE: commented out to support modal transitions (e.g. smooth closing of photo modal)
      // state.data = {};
      // state.type = ModalTypes.Unset;
    },
    updateModal: (state, action: PayloadAction<Payload>) => {
      const { ...payload } = action.payload;
      state.data = { ...state.data, ...payload };
    },
  },
});

export const { openModal, closeModal, updateModal } = modalSlice.actions;

export default modalSlice.reducer;
