import { gql } from '@apollo/client';

import { generateQueryHook } from '../../utils';

import {
  AutocompleteAirportQueryResult,
  AutocompleteAirportQueryVariables,
  useAutocompleteAirportQuery,
} from './useAutocompleteAirport.generated';

gql`
  query AutocompleteAirportQuery($query: String) {
    autocompleteAirport(query: $query) {
      ... on Airport {
        iata
        icao
        location {
          city
          country
        }
        name
      }
    }
  }
`;

interface Suggestion {
  label: string;
  tag?: string;
  value: string;
}

interface HookDataToOutputReturn {
  suggestions: Suggestion[];
}

const DEFAULT_RETURN: HookDataToOutputReturn = {
  suggestions: [],
};

const hookDataToOutput = (
  data?: AutocompleteAirportQueryResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    suggestions: [],
  };

  if (data?.autocompleteAirport) {
    data.autocompleteAirport.forEach((result) => {
      const { iata, name } = result;
      toReturn.suggestions.push({
        label: name!,
        tag: iata!,
        value: name!,
      });
    });
  }

  return toReturn;
};

const useAutocompleteAirport = generateQueryHook<
  AutocompleteAirportQueryResult,
  AutocompleteAirportQueryVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useAutocompleteAirportQuery, DEFAULT_RETURN);

export default useAutocompleteAirport;
