import { css } from '@emotion/css';

import { Colors, Spacing } from 'apps/shared/scripts/styles/constants';

export const controlPrevClassName = css({
  alignItems: 'center',
  position: 'fixed',
  top: 'calc(50% - (48px / 2))',
  display: 'none',
  '@media (min-width: 768px)': {
    display: 'block',
    left: 'calc((8rem / 2 / 2) - (48px / 2))',
  },
  '@media (min-width: 992px)': {
    left: 'calc((15rem / 2 / 2) - (48px / 2))',
  },
});

export const controlNextClassName = css({
  alignItems: 'center',
  position: 'fixed',
  top: 'calc(50% - (48px / 2))',
  display: 'none',
  '@media (min-width: 768px)': {
    display: 'block',
    right: 'calc((8rem / 2 / 2) - (48px / 2))',
  },
  '@media (min-width: 992px)': {
    right: 'calc((15rem / 2 / 2) - (48px / 2))',
  },
});

export const buttonClassName = css({
  border: 'none',
  borderRadius: '0',
  padding: Spacing.MD,
  height: 96,
  width: 48,
  '& i': {
    color: 'rgba(255, 255, 255, 0.75)',
    '& svg': {
      height: 24,
      width: 24,
    },
  },
  '&:hover': {
    '& i': {
      color: Colors.White,
    },
  },
});

export const disabledClassName = css({
  cursor: 'default !important',
  '& i': {
    color: 'rgba(255, 255, 255, 0.25) !important',
  },
});
