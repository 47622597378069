import { css } from '@emotion/css';

import { Breakpoints, Spacing } from 'apps/shared/scripts/styles/constants';

export const pageClassName = css({
  marginTop: Spacing['3XL'],
  maxWidth: '100%',
  paddingBottom: Spacing['3XL'],
});

export const splitClassName = css({
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    maxWidth: '80%',
  },
  [`@media(min-width: ${Breakpoints.MD}px)`]: {
    maxWidth: '60%',
  },
  [`@media(min-width: ${Breakpoints.LG}px)`]: {
    maxWidth: '40%',
  }
});

