import { cx } from '@emotion/css';
import { faSatellite } from '@fortawesome/free-solid-svg-icons';

import Icon from '../Icon';

import {
  headingClassName,
  iconClassName,
  innerClassName,
  mediaClassName,
  notFoundClassName,
  satelliteIconStyles,
  textClassName,
} from './styles';

const NotFound = () => {
  return (
    <div className={notFoundClassName}>
      <div className={innerClassName}>
        <div className={mediaClassName}>
          <Icon
            className={cx(iconClassName, satelliteIconStyles)}
            fa={faSatellite}
          />
        </div>
        <div className={textClassName}>
          <span className={headingClassName}>
            Uh oh... profile not found
          </span>
        </div>
      </div>
    </div>
  );
};

NotFound.displayName = 'NotFound';

export default NotFound;
