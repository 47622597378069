import { useCallback } from 'react';

import { ImageUpload } from 'apps/shared/scripts/__generated__/graphql.generated';
import {
  useUpdateProfilePhoto,
  useUpdateUser,
  useUser,
} from 'apps/shared/scripts/graphql';
import { useFormAsync, usePutSignedUrl } from 'apps/shared/scripts/hooks';

import AccountForm from './AccountForm';
import { FormValues } from './types';
import { toFormValues, toInput } from './utils';

const AccountFormContainer = () => {
  const { mutate: onUpdate } = useUpdateUser({
    awaitRefetchQueries: true,
    refetchQueries: ['UserQuery', 'UserProfileQuery'],
  });
  const { mutate: onUpdatePhoto } = useUpdateProfilePhoto();

  const { isLoading: isLoadingUser, user } = useUser();

  const { putSignedUrl } = usePutSignedUrl();
  const { isLoading, onStart, onFail, onSuccess } =
    useFormAsync();

  const handleSave = useCallback(
    async (data: FormValues, upload: File | null) => {
      onStart();
      // update profile photo
      if (upload) {
        const fileExtension = upload.type.split('/').pop()!;
        const { result: imageUpload } = await onUpdatePhoto({
          input: { fileExtension },
        });
        const { publicUrl, signedUrl } = imageUpload as ImageUpload;
        await putSignedUrl(signedUrl, upload);
        data.photo = publicUrl!;
      }
      // delete profile photo
      if (data.photo === '') {
        await onUpdatePhoto({
          input: { fileExtension: '' },
        });
      }
      const input = toInput(data);
      const { result, errors } = await onUpdate({ input });
      if (!result || errors.length > 0) {
        onFail();
        throw new Error(errors[0].message);
      }
      onSuccess();
    },
    [
      onFail,
      onStart,
      onSuccess,
      onUpdate,
      onUpdatePhoto,
      putSignedUrl,
    ]
  );

  if (isLoadingUser || !user) {
    return null; // todo(jgan): loading skeleton
  }

  const defaultValues = toFormValues(user);

  return (
    <AccountForm
      defaultValues={defaultValues}
      handleSave={handleSave}
      isSaving={isLoading}
    />
  );
};

AccountFormContainer.displayName = 'AccountFormContainer';

export default AccountFormContainer;
