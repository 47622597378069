import { useCallback, useState } from 'react';

import useResizeImage, { UseResizeImageOptions } from '../useResizeImage';

type UseUploadImageOptions = UseResizeImageOptions;

const useUploadImage = (options?: UseUploadImageOptions) => {
  const [isUploading, setUploading] = useState<boolean>(false);
  const { onResize, percentage } = useResizeImage(options);

  const onUpload = useCallback(
    async (file: File) => {
      try {
        setUploading(true);

        const { resizedFile, base64Url } = await onResize(file);

        setUploading(false);

        return { file: resizedFile, url: base64Url };
      } catch (e) {
        setUploading(false);
        const error = e instanceof Error ? e.message : 'Upload failed';
        return { file: null, url: '', error };
      }
    },
    [onResize]
  );

  return { onUpload, isUploading, percentage };
};

export default useUploadImage;
