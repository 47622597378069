import { css } from '@emotion/css';

import {
  Breakpoints,
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const sectionClassName = css({
  marginBottom: Spacing.LG,
  marginTop: Spacing.LG,
  padding: `0 ${Spacing.MD}px`,
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    padding: `0 ${Spacing.LG}px`,
  },
});

export const buttonGroupClassName = css({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
});

export const buttonClassName = css({
  backgroundColor: '#F0F0F0',
  borderRadius: 100,
  color: Colors.Primary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.SEMI_BOLD,
  lineHeight: 1.4,
  marginBottom: Spacing.SM,
  marginRight: Spacing.SM,
  padding: `${Spacing.XS}px ${Spacing.LG}px`,
  transition: 'background-color 0.2s ease-in-out 0s',
  '& i': {
    height: 14,
    width: 14,
    '> svg': {
      transition: 'none',
    },
  },
  '&:hover': {
    background: '#E8E8E8',
  },
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    marginBottom: 0,
  },
});

export const activeClassName = css({
  backgroundColor: '#061524',
  color: Colors.White,
  transition: 'background-color 0.2s ease-in-out 0s',
  '&:hover': {
    background: '#061524',
  },
});

export const bodyClassName = css({
  marginTop: Spacing.MD,
});

export const marginTopStyle = {
  marginTop: Spacing.MD,
};
