import { css } from '@emotion/css';

import {
  Breakpoints,
  Colors,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const landingHeroClassName = css({
  marginBottom: 52,
  paddingTop: 52,
  [`@media(min-width: ${Breakpoints.LG}px)`]: {
    marginBottom: 68,
    paddingTop: 84,
  },
});

export const headerClassName = css({
  marginTop: Spacing.LG,
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: 42,
  fontWeight: FontWeight.BOLD,
  lineHeight: LineHeight.COMPACT,
  [`@media(min-width: ${Breakpoints.SM}px)`]: {
    fontSize: 52,
    fontWeight: FontWeight.SEMI_BOLD,
    lineHeight: LineHeight.COMPACT,
  },
});

export const subheadingClassName = css({
  color: Colors.Secondary,
  fontSize: 20,
  fontWeight: FontWeight.MEDIUM,
  lineHeight: LineHeight.EXTRA_LARGE,
  marginTop: Spacing.XL,
  marginBottom: Spacing['2XL'],
});

export const formGroupClassName = css({
  alignItems: 'center',
  border: 'none',
  borderRadius: 100,
  boxShadow: `0px 0px 0px 3px rgba(74, 74, 244, 0.25)`,
  display: 'flex',
  minHeight: 64,
  padding: `${Spacing.XS}px ${Spacing.MD}px ${Spacing.XS}px ${Spacing.LG}px`,
  transition: '0.2s',
  '&:focus-within': {
    boxShadow: `0px 0px 0px 3px rgb(74, 74, 244)`,
  },
  width: '100%',
});

export const prefixClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.LARGE,
  fontWeight: FontWeight.SEMI_BOLD,
});

export const inputClassName = css({
  border: 'none',
  color: Colors.Primary,
  fontSize: FontSize.LARGE,
  fontWeight: FontWeight.MEDIUM,
  flex: 1,
  minWidth: 0,
  padding: `0 ${Spacing['2XS']}px`,
});

export const buttonClassName = css({
  background: '#4A4AF4',
  border: 'none',
  borderRadius: 100,
  cursor: 'pointer',
  marginLeft: 'auto',
  padding: Spacing.SM,
  '&:hover': {
    i: {
      left: 0,
    },
  },
});

export const iconClassName = css({
  color: Colors.White,
  display: 'block',
  height: 20,
  lineHeight: 0,
  left: '-2px',
  position: 'relative',
  transition: '0.2s',
  width: 20,
});

export const mediaClassName = css({
  display: 'none',
  [`@media(min-width: ${Breakpoints.LG}px)`]: {
    display: 'block',
    margin: 'auto',
    width: '80%',
  },
});

export const secondaryIconClassName = css({
  left: 0,
});
