import { css } from '@emotion/css';

import { Colors, FontSize, FontWeight } from 'apps/shared/scripts/styles/constants';

export const googleOauthClassName = css({
  border: 'none',
  boxShadow: '#DDDDDD 0px 0px 0px 1px inset',
  borderRadius: 8,
  minHeight: 50,
  width: '100%',
  '&:hover': {
    backgroundColor: `rgba(48, 48, 48, 0.05)`,
  },
});

export const iconClassName = css({
  position: 'absolute',
  left: 0,
  height: 18,
  width: 18,
});

export const textClassName = css({
  color: Colors.Primary,
  fontFamily: 'Roboto',
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
});
