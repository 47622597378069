import imageCompression from 'browser-image-compression';
import { useCallback, useState } from 'react';

export interface UseResizeImageOptions {
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
}

interface UseResizeImageReturn {
  resizedFile: File;
  base64Url: string;
}

const useResizeImage = (
  { maxSizeMB = 0.5, maxWidthOrHeight }: UseResizeImageOptions = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: undefined,
  }
) => {
  const [percentage, setPercentage] = useState<number>(0);

  const onResize = useCallback(
    (file: File): Promise<UseResizeImageReturn> =>
      new Promise<UseResizeImageReturn>(async (resolve, reject) => {
        const options = {
          maxSizeMB,
          maxIteration: 30,
          maxWidthOrHeight,
          onProgress: (p: number) => setPercentage(p),
        };
        try {
          // console.log(`original size: ${file.size / 1024 / 1024} MB`);
          const resizedFile: File = await imageCompression(file, options);
          // console.log(`compressed size: ${resizedFile.size / 1024 / 1024} MB`);
          if (resizedFile.size / 1024 / 1024 > 0.5) {
            throw new Error('Unable to compress image < 0.5 MB');
          }
          const base64Url = await imageCompression.getDataUrlFromFile(
            resizedFile
          );
          resolve({ resizedFile, base64Url });
        } catch (e) {
          reject(e);
        }
      }),
    [maxSizeMB, maxWidthOrHeight]
  );

  return { percentage, onResize };
};

export default useResizeImage;
