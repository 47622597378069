import { Collapsible } from '../../components';

import questions from './questions.json';
import { questionsClassName } from './styles';

const Questions = () => {
  const list = questions.map((q, i) => (
    <Collapsible key={i} title={q.question} description={q.answer} />
  ));

  return <div className={questionsClassName}>{list}</div>;
};

Questions.displayName = 'Questions';

export default Questions;
