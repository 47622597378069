import { cx } from '@emotion/css';
import { faCameraAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useCallback, useRef } from 'react';

import { Button, Image } from 'apps/shared/scripts/components';
import { useUploadImage } from 'apps/shared/scripts/hooks';

import {
  deleteClassName,
  editClassName,
  iconClassName,
  imageClassName,
  pillButtonClassName,
  profilePhotoClassName,
  wrapperClassName,
} from './styles';

interface Props {
  editMode?: boolean;
  onRemovePhoto?: () => void;
  onUploadPhoto?: (file: File | null, url: string) => void;
  src?: string | null;
}

const ProfilePhoto = ({
  editMode,
  onRemovePhoto,
  onUploadPhoto,
  src,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  const { isUploading, onUpload } = useUploadImage({
    maxSizeMB: 0.1,
    maxWidthOrHeight: 300,
  });

  const image = src
    ? src
    : 'https://res.cloudinary.com/dutl6xlom/image/upload/v1698626088/5976702cffb33336cf827d95_gzvtxl.jpg';

  const onEdit = useCallback(() => {
    ref.current!.click();
  }, []);

  const onChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files![0];
      const { file: resizedFile, url, error } = await onUpload(file);
      if (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return;
      }
      onUploadPhoto!(resizedFile, url);
    },
    [onUpload, onUploadPhoto]
  );

  return (
    <div className={profilePhotoClassName}>
      <div className={wrapperClassName}>
        <Image className={imageClassName} src={image} scaled />
        {editMode && (
          <div className={editClassName}>
            <Button
              className={cx(pillButtonClassName, { [deleteClassName]: !!src })}
              disabled={isUploading}
              faIcon={src ? faTrashAlt : faCameraAlt}
              iconClassName={iconClassName}
              loading={isUploading}
              onClick={src ? onRemovePhoto : onEdit}
            >
              {src ? 'Clear' : 'Edit'}
            </Button>
            {!src && <input
              onChange={onChange}
              ref={ref}
              type="file"
              style={{ display: 'none' }}
            />}
          </div>
        )}
      </div>
    </div>
  );
};

ProfilePhoto.displayName = 'ProfilePhoto';

export default ProfilePhoto;
