import { css } from '@emotion/css';

import { Colors, FontSize, FontWeight, Spacing } from 'apps/shared/scripts/styles/constants';

export const footnoteClassName = css({
  marginTop: Spacing.LG,
});

export const textClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.COMPACT,
  fontWeight: FontWeight.NORMAL,
});

export const linkClassName = css({
  color: '#26A7DE',
});
