import { cx } from '@emotion/css';
import { ReactNode } from 'react';

import { pageClassName, splitClassName } from './styles';

interface Props {
  children: ReactNode;
  split?: boolean;
}

const Page = ({ children, split }: Props) => {
  return (
    <div className={cx(pageClassName, { [splitClassName]: split })}>
      {children}
    </div>
  );
};

Page.displayName = 'Page';

export default Page;
