import { gql } from '@apollo/client';

import { generateMutationHook } from '../../utils';

import {
  CreateUserMutationResult,
  CreateUserMutationVariables,
  useCreateUserMutation,
} from './useCreateUser.generated';

gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      username
    }
  }
`;

interface HookDataToOutputReturn {
  result?: boolean;
}

const hookDataToOutput = (
  data?: CreateUserMutationResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    result: undefined,
  };

  if (data?.createUser) {
    toReturn.result = true;
  }

  return toReturn;
};

const useCreateUser = generateMutationHook<
  CreateUserMutationResult,
  CreateUserMutationVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useCreateUserMutation);

export default useCreateUser;
