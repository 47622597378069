import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useIsAuthenticated } from '../../hooks';

interface Props {
  children?: ReactElement;
}

const RequireUnauth = ({ children }: Props): ReactElement => {
  const isAuthenticated = useIsAuthenticated();
  if (isAuthenticated) {
    return <Navigate to={'/'} replace />;
  }
  return children ? children : <Outlet />;
};

export default RequireUnauth;
