import { useCallback } from 'react';

import { useChangePassword } from '../../hooks';

import PasswordForm from './PasswordForm';
import { FormValues } from './types';

const PasswordFormContainer = () => {
  const { changePassword, isLoading } = useChangePassword();

  const handleSave = useCallback(
    async (data: FormValues) => {
      const { currentPassword, newPassword } = data;
      const { error, result } = await changePassword(
        currentPassword,
        newPassword
      );
      if (!result || error.code) {
        throw new Error(error.message);
      }
    },
    [changePassword]
  );

  return <PasswordForm handleSave={handleSave} isSaving={isLoading} />;
};

PasswordFormContainer.displayName = 'PasswordFormContainer';

export default PasswordFormContainer;
