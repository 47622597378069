import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const backButtonClassName = css({
  color: Colors.Primary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  marginBottom: Spacing.LG,
  padding: 0,
});

export const iconClassName = css({
  height: 16,
  marginRight: 6,
  width: 16,
});
