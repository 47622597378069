import { css, cx, keyframes } from '@emotion/css';

import { Colors, FontWeight } from '../../styles/constants';

export const notFoundClassName = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

export const innerClassName = css({
  paddingTop: 120,
});

export const mediaClassName = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

export const textClassName = css({
  marginTop: 62,
});

export const headingClassName = css({
  color: Colors.Secondary,
  fontSize: 20,
  fontWeight: FontWeight.MEDIUM,
});

export const iconClassName = css({
  color: '#AAB4C1',
  height: 84,
  width: 84,
});

const satelliteHoverKeyframes = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
`;

const satelliteHover = css`
  animation: ${satelliteHoverKeyframes} 4s infinite;
`;

const satelliteIconHover = css`
  &:hover {
    animation-play-state: paused;
  }
`;

const satelliteIcon = css`
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
  ${satelliteHover}; /* Apply the animation */
`;

export const satelliteIconStyles = cx(satelliteIcon, satelliteIconHover);
