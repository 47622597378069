import { Container } from 'react-bootstrap';
import { ScrollRestoration } from 'react-router-dom';

import CenterContent from './CenterContent';
import LeftContent from './LeftContent';
import RightContent from './RightContent';
import { innerClassName, topNavClassName } from './styles';

const TopNav = () => {
  return (
    <div className={topNavClassName}>
      <Container>
        <div className={innerClassName}>
          <LeftContent />
          <CenterContent />
          <RightContent />
        </div>
      </Container>
      <ScrollRestoration />
    </div>
  );
};

TopNav.displayName = 'TopNav';

export default TopNav;
