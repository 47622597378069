import { css, CSSObject } from '@emotion/css';
import { Theme } from 'react-select';

import {
  Colors,
  FontSize,
  FontWeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const control = (defaultStyles: CSSObject, state: any): CSSObject => ({
  ...defaultStyles,
  border: 'none',
  boxShadow: state.isFocused
    ? `${Colors.Primary} 0px 0px 0px 2px inset`
    : '#ddd 0px 0px 0px 1px inset',
  borderRadius: 8,
  cursor: 'text',
  minHeight: 0,
  padding: 0,
});

export const indicatorsContainer = (
  defaultStyles: CSSObject,
  state: any
): CSSObject => ({
  ...defaultStyles,
  cursor: 'pointer',
});

export const input = (defaultStyles: CSSObject, state: any): CSSObject => ({
  ...defaultStyles,
  color: Colors.Primary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  padding: `${Spacing.XS}px ${Spacing.MD}px`,
  margin: 0,
  minHeight: 50,
});

export const menu = (defaultStyles: CSSObject, state: any): CSSObject => ({
  ...defaultStyles,
  borderRadius: 8,
  boxShadow: 'rgb(0, 0, 0, 0.12) 0px 6px 16px 4px',
  overflow: 'hidden',
});

export const menuList = (defaultStyles: CSSObject, state: any): CSSObject => ({
  ...defaultStyles,
  padding: 0,
});

export const menuPortal = (
  defaultStyles: CSSObject,
  state: any
): CSSObject => ({
  ...defaultStyles,
  zIndex: 99,
});

export const option = (defaultStyles: CSSObject, state: any): CSSObject => ({
  ...defaultStyles,
  alignItems: 'center',
  backgroundColor: state.isSelected
    ? '#C7CBFF'
    : state.isActive
    ? '#C7CBFF'
    : state.isFocused
    ? '#EFF3FF'
    : Colors.White,
  color: Colors.Primary,
  cursor: 'pointer',
  display: 'flex',
  minHeight: 46,
  padding: `${Spacing.SM}px ${Spacing.MD}px`,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
});

export const placeholder = (
  defaultStyles: CSSObject,
  state: any
): CSSObject => ({
  ...defaultStyles,
  marginLeft: Spacing.MD,
});

export const singleValue = (
  defaultStyles: CSSObject,
  state: any
): CSSObject => ({
  ...defaultStyles,
  color: Colors.Primary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.MEDIUM,
  marginLeft: Spacing.MD,
});

export const valueContainer = (
  defaultStyles: CSSObject,
  state: any
): CSSObject => ({
  ...defaultStyles,
  padding: 0,
});

export const theme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#EFF3FF',
    primary75: '#EFF3FF',
    primary50: '#EFF3FF',
    primary25: '#EFF3FF',
  },
});

export const optionClassName = css({
  alignItems: 'center',
  display: 'flex',
});

export const tagClassName = css({
  alignItems: 'center',
  background: '#E3E5FF',
  borderRadius: 6,
  color: Colors.Primary,
  display: 'flex',
  fontSize: 13,
  fontWeight: FontWeight.SEMI_BOLD,
  justifyContent: 'center',
  marginRight: Spacing.MD,
  minWidth: 45,
  padding: `${Spacing['2XS']}px ${Spacing.XS}px`,
});
