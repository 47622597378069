import debounce from 'lodash/debounce';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';

import { Combobox, Group } from 'apps/shared/scripts/components';
import { useAutocompleteAircraftType } from 'apps/shared/scripts/graphql';

import { FORM_FIELDS } from '../../../containers/UploadFormContainer/constants';

interface Props<T extends FieldValues> {
  control: Control<T>;
  errors?: FieldErrors<T>;
  manufacturer: string;
  type: string;
  onType: (v: string) => void;
}

const TypeField = <T extends FieldValues>({
  control,
  errors,
  manufacturer,
  type,
  onType,
}: Props<T>) => {
  const { isLoading, suggestions } = useAutocompleteAircraftType({
    variables: {
      manufacturer,
      type,
    },
  });

  return (
    <Group
      errors={errors}
      label={FORM_FIELDS.TYPE.label}
      validate={FORM_FIELDS.TYPE.name}
    >
      <Combobox
        isLoading={isLoading}
        onAutocomplete={debounce(onType, 500)}
        control={control}
        name={FORM_FIELDS.TYPE.name}
        options={suggestions}
      />
    </Group>
  );
};

TypeField.displayName = 'TypeField';

export default TypeField;
