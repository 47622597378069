import { FormValues } from './types';

export const FORM_FIELDS = {
  AIRCRAFT: {
    name: 'aircraft',
    label: 'Aircraft',
  } as const,
  AIRLINE: {
    name: 'airline',
    label: 'Airline',
  } as const,
  AIRPORT: {
    name: 'airport',
    label: 'Airport',
  } as const,
  TYPE: {
    name: 'type',
    label: 'Type',
  } as const,
  VARIANT: {
    name: 'variant',
    label: 'Variant',
  } as const,
  PHOTO: {
    name: 'photo',
    label: 'Photo',
  } as const,
};

export const DEFAULT_VALUES: FormValues = {
  aircraft: '',
  airline: '',
  airport: '',
  photo: '',
  type: '',
  variant: '',
};
