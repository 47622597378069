import { css } from '@emotion/css';

import {
  Colors,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from 'apps/shared/scripts/styles/constants';

export const collapsibleClassName = css({
  cursor: 'pointer',
  marginTop: Spacing.LG,
  marginBottom: Spacing.LG,
  width: '100%',
});

export const headerClassName = css({
  alignItems: 'center',
  display: 'flex',
});

export const headingClassName = css({
  color: Colors.Primary,
  fontSize: FontSize.LARGE,
  fontWeight: FontWeight.MEDIUM,
  lineHeight: LineHeight.REGULAR,
  paddingRight: Spacing.MD,
});

export const bodyClassName = css({
  marginTop: Spacing.MD,
});

export const descriptionClassName = css({
  color: Colors.Secondary,
  fontSize: FontSize.REGULAR,
  fontWeight: FontWeight.NORMAL,
  lineHeight: LineHeight.EXTRA_LARGE,
});

export const buttonClassName = css({
  border: 'none',
  marginLeft: 'auto',
  padding: 0,
});

export const iconClassName = css({
  color: Colors.Secondary,
  height: 16,
  width: 16,
  transition: '0.2s',
});

export const activeIconClassName = css({
  transform: 'rotate(-180deg)',
});
