import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'apps/shared/scripts/components';

import { backButtonClassName, iconClassName } from './styles';

interface Props {
  onClick: () => void;
}

const BackButton = ({ onClick }: Props) => {
  return (
    <Button
      className={backButtonClassName}
      iconClassName={iconClassName}
      faIcon={faChevronLeft}
      onClick={onClick}
    >
      Back
    </Button>
  );
};

BackButton.displayName = 'BackButton';

export default BackButton;
