import { gql } from '@apollo/client';

import { generateQueryHook } from '../../utils';

import {
  AutocompleteAircraftVariantQueryResult,
  AutocompleteAircraftVariantQueryVariables,
  useAutocompleteAircraftVariantQuery,
} from './useAutocompleteAircraftVariant.generated';

gql`
  query AutocompleteAircraftVariantQuery($manufacturer: String!, $type: String!, $variant:String) {
    autocompleteAircraftVariant(manufacturer: $manufacturer, type: $type, variant: $variant)
  }
`;

interface Suggestion {
  label: string;
  value: string;
}

interface HookDataToOutputReturn {
  suggestions: Suggestion[];
}

const DEFAULT_RETURN: HookDataToOutputReturn = {
  suggestions: [],
};

const hookDataToOutput = (
  data?: AutocompleteAircraftVariantQueryResult | null
): HookDataToOutputReturn => {
  const toReturn: HookDataToOutputReturn = {
    suggestions: [],
  };

  if (data?.autocompleteAircraftVariant) {
    data.autocompleteAircraftVariant.forEach((result) => {
      toReturn.suggestions.push({
        label: result!,
        value: result!,
      });
    });
  }

  return toReturn;
};

const useAutocompleteAircraftVariant = generateQueryHook<
  AutocompleteAircraftVariantQueryResult,
  AutocompleteAircraftVariantQueryVariables,
  HookDataToOutputReturn
>(hookDataToOutput, useAutocompleteAircraftVariantQuery, DEFAULT_RETURN);

export default useAutocompleteAircraftVariant;
