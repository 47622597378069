import Spinner from '../Spinner';

import { iconClassName, pageLoaderClassName, spinnerClassName } from './styles';

const PageLoader = () => {
  return (
    <div className={pageLoaderClassName}>
      <Spinner className={spinnerClassName} iconClassName={iconClassName} />
    </div>
  );
};

PageLoader.displayName = 'PageLoader';

export default PageLoader;
