import { css } from '@emotion/css';

import { Colors } from '../../styles/constants';

export const topNavClassName = css({
  background: Colors.White,
  border: `1px solid ${Colors.White}`,
  boxShadow: `rgba(0, 0, 0, 0.08) 0 1px 0`,
  display: 'flex',
  height: 68,
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 10,
});

export const innerClassName = css({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  position: 'relative',
  width: '100%',
  zIndex: 1,
});

export const leftContentClassName = css({
  display: 'flex',
  flex: '1',
});

export const logoClassName = css({
  height: 32,
});

export const centerContentClassName = css({
  flex: '1',
});
