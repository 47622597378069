import * as Types from 'apps/shared/scripts/__generated__/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AutocompleteAircraftTypeQueryVariables = Types.Exact<{
  manufacturer: Types.Scalars['String']['input'];
  type?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type AutocompleteAircraftTypeQueryResult = { readonly __typename: 'Query', readonly autocompleteAircraftType: ReadonlyArray<string> };


export const AutocompleteAircraftTypeQueryDocument = gql`
    query AutocompleteAircraftTypeQuery($manufacturer: String!, $type: String) {
  autocompleteAircraftType(manufacturer: $manufacturer, type: $type)
}
    `;

/**
 * __useAutocompleteAircraftTypeQuery__
 *
 * To run a query within a React component, call `useAutocompleteAircraftTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteAircraftTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteAircraftTypeQuery({
 *   variables: {
 *      manufacturer: // value for 'manufacturer'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAutocompleteAircraftTypeQuery(baseOptions: Apollo.QueryHookOptions<AutocompleteAircraftTypeQueryResult, AutocompleteAircraftTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutocompleteAircraftTypeQueryResult, AutocompleteAircraftTypeQueryVariables>(AutocompleteAircraftTypeQueryDocument, options);
      }
export function useAutocompleteAircraftTypeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutocompleteAircraftTypeQueryResult, AutocompleteAircraftTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutocompleteAircraftTypeQueryResult, AutocompleteAircraftTypeQueryVariables>(AutocompleteAircraftTypeQueryDocument, options);
        }
export type AutocompleteAircraftTypeQueryHookResult = ReturnType<typeof useAutocompleteAircraftTypeQuery>;
export type AutocompleteAircraftTypeQueryLazyQueryHookResult = ReturnType<typeof useAutocompleteAircraftTypeQueryLazyQuery>;